import React from "react";
import PropTypes from 'prop-types';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import styles from './WMICRemoveVehicleMessage.module.scss';

const WMICRemoveVehicleMessage = ({title, text, mode, additionalTexts = []}) => {
    return (
        <div className={`${styles['wmic-notification']} ${mode === 'error' ? styles['is-alert'] : styles['is-info']}`}>
            <div>
                <h3 className={`${styles['wmic-notification-header']}`}>{title}</h3>
                <div className={`${styles['wmic-notification-body']}`}>{WMICRichTextUtil.translateRichText(text)}</div>
                {
                    additionalTexts.map((addText) => {
                        return (<><div className={`${styles['wmic-notification-body']}`}>{WMICRichTextUtil.translateRichText(addText)}</div></>)
                    })
                }
            </div>
        </div>
    );
}

WMICRemoveVehicleMessage.propTypes = {
    title: PropTypes.any,
    text: PropTypes.any.isRequired,
    additionalTexts: PropTypes.arrayOf(PropTypes.string),
    mode: PropTypes.any,
};

export default WMICRemoveVehicleMessage;
