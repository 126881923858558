import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { MetadataForm } from '@jutro/uiconfig';
import _ from 'lodash';
import CONSTANTS from 'wmic-portals-utils-js/StringConstants';
import { FNOLConstants } from 'wmic-capability-fnol-react';

import { useTranslator } from '@jutro/locale';
import metadata from './FNOLPassengersComponent.metadata.json5';
import styles from '../FNOLStyle.module.scss';
import messages from './FNOLPassengersComponent.messages.js';


function FNOLPassengersComponent({match, ...props}){
    const { WMICFnolSharedDataService, WMICFnolUtilsService, validateFields, state } = props;
    const [passengers, setPassenger] = useState(WMICFnolSharedDataService.passengers);
    const [toggleValue, setToggleValue] = useState(WMICFnolSharedDataService.passengersSelected.value);
    const [count, setCount] = useState(1);
    const translator = useTranslator();

    useEffect(() => {
        handleUpdateList()
    }, [count, handleUpdateList])

    const handleUpdateList = useCallback(() => {
        const listLength = passengers.list.length;
        let updatedList = [...passengers.list];

        if (count !== listLength) {
            if (count > listLength) {
                updatedList.push(...Array.from({length: count - listLength}, () => WMICFnolUtilsService.passenger(state)))
            } else  {
                passengers.list.splice(count);
                updatedList = passengers.list;
            }
        }
        
        setPassenger({
            ...passengers,
            list: updatedList
        })
        WMICFnolSharedDataService.passengers.list = updatedList;
    }, [WMICFnolUtilsService.passenger, WMICFnolSharedDataService.passengers, passengers, count])

    const onDropdownChange = useCallback((item) => {
        setCount(item);
    }, [setCount]);

    const onToggleChange = (value) => {
        setToggleValue(value)
        WMICFnolSharedDataService.passengersSelected.value = value;
    };

    const getAvailableVehicleOptions = () => {
        const options = WMICFnolUtilsService.getVehicleOptions();
        if (WMICFnolSharedDataService.otherParties && WMICFnolSharedDataService.otherParties.list) {
            WMICFnolSharedDataService.otherParties.list.forEach((v, i) => {
                options.push({
                    code: `Other Party ${i + 1}`,
                    displayName: `Other Party ${i + 1}`
                });
            });
            
        }
        return options;
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onToggleChange,
        },
    }

    const renderTabs = () => {
        return _.map(passengers.list, (item, index) => {
            const itemId = index + 1;
            return {
                id: `tab-${itemId}`,
                type: 'element',
                component: 'tab',
                componentProps: {
                    id: `passengers-tab-${itemId}`,
                    heading: itemId,
                    showFrame: true,
                    className: 'fnolTab'
                },
                content: [
                    {
                        id: `FNOLPassengerTabDescrption_tab${itemId}`,
                        type: 'element',
                        component: 'h4',
                        content: translator(messages.FNOLPassengersDescription, {num: itemId})
                    },
                    {
                        id: `FNOLPassengerCheckboxContainer_tab${itemId}`,
                        type: 'element',
                        component: 'div',
                        componentProps: {
                            className: "ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLPassengerWhichVechicle_tab${itemId}`,
                                type: "field",
                                component: "dropdownselect",
                                componentProps: {
                                    availableValues: getAvailableVehicleOptions(),
                                    path: `list[${index}].passengerLocation`,
                                    className: "wmic-fnol-dropdown  ww-input-75",
                                    label: {
                                        id: "wmic.fnol-page.loss-information.which-vehicle-label",
                                        defaultMessage: "Which vehicle was the passenger in?"
                                    }
                                }
                            },
                        ]
                    },
                    {
                        id: "FNOLPassengersFirstNameContainer",
                        type:"container",
                        component:"div",
                        componentProps: {
                            className:"ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLPassengerFirstName_tab${itemId}`,
                                type:"field",
                                component:"input",
                                componentProps: {
                                    value: item.firstName,
                                    labelClassName:"input-required",
                                    path:`list[${index}].firstName`,
                                    label: {
                                        id: "wmic.fnol-page.passengers.first-name-label",
                                        defaultMessage: "First Name"
                                    },
                                    required: true,
                                    maxLength: 50
                                }
                            },
                            {
                                id: `FNOLPassengersFirstNameError_tab${itemId}`,
                                type:"element",
                                component:"span",
                                componentProps: {
                                    className:"error",
                                    visible: validateFields('passengers.list', 'tabset', false, 'firstName')
                                },
                                content:{
                                    id: "wmic.fnol-page.pa.field-required",
                                    defaultMessage: "Field required"
                                }
                            },
                        ]
                    },
                    {
                        id: "FNOLPassengersLastNameContainer",
                        type:"container",
                        component:"div",
                        componentProps: {
                            className:"ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLPassengerLastName_tab${itemId}`,
                                type:"field",
                                component:"input",
                                componentProps: {
                                    value: item.lastName,
                                    labelClassName:"input-required",
                                    path:`list[${index}].lastName`,
                                    label: {
                                        id: "wmic.fnol-page.passengers.last-name-label",
                                        defaultMessage: "Last Name"
                                    },
                                    required: true,
                                    maxLength: 50
                                }
                            },
                            {
                                id: `FNOLPassengersLastNameError_tab${itemId}`,
                                type:"element",
                                component:"span",
                                componentProps: {
                                    className:"error",
                                    visible: validateFields('passengers.list', 'tabset', false, 'lastName')
                                },
                                content:{
                                    id: "wmic.fnol-page.pa.field-required",
                                    defaultMessage: "Field required"
                                }
                            },
                        ]
                    },
                    {
                        id: `FNOLPassengerAddress_tab${itemId}`,
                        type:"field",
                        component:"input",
                        componentProps: {
                            value: item.address,
                            className:"entry-item ww-input--75",
                            path:`list[${index}].address.street`,
                            label: {
                                id: "wmic.fnol-page.passengers.address-label",
                                defaultMessage: "Address"
                            },
                            maxLength: 50
                        }
                    },
                    {
                        id: `FNOLPassengerCity_tab${itemId}`,
                        type:"field",
                        component: "input",
                        componentProps: {
                            value: item.city,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].address.city`,
                            label: {
                                id: "wmic.fnol-page.passengers.city-label",
                                defaultMessage: "City"
                            },
                            maxLength: 50
                        }
                    },
                    {
                        id: `FNOLPassengerCountryContainer_tab${itemId}`,
                        type: "container",
                        component:"div",
                        componentProps: {
                            className:"ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLPassengerCountry_tab${itemId}`,
                                type: "field",
                                component: "dropdownselect",
                                componentProps: {
                                    availableValues: WMICFnolUtilsService.getSelectCanadaUSA(),
                                    path: `list[${index}].address.country`,
                                    className: "wmic-fnol-dropdown",
                                    label: {
                                        id: "wmic.fnol-page.passengers.country-label",
                                        defaultMessage: "Country"
                                    },
                                    internalClassNames: {
                                        option: "dropdownOption"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        id: `FNOLPassengerStateProvinceContainer_tab${itemId}`,
                        type: "container",
                        component:"div",
                        componentProps: {
                            className:"ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLPassengerStateProvince_tab${itemId}`,
                                type: "field",
                                component: "dropdownselect",
                                componentProps: {
                                    availableValues: WMICFnolUtilsService.getStatesShown(item),
                                    path: `list[${index}].address.state`,
                                    className: "wmic-fnol-dropdown",
                                    label: {
                                        id: "wmic.fnol-page.loss-information.state-province-label",
                                        defaultMessage: "State"
                                    },
                                    internalClassNames: {
                                        option: "dropdownOption"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        id: `FNOLPassengerZipCode_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "99999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.postalCode,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].address.postalCode`,
                            label: {
                                id: "wmic.fnol-page.passengers.zipcode-label",
                                defaultMessage: "Zip Code"
                            }   
                        }
                    },
                    {
                        id: `FNOLPassengerHomePhone_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "(999) 999-9999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.phoneNumber,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].phoneNumber`,
                            label: {
                                id: "wmic.fnol-page.passengers.home-phone-label",
                                defaultMessage: "Home Phone Number"
                            }   
                        }
                    },
                    {
                        id: `FNOLPassengerBusinessPhone_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "(999) 999-9999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.homeBusinessNumber,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].businessPhoneNumber`,
                            label: {
                                id: "wmic.fnol-page.passengers.business-phone-label",
                                defaultMessage: "Business Phone Number"
                            }   
                        }
                    },
                    {
                        id: `FNOLPassengerExt_tab${itemId}`,
                        type:"field",
                        component: "Input",
                        componentProps: {
                            maxLength: 5,
                            placeholder: "_____",
                            registerValidation: () => _.noop,
                            value: item.ext,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].businessPhoneNumberExt`,
                            label: {
                                id: "wmic.fnol-page.passengers.ext-label",
                                defaultMessage: "Ext"
                            }   
                        }
                    },
                    {
                        id: `FNOLPassengerCellPhone_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "(999) 999-9999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.cellPhoneNumber,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].cellPhoneNumber`,
                            label: {
                                id: "wmic.fnol-page.passengers.cellphone-label",
                                defaultMessage: "Cell Phone Number"
                            }   
                        }
                    },
                    {
                        id: `FNOLPassengerDriversLicense_tab${itemId}`,
                        type:"field",
                        component: "input",
                        componentProps: {
                            value: item.driverLicNo,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].driverLicNo`,
                            label: {
                                id: "wmic.fnol-page.passengers.drivers-license-label",
                                defaultMessage: "Drivers License Number"
                            },
                            maxLength: 20
                        }
                    },
                    {
                        id: `FNOLPassengerInjuriesOrTreatments_tab${itemId}`,
                        type:"field",
                        component: "textarea",
                        componentProps: {
                            value: item.injuries,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].injuries`,
                            label: {
                                id: "wmic.fnol-page.passengers.injuries-or-treatments-label",
                                defaultMessage: "Tell us about any injuries or treatments received"
                            },
                            maxLength: 1333   
                        }
                    },
                ]
            };
        })
    }

    const setZipLabel = useCallback((country) => {
        return country === CONSTANTS.COUNTRY.US ? translator(messages.FNOLPassengersFormZipCode)  : translator(messages.FNOLPassengersFormPostalCode) 
    }, [translator])

    const zipOverrides = useMemo(() => {
        const overrides = _.get(passengers, 'list')
            .reduce((partial, passenger, index) => ({
                ...partial,
                [`FNOLPassengerZipCode_tab${index+1}`]: {
                    mask: FNOLConstants.setZipMask(passenger?.address.country),
                    label: setZipLabel(passenger?.address.country)
                }
            }), {});
        return overrides
    }, [passengers, setZipLabel])

    const overrideProps = {
        ...zipOverrides,
        FNOLPassengersCount: {
            availableValues: FNOLConstants.AVAILABLE_COUNTS,
            value: count,
            onValueChange: onDropdownChange
        },
        FNOLPassengersToggle: {
            value: toggleValue
        },
        FNOLPassengersFormContainer: {
            visible: toggleValue === true
        },
        FNOLPassengersNoPassengersWarning: {
            visible: toggleValue === false
        }, 
        FNOLPassengersTabSet: {
            activeTab: 'passengers-tab-1',
            content: renderTabs(),
            className: styles.fnolTabSet
        },
        FNOLPassengersToggleError: {
            visible: validateFields('passengersSelected.value', 'toggle')
        }
    }

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            data={passengers}
            onDataChange={(value, path) => {
                value = (path.split('.').at(-1) ==='businessPhoneNumberExt') ? value.replace(/\D/g,'') : value;
                const newData = {...passengers};
                _.set(newData, path, value);
                WMICFnolSharedDataService.passengers = newData;
                setPassenger(newData);
                if (path.split('.').at(-1) === 'country') {
                    WMICFnolUtilsService.countryOptionChanged(path, value, state, passengers, setPassenger, WMICFnolSharedDataService.passengers);
                }
            }} />
    );
}

export default FNOLPassengersComponent