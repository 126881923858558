import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { MetadataContent } from '@jutro/uiconfig';
import { ENROLLMENT_TYPES } from '../../EnrollmentUtil';
import EnrollmentComponent from '../../components/EnrollmentComponent/EnrollmentComponent';

import metadata from './AdditionalEnrollment.metadata.json5';
import messages from './AdditionalEnrollment.messages';

import React from 'react';

const isMultiAccountUser = _.get(appConfig, 'self-enrollment.multiAccountEnrollment', false);

function AdditionalEnrollment() {
    const overrides = {
        enrollmentComponent: {
            enrollmentType: isMultiAccountUser ? ENROLLMENT_TYPES.account : ENROLLMENT_TYPES.policy,
            informationText: messages.provideVerificationInformation,
            informationAdditionalText:
                isMultiAccountUser === true
                    ? messages.relatedPoliciesAddedAutomatically
                    : messages.completeFieldsToAccessPolicyInfo
        }
    };

    const resolvers = {
        resolveComponentMap: {
            enrollmentcomponent: EnrollmentComponent
        }
    };

    const renderedContentFromMetadata = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;

    return renderedContentFromMetadata;
}

export default AdditionalEnrollment;
