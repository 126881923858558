import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICLoader } from 'gw-components-platform-react';
import { WMICEMQLandingPage, WMICEMQVehiclesComponent, WMICEMQSuccessComponent } from 'wmic-capability-emq-react';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { EMQ_RESPONSE_TYPES, EMQ_STEPS } from 'wmic-portals-utils-js/StringConstants';
import WMICVehicleInfo from '../../models/WMICVehicleInfo';


import metadata from './WMICEMQPage.metadata.json5';
import styles from './WMICEMQPage.module.scss';
import messages from './WMICEMQPage.messages';

function WMICEMQPage(props) {
    const { match } = props;
    const history = useHistory();
    const translator = useTranslator();
    const [EMQVehicleData, setEMQVehicleData] = useState({ Vehicles: [] });
    const [loading, setLoading] = useState(false);
    const [landingPageError, setlandingPageError] = useState(false);
    const model = {
        insuredName: '',
        policyNumber: '',
        Vehicles: EMQVehicleData.Vehicles
    };
    const stepSelected = _.get(match, 'params.step');

    // TODO-AMPDM-1525 remove mock data
    const mockVehicles = {
        Status: 'SUCCESS',
        StatusMessage: null,
        LastAMQSubmitDate: null,
        PolicyNumber: '10967160',
        InsuredName: 'emqtest testtest',
        Vehicles: [
            {
                AnnualMileage: '8000',
                CommutingMiles: '-1',
                DaysPerWeekCommute: '-1',
                OdometerReading: '10000',
                PrimaryUse: 'pleasure',
                Vin: '1G1YW3DW5D5108598',
                VehicleName: '2013 Chevrolet CORVETTE GS 3LT',
                VehicleNumber: '1'
            },
            {
                AnnualMileage: '2000',
                CommutingMiles: '20',
                DaysPerWeekCommute: '-1',
                OdometerReading: '-1',
                PrimaryUse: 'commuting',
                Vin: 'WBAXA5C57G3834384',
                VehicleName: '2016 BMW 535D',
                VehicleNumber: '2'
            },
            {
                AnnualMileage: '5000',
                CommutingMiles: '10',
                DaysPerWeekCommute: '-1',
                OdometerReading: '-1',
                PrimaryUse: 'commuting',
                Vin: '1G1NE52JX28881975',
                VehicleName: '2002 Chevrolet MALIBU LS',
                VehicleNumber: '3'
            }
        ]
    };

    const updateVehiclesModel = (vehArray) => {
        vehArray.Vehicles.forEach((vehicle) => {
            const vehicleItem = {
                NewEmqVehicle: {},
                CurrentPolicyVehicle: {}
            };
            const newModelVehicle = new WMICVehicleInfo({
                Vin: vehicle.Vin,
                VehicleName: vehicle.VehicleName,
                VehicleNumber: vehicle.VehicleNumber,
                PrimaryUse: null,
                OdometerReading: null,
                DaysPerWeekCommute: null,
                CommutingMiles: null,
                AnnualMileage: null,
            });

            vehicleItem.CurrentPolicyVehicle = vehicle;
            vehicleItem.NewEmqVehicle = newModelVehicle;

            model.Vehicles.push(vehicleItem);
        });
        setEMQVehicleData(model);
    };

    const mockError = {
        Status: 'ERROR',
        StatusMessage: 'POLICY-NOT-FOUND',
        LastAMQSubmitDate: null,
        PolicyNumber: null,
        InsuredName: null,
        Vehicles: null
    };
    const mockNotNeeded = {
        Status: 'ERROR',
        StatusMessage: 'AMQ-NOT-REQUIRED',
        LastAMQSubmitDate: null,
        PolicyNumber: 10264390,
        InsuredName: 'Haruki Murasaki',
        Vehicles: null
    };

    const mockSubmit = {
        Status: 'Success',
        StatusMessage: null,
        PolicyNumber: '10967160'
    };

    const getPageTitle = () => {
        const title = stepSelected === EMQ_STEPS.SUCCESS ? (
            <h1>
                {translator(messages.EMQPageTitleSubmitted)}
            </h1>
        ) : (
            <h1>
                {translator(messages.EMQPageTitle)}
            </h1>
        );
        return title;
    };

    const handleNext = (formData, authHeader) => {
        setLoading(true);
        // TO DO AMPDM-1353
        // WMICEMQService.getEMQData(formData, authHeader).then((res) => {}).catch(() => {});
        const data = formData.policyNumber === '1234' ? mockVehicles : mockError;
        if (data.Status === EMQ_RESPONSE_TYPES.ERROR) {
            setlandingPageError(true);
        }
        if (mockVehicles.Vehicles && model.Vehicles.length === 0
            && data.Status === EMQ_RESPONSE_TYPES.SUCCESS) {
            updateVehiclesModel(mockVehicles);
            history.push('/california/estimated-mileage/vehicles-info');
        }
        setLoading(false);
    };

    const overrideProps = {
        wmicLoader: {
            visible: loading
        },
        EMQHeader: {
            visible: !loading,
            content: getPageTitle()
        },
        EMQBody: {
            visible: !loading
        },
        WMICEMQLandingpage: {
            visible: !stepSelected,
            onNext: handleNext,
            searchError: landingPageError
        },
        WMICEMQVehiclesComponent: {
            visible: stepSelected === EMQ_STEPS.VEHICLE_INFO,
            vehicles: model,
            updateVehicles: setEMQVehicleData
        },
        WMICEMQSuccessComponent: {
            visible: stepSelected === EMQ_STEPS.SUCCESS,
            data: mockSubmit
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICEMQLandingPage: WMICEMQLandingPage,
            WMICLoaderComponent: WMICLoader,
            WMICVehiclesComponent: WMICEMQVehiclesComponent,
            WMICEMQSuccessComponent: WMICEMQSuccessComponent
        },
        resolveClassNameMap: styles
    };

    WMICEMQPage.propTypes = {
        match: PropTypes.shape({}).isRequired
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default withRouter(WMICEMQPage);
