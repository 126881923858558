/* eslint-disable indent */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useCallback, useEffect, useState } from 'react';
import { TranslatorContext, IntlContext } from '@jutro/locale'; 
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { DropdownSelectField, InputField } from '@jutro/components';
import styles from '../WMICRemoveVehicleWizard.module.scss';
import messages from '../WMICRemoveVehicleWizard.messages';
import { getVehicleName, getCurrentVehicleIndexByNumber } from '../WMICRemoveVehicleWizardUtils';
import { getMessageContent } from './index';
import { PRIMARY_USE, STATE_KEYS, VEHICLE_KEYS, MESSAGE_TYPES } from '../WMICRemoveVehicleWizardConsts';
import WMICRemoveVehicleMessage from '../../WMICRemoveVehicleMessage/WMICRemoveVehicleMessage';

// check vehicle mileage
export const checkVehicleMileage = (modelState, currentVehicleNumber) => {
    const originalVehicle = modelState[STATE_KEYS.ORIGINAL_VEHICLES].find(
        (v) => v.vehicleNumber === currentVehicleNumber);
    const remainingVehicle = modelState[STATE_KEYS.REMAINING_VEHICLES].find(
        (v) => v.vehicleNumber === currentVehicleNumber);

    return ({
        isMileageReduced: originalVehicle.annualMileage > parseInt(remainingVehicle.annualMileage)
    });
};

export const adjustVehicleUsing = (modelState, modelStateDispatch) => {
    // if delete vehicle is business and one vehicle remaining
    if (modelState[STATE_KEYS.VEHICLE_TO_REMOVE]
        && modelState[STATE_KEYS.VEHICLE_TO_REMOVE][VEHICLE_KEYS.PRIMARY_USE] === 'business'
        && modelState[STATE_KEYS.REMAINING_VEHICLES].length === 1) {

        modelStateDispatch({ type: `${STATE_KEYS.REMAINING_VEHICLES}.0.${VEHICLE_KEYS.PRIMARY_USE}`, value: 'business' });
        if (parseInt(modelState[STATE_KEYS.VEHICLE_TO_REMOVE][VEHICLE_KEYS.ANNUAL_MILEAGE]) > parseInt(modelState[STATE_KEYS.REMAINING_VEHICLES][0][VEHICLE_KEYS.ANNUAL_MILEAGE])) {
            modelStateDispatch({
                type: `${STATE_KEYS.REMAINING_VEHICLES}.0.${VEHICLE_KEYS.ANNUAL_MILEAGE}`,
                value: modelState[STATE_KEYS.VEHICLE_TO_REMOVE][VEHICLE_KEYS.ANNUAL_MILEAGE]
            });
        }
    }

    // if only one driver
    if (modelState[STATE_KEYS.DRIVERS_TO_ASSIGN].length === 1) {
        const driverId = modelState[STATE_KEYS.DRIVERS_TO_ASSIGN][0].code;
        modelState[STATE_KEYS.REMAINING_VEHICLES].forEach((v, idx) => {
            modelStateDispatch({ type: `${STATE_KEYS.REMAINING_VEHICLES}.${idx}.${VEHICLE_KEYS.PRINCIPAL_DRIVER}`, value: driverId })
        });
    }
};

// check vehicles usage
export const checkVehiclesUsages = (modelState) => {
   const checkResult = ({
       isLowMileage: true,
       isLowMileageList: [],
       isChangeToBusiness: true,
       isChangeUnCorrectPrimaryUseList: [],
       summary: false
   });

   if (modelState[STATE_KEYS.VEHICLE_TO_REMOVE]) {
       checkResult.isLowMileageList = _.filter(modelState[STATE_KEYS.REMAINING_VEHICLES], (v) => v.annualMileage < modelState[STATE_KEYS.VEHICLE_TO_REMOVE]?.annualMileage);
       checkResult.isChangeToBusiness = modelState[STATE_KEYS.VEHICLE_TO_REMOVE]?.primaryUse === 'business'
           ? !_.some(modelState[STATE_KEYS.REMAINING_VEHICLES], (v) => v.primaryUse === 'business')
           : false;

        if (!checkResult.isChangeToBusiness) {
           _.forEach(modelState[STATE_KEYS.REMAINING_VEHICLES], (v) => {
               const originalVehicle = _.find(modelState[STATE_KEYS.ORIGINAL_VEHICLES], (_v) => _v.vehicleNumber === v.vehicleNumber);
               if (originalVehicle) {
                   if (originalVehicle.primaryUse !== v.primaryUse &&
                       (!(modelState[STATE_KEYS.VEHICLE_TO_REMOVE]?.primaryUse === 'business' && v.primaryUse === 'business') &&
                       !(originalVehicle.primaryUse === 'pleasure' && v.primaryUse === 'commuting'))) {
                           checkResult.isChangeUnCorrectPrimaryUseList.push({
                               displayName: getVehicleName(v),
                               originalPrimaryUse: originalVehicle.primaryUse,
                               currentPrimaryUse: v.primaryUse,
                               fixedId: v.fixedId
                           });
                   }
               }
           });
       }

       checkResult.isLowMileage = checkResult.isLowMileageList.length === modelState[STATE_KEYS.REMAINING_VEHICLES].length;
       checkResult.summary = !checkResult.isLowMileage && !checkResult.isChangeToBusiness && checkResult.isChangeUnCorrectPrimaryUseList.length === 0;
   }

   return checkResult;
};

const WMICRemoveVehicleUsingStep = (props) => {
    const { modelState, modelStateDispatch, currentVehicleNumber, focusRemoveVehicleWizardTitle, ...otherProps } = props;
    const translator = useContext(TranslatorContext);
    const vehicle = modelState[STATE_KEYS.REMAINING_VEHICLES].find((v) => v.vehicleNumber === currentVehicleNumber);
    const vehicleIdx = getCurrentVehicleIndexByNumber(currentVehicleNumber, modelState[STATE_KEYS.REMAINING_VEHICLES]);
    const intl = useContext(IntlContext);

    const message = vehicleIdx === 0 ? getMessageContent(MESSAGE_TYPES.VEHICLE_USING, translator, intl, modelState) : null;

    const mileagePattern = /[1-9]\d{0,4}/;
    const validateInput = useCallback((v) => {
        const patMatches = v.match(mileagePattern);
        if (!v || (patMatches && patMatches.length===1 && patMatches[0]===v)) {
            modelStateDispatch({ type: `${STATE_KEYS.REMAINING_VEHICLES}.${vehicleIdx}.${VEHICLE_KEYS.ANNUAL_MILEAGE}`, value: v });
        }
    }, [mileagePattern]);
    
    const [exVehicleIdx, setExVehicleIdx] = useState(-1);  //used so react redraw due to value change would not trigger reset of focus

    useEffect(() => {   
        if (exVehicleIdx !== vehicleIdx) {
            setExVehicleIdx(vehicleIdx);
            focusRemoveVehicleWizardTitle();
        }
    });

    return (
        <div {...otherProps}>
            {vehicleIdx === 0 && <WMICRemoveVehicleMessage {...message} />}
            <div className={styles['wmic-edit-title']} >
                {WMICRichTextUtil.translateRichText(translator(messages.usingVehicleMessage, { vehicleName: getVehicleName(vehicle) }))}
            </div>
            <div className={`ww-edit-field-container ${styles['wmic-field-container-margin-bottom']}`}>
                <DropdownSelectField
                    id="dropdown"
                    secondaryLabel={translator(messages.primaryUseVehicleLabel)}
                    className={styles['wmic-field-fit-to-content']}
                    secondaryLabelClassName={styles['wmic-field-label-fit-to-content']}
                    //label is hidden to provide better user experience for accessibility purpose. secondary label is displayed in UI.
                    label={WMICRichTextUtil.translateRichText(translator(messages.primaryUseVehicleLabelAria, { vehicleName: getVehicleName(vehicle) }))}
                    labelClassName={styles['wmicHidden']}
                    availableValues={PRIMARY_USE}
                    value={modelState[STATE_KEYS.REMAINING_VEHICLES][vehicleIdx]?.primaryUse}
                    onValueChange={(v) => modelStateDispatch({ type: `${STATE_KEYS.REMAINING_VEHICLES}.${vehicleIdx}.${VEHICLE_KEYS.PRIMARY_USE}`, value: v })}
                />
            </div>
            <div className="ww-edit-field-container">
                <InputField
                    decimalPlaces={0}
                    id="annualMileage"
                    className={styles['wmic-field-fit-to-content']}
                    //label is hidden to provide better user experience for accessibility purpose. secondary label is displayed in UI.
                    secondaryLabel={translator(messages.annualMileageLabel, { vehicleName: getVehicleName(vehicle) })}
                    secondaryLabelClassName={styles['wmic-field-label-fit-to-content']}
                    label={WMICRichTextUtil.translateRichText(translator(messages.annualMileageOverLimitAria, { vehicleName: getVehicleName(vehicle) }))}
                    labelClassName={styles['wmicHidden']}
                    value={modelState[STATE_KEYS.REMAINING_VEHICLES][vehicleIdx]?.annualMileage}
                    onValueChange={(v) => validateInput(v)}
                />
            </div>
            <div className={styles.wmicInputBoxNotes}>
                {translator(messages.annualMileageOverLimit)}
            </div>
        </div>
    );
}

WMICRemoveVehicleUsingStep.propTypes = {
    modelState: PropTypes.shape({}).isRequired,
    currentVehicleNumber: PropTypes.string,
    modelStateDispatch: PropTypes.func.isRequired,
    focusRemoveVehicleWizardTitle: PropTypes.func
};

export default WMICRemoveVehicleUsingStep;
