import { serviceUrls, enrollmentCredentials } from 'app-config';

export default class EnrollmentService {
    static integrationAuthCredentials = window.btoa(`${enrollmentCredentials.username}:${enrollmentCredentials.password}`);
    static requestBody = {};
    static simpleRegistration(formData, recaptchaToken) {
        this.requestBody = {
            email: formData.emailAddress
        }

        return fetch(`${serviceUrls.enrollmentUrlEndpoint}/users-amp`, {
            method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Basic ${this.integrationAuthCredentials}`,
              },
              body: JSON.stringify({
                  ...this.requestBody,
                  preferredLanguage: "English",
                  locale: "en_US",
                  recaptcha: {
                      token: recaptchaToken
                  }
              })
        });
    }

    static detailedRegistration(formData, recaptchaToken) {
        this.requestBody = {
            firstName: formData.firstName.trim(),
            lastName: formData.lastName.trim(),
            email: formData.emailAddress,
            login: formData.emailAddress,
            phone: formData.phoneNumber.replaceAll('-', ''),
            policyOrAccountNumber: formData.policyOrAccountNumber.replaceAll(/-[0-9]+$/g, ''),
            postalCode: formData.zipCode
        };

        return fetch(`${serviceUrls.enrollmentUrlEndpoint}/users-amp`, {
          method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Basic ${this.integrationAuthCredentials}`,
            },
            body: JSON.stringify({
                ...this.requestBody,
                preferredLanguage: "English",
                locale: "en_US",
                recaptcha: {
                    token: recaptchaToken
                }
            })
        });
    }

    static createPassword(formData, recaptchaToken) {
        this.requestBody = formData;

        return fetch(`${serviceUrls.enrollmentUrlEndpoint}/users-amp/password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Basic ${this.integrationAuthCredentials}`,
            },
            body: JSON.stringify({
                ...this.requestBody,
                preferredLanguage: "English",
                locale: "en_US",
                recaptcha: {
                    token: recaptchaToken
                }
            })
        });
    }

    static redirectAmpUserToConsentForm(sessionToken) {
        const redirectUrl = _.escape(`${window.location.origin}/emailConsent`);
        window.location.href = `${serviceUrls.enrollmentIdpUri}/login/sessionCookieRedirect?token=${sessionToken}&redirectUrl=${redirectUrl}`
    }

    static redirectAmpUserToLandingPage(sessionToken) {
        const landingPage = _.escape(`${window.location.origin}`);
        window.location.href = `${serviceUrls.enrollmentIdpUri}/login/sessionCookieRedirect?token=${sessionToken}&redirectUrl=${landingPage}`
    }

    static activateToken(recoveryToken) {
        return fetch(`${serviceUrls.enrollmentUrlEndpoint}/tokens/activate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Basic ${this.integrationAuthCredentials}`,
            },
            body: JSON.stringify({ recoveryToken })
        });
    }

    static resendRegistrationEmail(emailAddress) {
        return fetch(`${serviceUrls.enrollmentUrlEndpoint}/users-amp/lifecycle/reactivate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Basic ${this.integrationAuthCredentials}`,
            },
            body: JSON.stringify({
                email: emailAddress
            })
        });
    }

    static sendResetPasswordEmail(emailAddress, recaptchaToken) {
        return fetch(`${serviceUrls.enrollmentUrlEndpoint}/users-amp/recover`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Basic ${this.integrationAuthCredentials}`,
            },
            body: JSON.stringify({
                email: emailAddress,
                recaptcha: {
                    token: recaptchaToken
                }
            })
        });
    }
}
