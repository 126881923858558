import { MetadataContent } from '@jutro/uiconfig';


import metadata from './FNOLReviewBlockComponent.metadata.json5';
import styles from './FNOLReviewBlockComponent.module.scss'

import React from 'react';

function FNOLReviewBlockComponent({data, title, visible = true, noContentWarningMessage = ''}) {
    const overrideProps = {
        FNOLReviewComponentListContainer: {
            data: data
        },
        FNOLReviewComponentTitle: {
            content: title,
            visible: title.length > 0
        },
        FNOLPAReviewBlockComponent: {
            visible
        },
        FNOLReviewComponentNoContent: {
            content: noContentWarningMessage
        }
    }
    const resolvers = {
        resolveClassNameMap: styles,
    }

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

export default FNOLReviewBlockComponent