/* eslint-disable max-len */
import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { 
    ModalNext,
    ModalFooter
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WMICButton } from 'wmic-components-platform-react';
import _ from 'lodash';
import { Decimal } from 'decimal.js-light';
import {WMICPaymentUtil, WMICFeatureFlagUtil, WMICRichTextUtil, WMICPaymentAccountContactUtil, WMICRemoteLogger} from 'wmic-portals-utils-js';
import { PolicyService } from 'gw-capability-policy';
import { useAuthentication } from 'wmic-digital-auth-react';
import { WMICManageBankingInformation, WMICChangePaymentPlanErrorModal } from 'gw-capability-policy-react';
import {useHistory} from "react-router-dom";
import styles from './WMICPaymentModal.module.scss';
import WMICConfirmPaymentModal from './WMICConfirmPaymentModal';
import WMICMakeAPaymentModal from './WMICMakeAPaymentModal';
import WMICProcessingPaymentModal from './WMICProcessingPaymentModal';
import WMICFailedPaymentModal from './WMICFailedPaymentModal';
import WMICSuccessPaymentModal from './WMICSuccessPaymentModal';
import WMICInProgressPaymentModal from './WMICInProgressPaymentModal';
import WMICScheduledPaymentModal from './WMICScheduledPaymentModal';
import WMICOneIncPaymentModal from '../WMICOneIncPaymentModal/WMICOneIncPaymentModal';
import WMICPaymentWarningModal from './WMICPaymentWarningModal';
import messages from './WMICPaymentModal.messages';

const STEPS = {
    CONFIRM_PAYMENT_PAGE: 1,
    MAKE_A_PAYMENT_PAGE: 2,
    PAYMENT_PROCESSING: 3,
    PAYMENT_FAILED: 4,
    PAYMENT_INVALID_ROUTING_TRANSIT_NUMBER: 5,
    PAYMENT_SUCCESS: 6,
    PAYMENT_IN_PROGRESS: 7,
    PAYMENT_SCHEDULED: 8,
    MANAGE_BANKING_INFORMATION: 9,
    PAYMENT_WARNINGS: 10
};

const VALIDATORS = {
    POSTAL_CODE: /^\d{5}-\d{4}$/
};

const trimLongFormZipCodes = (postalCode) => {
    if (postalCode && VALIDATORS.POSTAL_CODE.test(postalCode)) {
        return postalCode.substr(0, 5);
    }
    return postalCode;
};

const WMICPaymentModal = (props) => {
    const [step, updateStep] = useState(STEPS.CONFIRM_PAYMENT_PAGE);
    const [paymentDate, updatePaymentDate] = useState(undefined);
    const [saveBankAccount, updateSaveBankAccount] = useState(false);
    const [isBankAccountSaved, updateIsBankAccountSaved] = useState(false);
    const [selectedPaymentAmount, updateSelectedPaymentAmount] = useState(undefined);
    const [accountInfo, updateAccountInfo] = useState(undefined);
    const [makingOneIncPayment, updateMakingOneIncPayment] = useState(false);
    const [oneIncPaymentDetails, updateOneIncPaymentDetails] = useState({});
    const [oneIncPayNowEnabled, updateOneIncPayNowEnabled] = useState(false);
    const {
        isOpen,
        onReject,
        onResolve,
        data,
        fetchBillingData,
        ldFlags,
        isOpenFromOverdueContainer
    } = props;

    const translator = useTranslator();
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags(); 
    const policyStatus = _.get(data, 'billingData.policyStatus');
    const billingData = _.get(data, 'billingData', {});
    const policyData = _.get(data, 'policyData', {});
    const policyType = _.get(data, 'policyData.overview', '');
    const policyNumber = _.get(data, 'policyData.policyId', '');
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const history = useHistory();

    if (_.isEmpty(policyStatus)) {
        _.set(data, 'billingData.policyStatus', _.get(data, 'policyData.displayStatus_WMIC', ''));
    }

    const paymentsFeatureFlagAvailability = useCallback(() => {
        return WMICFeatureFlagUtil.queryAvailabilityAMP(ldFlags, featureFlags.PAYMENTS);
    }, [ldFlags, featureFlags]);

    const handleClose = () => {
        onReject();
    };

    const makeOneIncPayment = (accountContact) => {
        const paymentData = {
            amountOverdue: WMICPaymentUtil.getOverdueAmount(billingData),
            paymentDue: WMICPaymentUtil.getPaymentDue(billingData),
            renewalPaymentDue: WMICPaymentUtil.getRenewalPaymentDue(billingData),
            nextPlannedAmount: WMICPaymentUtil.getNextPayment(billingData),
            remainingBalance: WMICPaymentUtil.getRemainingBalance(billingData),
        }
        
        const requiredPaymentAmounts = [];
        const optionalPaymentAmounts = [];

        if (paymentData.amountOverdue.isPositive()) {
            requiredPaymentAmounts.push(paymentData.amountOverdue.toString());
        }
        if (paymentData.paymentDue.isPositive()) {
            requiredPaymentAmounts.push(paymentData.paymentDue.toString());
        }
        if (paymentData.renewalPaymentDue.isPositive()) {
            requiredPaymentAmounts.push(paymentData.renewalPaymentDue.toString());
        }
        if (paymentData.nextPlannedAmount.isPositive()) {
            if (requiredPaymentAmounts.length === 0) {
                requiredPaymentAmounts.push(paymentData.nextPlannedAmount.toString());
            } else {
                optionalPaymentAmounts.push(paymentData.nextPlannedAmount.toString());
            }
        }
        if (paymentData.remainingBalance.isPositive()) {
            if (requiredPaymentAmounts.length === 0 && paymentData.nextPlannedAmount.isZero()) {
                requiredPaymentAmounts.push(paymentData.remainingBalance.toString());
            } else {
                optionalPaymentAmounts.push(paymentData.remainingBalance.toString());
            }
        }

        let sumRequiredAmounts = _.reduce(requiredPaymentAmounts, (memory, item) => {
            return new Decimal(memory).plus(item);
        }, 0);
        let sumOptionalAmounts = _.reduce(optionalPaymentAmounts, (memory, item) => {
            return new Decimal(memory).plus(item);
        }, 0);
        sumRequiredAmounts = Number(WMICPaymentUtil.round(sumRequiredAmounts));
        sumOptionalAmounts = Number(WMICPaymentUtil.round(sumOptionalAmounts));
        const accountBalance = sumRequiredAmounts + sumOptionalAmounts;

        const oneIncDetails = {
            paymentType: 'ECheck',
            minAmountDue: sumRequiredAmounts.toString(),
            accountBalance: accountBalance.toString(),
            userDisplayName: _.get(accountContact, 'displayName'),
            email: _.get(accountContact, 'emailAddress1'),
            billingAddress: {
                addressLine1: _.get(accountContact, 'primaryAddress.addressLine1'),
                city: _.get(accountContact, 'primaryAddress.city'),
                state: _.get(accountContact, 'primaryAddress.state'),
                postalCode: trimLongFormZipCodes(_.get(accountContact, 'primaryAddress.postalCode'))
            },
            policyId: _.get(billingData, 'policyId'),
            contactPublicId: _.get(accountContact, 'publicID'),
            accountNumber: _.get(billingData, 'accountSummary.accountNumber'),
            clientReferenceData1: WMICPaymentUtil.isListBill(billingData) ? 
                _.get(billingData, 'accountSummary.defaultUnappliedFundNumber')
                : WMICPaymentUtil.getInvoiceStreamId(policyData, billingData, billingData.policyStatus),
            creditCardAllowed: true
        };

        updateOneIncPaymentDetails(oneIncDetails);
        updateOneIncPayNowEnabled(true);
        updateMakingOneIncPayment(true);
    };

    const getPolicyDetails = async () => {
        if (paymentsFeatureFlagAvailability().isAvailable) {
            const policy = await PolicyService.getAccountPolicyDetails_WMIC(billingData.policyId, billingData.termNumber, authHeader, authUserData.firstName, authUserData.lastName);
            await WMICPaymentAccountContactUtil.getPickedContact(authHeader, authUserData, policy.currentPeriod.contacts).then(pickedContact => {
                if (pickedContact) {
                    makeOneIncPayment(pickedContact);
                } else {
                    handleError(`Contact Not Found for user with grantedAuthorities: ${JSON.stringify(authUserData?.grantedAuthorities)}`);
                }
            });
        }
    };

    function handleError(e) {
        onResolve();
        const logger = WMICRemoteLogger.getWMICRemoteLogger(
            'WMICReviewContactInfoModal',
            history.location.pathname,
            window.location,
            ldFlags,
            authHeader
        );
        logger.error(e);
        history.push('technicalErrorPage/issueWithAccount');
    }

    const paymentModalClosed = () => {
        updateMakingOneIncPayment(false);
        updateOneIncPayNowEnabled(false);
        updateOneIncPaymentDetails({
            paymentMethod: null
        });
        fetchBillingData();
        onReject();
    };

    const modal = useMemo(() => {
        if (WMICPaymentUtil.isListBill(billingData) || WMICPaymentUtil.shouldDisplayInactivePolicyMessage(billingData, policyData.displayStatus_WMIC) || WMICPaymentUtil.isDelinquent(billingData)) {
            updateStep(STEPS.PAYMENT_WARNINGS);
        } else {
            getPolicyDetails();
            return;
        }
        switch (step) {
            case STEPS.MAKE_A_PAYMENT_PAGE:
                return (
                    <WMICMakeAPaymentModal
                        data={data}
                        selectedPaymentAmount={selectedPaymentAmount}
                        onReject={onReject}
                        onManageBankingInformation={(accInfo) => {
                            updateAccountInfo(accInfo);
                            updateStep(STEPS.MANAGE_BANKING_INFORMATION);
                        }}
                        onPaymentProcessing={() => updateStep(STEPS.PAYMENT_PROCESSING)}
                        onPaymentFailed={() => updateStep(STEPS.PAYMENT_FAILED)}
                        onInvalidRoutingTransitNumber={() => updateStep(STEPS.PAYMENT_INVALID_ROUTING_TRANSIT_NUMBER)}
                        onPaymentSuccess={(saveBankAcc, isBankAccSaved) => {
                            updateSaveBankAccount(saveBankAcc);
                            updateIsBankAccountSaved(isBankAccSaved);
                            updateStep(STEPS.PAYMENT_SUCCESS);
                        }}
                        onPaymentInProgress={(saveBankAcc, isBankAccSaved) => {
                            updateSaveBankAccount(saveBankAcc);
                            updateIsBankAccountSaved(isBankAccSaved);
                            updateStep(STEPS.PAYMENT_IN_PROGRESS);
                        }}
                        onPaymentScheduled={(date, saveBankAcc, isBankAccSaved) => {
                            updatePaymentDate(date);
                            updateSaveBankAccount(saveBankAcc);
                            updateIsBankAccountSaved(isBankAccSaved);
                            updateStep(STEPS.PAYMENT_SCHEDULED);
                        }}
                    />
                );
            case STEPS.MANAGE_BANKING_INFORMATION:
                return (
                    <WMICManageBankingInformation
                        accountInfo={accountInfo}
                        selectedPaymentAmount={selectedPaymentAmount}
                        onBack={() => updateStep(STEPS.MAKE_A_PAYMENT_PAGE)}
                        ldFlags={ldFlags}
                    />
                );
            case STEPS.PAYMENT_PROCESSING:
                return (<WMICProcessingPaymentModal />);
            case STEPS.PAYMENT_FAILED:
                return (<WMICFailedPaymentModal onClose={() => { updateStep(STEPS.MAKE_A_PAYMENT_PAGE); }} />);
            case STEPS.PAYMENT_INVALID_ROUTING_TRANSIT_NUMBER:
                return (<WMICChangePaymentPlanErrorModal invalidRoutingTransitNumber onClose={() => { updateStep(STEPS.MAKE_A_PAYMENT_PAGE); }} />);
            case STEPS.PAYMENT_SUCCESS:
                return (
                    <WMICSuccessPaymentModal
                        fetchBillingData={fetchBillingData}
                        onReject={onReject}
                        onResolve={onResolve}
                        saveBankAccount={saveBankAccount}
                        isBankAccountSaved={isBankAccountSaved}
                        isOpenFromOverdueContainer={isOpenFromOverdueContainer}
                    />
                );
            case STEPS.PAYMENT_IN_PROGRESS:
                return (
                    <WMICInProgressPaymentModal
                        onReject={onReject}
                        onResolve={onResolve}
                        saveBankAccount={saveBankAccount}
                        isBankAccountSaved={isBankAccountSaved}
                    />
                );
            case STEPS.PAYMENT_SCHEDULED:
                return (
                    <WMICScheduledPaymentModal
                        onReject={onReject}
                        paymentDate={paymentDate}
                        saveBankAccount={saveBankAccount}
                        isBankAccountSaved={isBankAccountSaved}
                    />
                );
            case STEPS.PAYMENT_WARNINGS:
                return (
                    <WMICPaymentWarningModal
                        billingData={billingData}
                        policyStatus={policyData.displayStatus_WMIC}
                        policyNumber={policyNumber}
                        policyType={policyType}
                        onReject={onReject}
                        onResolve={() => { getPolicyDetails(); }}
                    />
                );
            case STEPS.CONFIRM_PAYMENT_PAGE:
            default:
                return (
                    <WMICConfirmPaymentModal
                        data={data}
                        updateSelectedPaymentAmount={updateSelectedPaymentAmount}
                        onContinue={() => { updateStep(STEPS.MAKE_A_PAYMENT_PAGE); }}
                        onReject={onReject}
                    />
                );
        }
    }, [step, data, paymentDate]);

    if (!paymentsFeatureFlagAvailability().isAvailable) {
        return (
            <ModalNext isOpen={isOpen}
                       shouldCloseOnOverlayClick={false}
                       shouldCloseOnEsc
                       onRequestClose={handleClose}>
                <div className={styles.modalHeaderBodyContainer}>
                    <h1 className="wmic-modal-header-title">{translator(messages.paymentSystemUnavailable)}</h1>
                </div>
                <div className={styles.modalBodyContainer}>{WMICRichTextUtil.translateRichText(translator(messages.paymentSystemCurrentlyUnavailable))}</div>
                <ModalFooter>
                    <WMICButton
                        onClick={() => {
                            paymentModalClosed();
                        }}
                    >
                        {translator(messages.close)}
                    </WMICButton>
                </ModalFooter>
            </ModalNext>
        );
    }
    
    if (makingOneIncPayment) {
        return (
            <WMICOneIncPaymentModal
                paymentModalClosed={paymentModalClosed}
                oneIncPaymentDetails={oneIncPaymentDetails}
                payNowEnabled={oneIncPayNowEnabled}
                updateModalLoaded={() => _.noop()}
            />
        );
    }

    return (
        <ModalNext
            isOpen={isOpen}
            className={styles['ww-payment-modal']}
            overlayClassName={styles['ww-payment-modal-overlay']}
            onRequestClose={handleClose}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc
            contentLayout={{
                component: 'Grid',
                componentProps: {
                    rows: ['auto'],
                },
            }}
        >
            {modal}
        </ModalNext>
    );
};

WMICPaymentModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    data: PropTypes.shape({}),
    fetchBillingData: PropTypes.func,
    isOpenFromOverdueContainer: PropTypes.bool,
    ldFlags: PropTypes.shape({}).isRequired
};

WMICPaymentModal.defaultProps = {
    data: {},
    isOpenFromOverdueContainer: false
};

export default WMICPaymentModal;