import React from 'react';
import moment from 'moment';
import { MetadataContent } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';

import metadata from './WMICEMQSuccessComponent.metadata.json5';
import messages from './WMICEMQSuccessComponent.messages';

function WMICEMQSuccessComponent(props) {
    const translator = useTranslator();
    const { data } = props;

    const getCardContent = (text) => {
        return (
            <p>
                {translator(messages.EMQSuccessComponentCardText1)}
                &nbsp;
                <strong>
                    #
                    {text.PolicyNumber}
                </strong>
                &nbsp;
                {translator(messages.EMQSuccessComponentCardText2)}
                &nbsp;
                <strong>
                    {moment().format('LLLL')}
                </strong>
                .
                &nbsp;
                {translator(messages.EMQSuccessComponentCardText3)}
            </p>
        );
    };

    const overrideProps = {
        emqSuccessCardContent: {
            content: getCardContent(data)
        }
    };

    WMICEMQSuccessComponent.propTypes = {
        data: PropTypes.shape({}).isRequired
    };

    return <MetadataContent uiProps={metadata.componentContent} overrideProps={overrideProps} />;
}

export default WMICEMQSuccessComponent;
