/* eslint-disable camelcase */
import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import { WMICJsonRPCService } from 'wmic-portals-transport-js';

export default class AccountBillingDetailsService {
    /**
     * Retrieves an accounts billing summary.
     *
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof AccountBillingDetailsService
     */
    static getAccountBillingSummary(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingData'), 'getAccountBillingSummary', [], authHeader);
    }

    /**
     * Retrieves an accounts profile summary.
     *
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof AccountBillingDetailsService
     */
    static getAccountSummaryForProfile(authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'getAccountSummaryForProfile', [], authHeader);
    }

    /**
     * Retrieves an invoice summary for account.
     *
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static getAccountInvoiceStreamInformation(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingData'), 'getAccountInvoiceStreamInformation', [], authHeader);
    }

    /**
     * Retrieves details of invoices for the invoice id.
     *
     * @param {String} invoiceStreamId a public-id of invoice stream data
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static getInvoiceStreamDetails(invoiceStreamId, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingData'), 'getInvoiceStreamDetails', [invoiceStreamId], authHeader);
    }

    /**
     * Set a payment instrument as automatic payment instrument.
     *
     * @param {String} invoiceStreamId a public-id of invoice stream data
     * @param {Object} instrument a payment instrument in PaymentInstrumentDTO
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static setPaymentGroupPaymentInformation(invoiceStreamId, instrument, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingData'),
            'setPaymentGroupPaymentInformation', [invoiceStreamId, instrument], authHeader);
    }

    /**
     * Pay for selected invoices with a payment instrument.
     *
     * @param {Array} invoiceIds array of public-ids of invoices
     * @param {Object} amount a payment amount in BigDecimal
     * @param {Object} instrument a payment instrument in PaymentInstrumentDTO
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static makeDirectBillPayment(invoiceIds, amount, instrument, authHeader = {}) {
        const directBillPaymentDto = {
            invoiceIds,
            amountPaid: amount,
            paymentInstrument: instrument
        };

        return JsonRPCService.send(getProxiedServiceUrl('directBillPayment'),
            'makeDirectBillPayment', [directBillPaymentDto], authHeader);
    }

    /**
     * Retrieves accounts billing delivery method
     *
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static getInvoiceDeliveryMethods(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingData'), 'getInvoiceDeliveryMethods', [], authHeader);
    }

    /**
     * Sets a new billing delivery method for the account
     *
     * @param {String} newDeliveryMethod a delivery method code
     * @param {String} accountNumber an account number to set the delivery method
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     */
    static setInvoiceDeliveryMethodForAccount(newDeliveryMethod, accountNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('billingData'), 'setInvoiceDeliveryMethodForAccount', [newDeliveryMethod, accountNumber], authHeader);
    }

    /**
     * Retrieves an accounts billing summary.
     *
     * @param {Object} authHeader additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof AccountBillingDetailsService
     */
    // eslint-disable-next-line
    static getPolicyPaymentSummary_WMIC(authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'getPolicyPaymentSummary_WMIC', [], authHeader);
    }

    // eslint-disable-next-line
    static getPolicyBillingSummary_WMIC(policyNumber, termNumber, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'getPolicyBillingSummary_WMIC', [policyNumber, termNumber], authHeader);
    }

    // eslint-disable-next-line
    static getPaymentPlanChangesWithInvoices_WMIC(policyNumber, termNumber, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'getPaymentPlanChangesWithInvoices_WMIC', [policyNumber, termNumber], authHeader);
    }

    static changePaymentPlanToMAR(policyNumber, termNumber, newPaymentPlanName, AccountBankDetailsDTO, ContactDTO, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'changePaymentPlanToMAR', [policyNumber, termNumber, newPaymentPlanName, AccountBankDetailsDTO, ContactDTO], authHeader);
    }    

    // eslint-disable-next-line
    static changePaymentPlanTo(policyNumber, termNumber, newPaymentPlanName, authHeader = {}) {
        return WMICJsonRPCService.send(
            getProxiedServiceUrl('billingData'), 'changePaymentPlanTo', [policyNumber, termNumber, newPaymentPlanName], authHeader
        );
    }

    // eslint-disable-next-line
    static getAccountBillingDetailsForMarPolicy(policyNumber, termNumber, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'getAccountBillingDetailsForMarPolicy', [policyNumber, termNumber], authHeader);
    }

    // eslint-disable-next-line
    static getAccountBillingDetailsForPolicy(policyNumber, termNumber, publicID, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'getAccountBillingDetailsForPolicy', [policyNumber, termNumber, publicID], authHeader);
    }

    // eslint-disable-next-line
    static getPendingPaymentRequests_WMIC(invoiceStreamId, authHeader = {}) {
        // eslint-disable-next-line max-len
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'getPendingPaymentRequests_WMIC', [invoiceStreamId], authHeader);
    }

    // eslint-disable-next-line
    static cancelPendingPaymentRequest_WMIC(pendingPaymentProcessID, authHeader = {}) {
        // eslint-disable-next-line max-len
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'cancelPendingPaymentRequest_WMIC', [pendingPaymentProcessID], authHeader);
    }

    // eslint-disable-next-line
    static makeOneTimeBankAccountPayment_WMIC(directBillPaymentDTO, authHeader = {}) {
        // eslint-disable-next-line max-len
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'makeOneTimeBankAccountPayment_WMIC', [directBillPaymentDTO], authHeader);
    }

    // eslint-disable-next-line
    static getOTWPaymentStatus_WMIC(processID, authHeader = {}) {
        // eslint-disable-next-line max-len
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'getOTWPaymentStatus_WMIC', [processID], authHeader);
    }

    // eslint-disable-next-line camelcase
    static policyChangeCheckHasDelinquency_WMIC(policyNumber, termNumber, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'policyChangeCheckHasDelinquency_WMIC', [policyNumber, termNumber], authHeader);
    }

    // eslint-disable-next-line camelcase
    static getPolicyPaymentSummaryByPolicyNumber_WMIC(policyNumber, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'getPolicyPaymentSummaryByPolicyNumber_WMIC', [policyNumber], authHeader);
    }

    static getBankingInfoForProfile(accountNumber, publicID, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'getBankingInfoForProfile', [accountNumber, publicID], authHeader);
    }

    static addNewBankingInfo(accountNumber, directBillPaymentDTO, contactID, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'addNewBankingInfo', [accountNumber, directBillPaymentDTO, contactID], authHeader);
    }

    // eslint-disable-next-line max-len
    static addNewBankingInfoWithConsentType(accountNumber, directBillPaymentDTO, contactID, consentType, policyNumber, saveConsent, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'addNewBankingInfoWithConsentFlow', [accountNumber, directBillPaymentDTO, contactID, consentType, policyNumber, saveConsent], authHeader);
    }    

    static editBankingInfo(accountNumber, accountBankDetailsDTO, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'editBankingInfo', [accountNumber, accountBankDetailsDTO], authHeader);
    }

    // eslint-disable-next-line max-len
    static editBankingInfoWithConsentType(accountNumber, accountBankDetailsDTO, consentType, policyNumber, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'editBankingInfoWithConsentFlow', [accountNumber, accountBankDetailsDTO, consentType, policyNumber], authHeader);
    }

    static deleteBankAccount(publicId, authHeader = {}) {
        return WMICJsonRPCService.send(getProxiedServiceUrl('billingData'), 'deleteBankAccount', [publicId], authHeader);
    }

    static deletePaymentInstrument_Ext(paymentInstrumentTokenId, accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('billingData'), 'deletePaymentInstrument_Ext', [paymentInstrumentTokenId, accountNumber], additionalHeaders);
    }

    // eslint-disable-next-line camelcase
    static getBillingDocumentsByPolicy_WMIC(policyNumber, termNumber, additionalHeaders = {}) {
        return WMICJsonRPCService.send(
            getProxiedServiceUrl('billingData'), 'getBillingDocumentsByPolicy_WMIC', [policyNumber, termNumber], additionalHeaders
        );
    }
    
    // eslint-disable-next-line camelcase
    static getBillingDocumentsByPolicyAndTerm_WMIC(policyNumber, termNumber, additionalHeaders = {}) {
        return WMICJsonRPCService.send(
            getProxiedServiceUrl('billingData'), 'getBillingDocumentsByPolicyAndTerm_WMIC', [policyNumber, termNumber], additionalHeaders
        );
    }

    static getOneIncSessionDetails(accountNumber, accountContactId, additionalHeaders = {}) {
        return WMICJsonRPCService.send(
            getProxiedServiceUrl('billingData'), 'getOneIncSessionDetails', [accountNumber, accountContactId], additionalHeaders
        );
    }

    static getPaymentInstrumentTokenSummary(accountNumber, publicId, paymentMethod, additionalHeaders = {}) {
        return WMICJsonRPCService.send(
            getProxiedServiceUrl('billingData'), 'getPaymentInstrumentTokenSummary', [accountNumber, publicId, paymentMethod], additionalHeaders
        );
    }

    static getPaymentInstrumentTokenSummaryForMarPolicy(policyNumber, termNumber, additionalHeaders = {}) {
        return WMICJsonRPCService.send(
            getProxiedServiceUrl('billingData'), 'getPaymentInstrumentTokenSummaryForMarPolicy', [policyNumber, termNumber], additionalHeaders
        );
    }
}
