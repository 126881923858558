import React, { useState, useCallback } from 'react';
import { MetadataForm } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import { WMICAnimatedLoaderButton } from 'wmic-components-platform-react';
import { WMICReCaptchaService } from 'wmic-portals-recaptcha-js';

import CheckEmailRegistrationDialog from '../dialogs/CheckEmailRegistrationDialog/CheckEmailRegistrationDialog';
import ValidateErrorMessage from "../common/ValidateErrorMessage/ValidateErrorMessage";

import EnrollmentService from '../services/EnrollmentService';
import ErrorCodes from '../utils/WMICEnrollmentErrorCodes';

import metadata from './RegisterPage.metadata.json5';
import styles from './RegisterPage.module.scss';
import messages from './RegisterPage.messages';
import ForgotLoginInfo from '../common/ForgotLoginInfo/ForgotLoginInfo';
import { set } from 'lodash';
import { _ } from 'core-js';
import { translateRichText } from '../utils/WMICRichTextUtil';

function RegisterPage(props) {
    const [submitted, setSubmitted] = useState(false);
    const [formErrorCode, setFormErrorCode] = useState('');
    const [isDetailedRegistration, setIsDetailedRegistration] = useState(false);
    const [formData, setFormData] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [isUserCreated, setIsUserCreated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [theTextToRead, setTheTextToRead] = useState('');
    const translator = useTranslator();

    const HTTP_STATUS_SUCCESS = 200;

    const handleValueChange = (value, path) => {
        if (submitted) {
            setSubmitted(false);
        }
        setFormData(prevState => {
            const newState = {...prevState}
            set(newState, path, value);
            return newState
        });
    };

    const onValidationChange = useCallback((newIsValid) => {
        setIsValid(newIsValid);
    }, []);

    const getErrorClassName = (msg) => {
        if (msg) {
            return 'is-size-4 p-3 mb-4 ww-error-block';
        } else {
            return 'is-invisible';
        }
    }

    const showServerError =  useCallback(() => {
        let msg = '';
        let dynamicErrorParams = {};

        if(formErrorCode === ErrorCodes.ACCOUNT_ACTIVATED) {
            msg = messages.validationUserExists
            dynamicErrorParams= { emailAddress: formData.emailAddress};
        } else if(formErrorCode === ErrorCodes.PC_INFO_MISMATCH || formErrorCode === ErrorCodes.POLICY_CENTER_JSON_RPC_ERROR) {
            msg = messages.validationEncounteredAnIssue;
        } else if (formErrorCode === ErrorCodes.GENERIC_ERROR) {
            msg = messages.genericErrorMessage;
        }
        
        return {
            formNotificationContainer: {
                className: msg ? '' : 'is-sr-only'
            },
            formNotification: {
                message: translateRichText(translator(msg, dynamicErrorParams)),
                className: getErrorClassName(msg)
            }
        };

    }, [formData.emailAddress, formErrorCode, translator]);

    const saveForm = useCallback(async() => {
        setFormErrorCode('');   //this is required to clear the message so that the screen reader will read a new error if it exists
        
        if(!isValid) {
            setSubmitted(true);

            return;
        }

        setIsLoading(true);

        let fetchPromise = undefined;

        if(!isDetailedRegistration) {
            const token = await WMICReCaptchaService.fetchReCaptchaToken('submit', true);
            fetchPromise = EnrollmentService.simpleRegistration(formData, token);
        } else {
            // request a new token, overriding cached value - simple enrollment failed with no page/form refresh
            const token = await WMICReCaptchaService.fetchReCaptchaToken('submit', true);
            fetchPromise = EnrollmentService.detailedRegistration(formData, token);
        }

        fetchPromise.then(response => {
            if(response.status == HTTP_STATUS_SUCCESS) {
                setIsUserCreated(true);
                return;
            }
            // force error for all other statuses including successful ones ie. 201 - 299
            return response.json().then(err => Promise.reject(err));
        }).catch(error => {
            if(!isDetailedRegistration && error?.code != ErrorCodes.ACCOUNT_ACTIVATED) {
                setSubmitted(false);
                setIsDetailedRegistration(true);
                return;
            }
            setFormErrorCode(error?.code || ErrorCodes.GENERIC_ERROR)
            setSubmitted(true);
        }).finally(() => setIsLoading(false));
    }, [formData, isDetailedRegistration, isValid]);


    const canAutoTrim = useCallback((path) => {
        const value = _.get(formData, path);
        return /^(\s.*|.*\s)$/.test(value);
    }, [formData]);

    const textToReadCallback = (textToRead) => {
        setTheTextToRead(textToRead);
    }

    const overrideProps = {
        //could not get '@fields' working - using '@all' with a guard on form fields. i.e. includes('enrollment')
        '@all': (id, path) => {
            if(id.includes('enrollment')) {
                return {
                    required: isDetailedRegistration,
                    autoTrim: canAutoTrim(path),
                    messageProps: {
                        requiredField: `${translator(messages[`${path}Label`])} is a required field`
                    }               
                }
            }
        },
        pageDescription: {
            content: isDetailedRegistration ? translator(messages.additionalDetails) : translator(messages.registerDescription)
        },
        enrollmentEmailAddress: {
            required: true,
            disabled: isDetailedRegistration
        },
        detailedForm: {
            visible: isDetailedRegistration
        },
        registrationContent: {
            visible: !isUserCreated
        },
        confimationDialog: {
            textToReadCallback,
            visible: isUserCreated,
            emailAddress: formData.emailAddress
        },
        validationErrorContainer: {
            showMessage: submitted
        },
        textToRead: {
            content: theTextToRead
        },
        submit: {
            isAnimated: isLoading
        },
        ...showServerError(),
    };

    return (
        <MetadataForm
            uiProps={metadata.pageContent}
            data={formData}
            onDataChange={handleValueChange}
            classNameMap={styles}
            overrideProps={overrideProps}
            onValidationChange={onValidationChange}
            showErrors={submitted && isValid}
            componentMap={{
                confirmationDialog: CheckEmailRegistrationDialog,
                forgotLoginInfo: ForgotLoginInfo,
                validationError: ValidateErrorMessage,
                WMICButton: WMICAnimatedLoaderButton
            }}
            callbackMap={{
                saveForm: saveForm
            }}/>
    );
}

export default RegisterPage;
