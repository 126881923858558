import React, { useState, useCallback } from 'react';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { WMICButton } from 'wmic-components-platform-react';

import metadata from './PasswordResetSuccessDialog.metadata.json5';
import './PasswordResetSuccessDialog.messages';

function PasswordResetSuccessDialog(props) {
    const history = useHistory();

    const saveForm = useCallback(() => {
        history.push('/');
    }, []);

    const overrideProps = {};

    const resolvers = {
        resolveComponentMap: {
            WMICButton
        },
        resolveCallbackMap: {
            saveForm: saveForm
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

export default PasswordResetSuccessDialog;