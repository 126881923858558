/* eslint-disable camelcase */
import { getProxiedServiceUrl } from 'wmic-portals-url-js';
import { WMICJsonRPCService } from 'wmic-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return WMICJsonRPCService.send(getProxiedServiceUrl('policyChange'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the Quote and Buy backend operations
 *
 * @export
 * @class EndorsementService
 */
export default class EndorsementService {
    /**
     * Updates, saves and performs a quote on an existing submission.
     * Generates quotes for all available product offerings.
     *
     * @param {Object} data tge submission that will be saved
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof EndorsementService
     */
    static loadEndorsement(data, additionalHeaders = {}) {
        return processSubmission('load', [data], additionalHeaders);
    }

    static loadEndorsementWithEffectiveDate(data, additionalHeaders = {}) {
        return processSubmission('loadWithEffectiveDate', data, additionalHeaders);
    }

    static saveEndorsement(data, additionalHeaders = {}) {
        return processSubmission('save', data, additionalHeaders);
    }

    /* Reused from Quote and Buy and Method overrided */
    static saveAndQuoteSubmission(data, additionalHeaders = {}) {
        return processSubmission('save', data, additionalHeaders);
    }

    static updateCoverages(quoteID, sessionUUID, clausesToUpdate, additionalHeaders = {}) {
        return processSubmission('updateCoverages', [quoteID, clausesToUpdate], additionalHeaders);
    }

    static quoteEndorsement(data, additionalHeaders = {}) {
        return processSubmission('quote', data, additionalHeaders);
    }

    static bind(data, additionalHeaders = {}) {
        return processSubmission('bind', data, additionalHeaders);
    }

    static withdrawEndorsement(data, additionalHeaders = {}) {
        return processSubmission('withdraw', data, additionalHeaders);
    }

    static getAvailablePolicy(data, additionalHeaders = {}) {
        return processSubmission('getAvailablePolicy', [data], additionalHeaders);
    }

    static getAvailablePolicies(data, additionalHeaders = {}) {
        return processSubmission('getAvailablePolicies', data, additionalHeaders);
    }

    static checkEffectiveDateIsValid(data, additionalHeaders = {}) {
        return processSubmission('checkEffectiveDateIsValid', data, additionalHeaders);
    }

    static isQuoted(data, additionalHeaders = {}) {
        return processSubmission('isQuoted', data, additionalHeaders);
    }

    // WMIC methods - Start

    static policyChangeCanBeStarted_WMIC(data, additionalHeaders = {}) {
        return processSubmission('policyChangeCanBeStarted_WMIC', [data], additionalHeaders);
    }

    static policyPeriodHasFutureBoundPolicyChange_WMIC(data, additionalHeaders = {}) {
        return processSubmission('policyPeriodHasFutureBoundPolicyChange_WMIC', [data], additionalHeaders);
    }

    static policyHasRenewal_WMIC(data, additionalHeaders = {}) {
        return processSubmission('policyHasRenewal_WMIC', [data], additionalHeaders);
    }

    static postAssistedPolicyChange(data, additionalHeaders = {}) {
        return processSubmission('postAssistedPolicyChange', [data], additionalHeaders);
    }

    static loadPolicyChange_WMIC(data, additionalHeaders = {}) {
        return processSubmission('loadPolicyChange_WMIC', [data], additionalHeaders);
    }

    static editQuotedPolicyChange_WMIC(data, additionalHeaders = {}) {
        return processSubmission('editQuotedPolicyChange_WMIC', [data], additionalHeaders);
    }

    static saveEndorsement_WMIC(endorsement, additionalHeaders = {}) {
        endorsement.beforeSave();
        return processSubmission('save_WMIC', [endorsement], additionalHeaders);
    }

    static deleteVehicle_WMIC(jobID, effDate, description, vehicleId, vehiclesAssignment, additionalHeaders = {}) {
        return processSubmission('deleteVehicle_WMIC', [jobID, effDate, description, vehicleId, vehiclesAssignment], additionalHeaders);
    }

    static quoteEndorsement_WMIC(endorsement, additionalHeaders = {}) {
        return processSubmission('quote_WMIC', [endorsement], additionalHeaders);
    }

    static quoteWithDaysEndorsement_WMIC(jobID, days, additionalHeaders = {}) {
        return processSubmission('quoteWithDate_WMIC', [jobID, days], additionalHeaders);
    }

    static notTaken_WMIC(jobNumber, additionalHeaders = {}) {
        return processSubmission('notTaken_WMIC', [jobNumber], additionalHeaders);
    }

    static getPolicyChangeByJobNumber_WMIC(jobNumber, additionalHeaders = {}) {
        return processSubmission('getPolicyChangeByJobNumber_WMIC', [jobNumber], additionalHeaders);
    }

    static bind_WMIC(jobNumber, paymentDetails, additionalHeaders = {}) {
        return processSubmission('bind_WMIC', [jobNumber, paymentDetails], additionalHeaders);
    }

    static bindWithRenewal_WMIC(jobNumber, paymentDetails, applyToRenewal, notes, additionalHeaders = {}) {
        return processSubmission('bindWithRenewal_WMIC', [jobNumber, paymentDetails, applyToRenewal, notes], additionalHeaders);
    }

    static getBoundPolicySummary_WMIC(jobNumber, termNumber, additionalHeaders = {}) {
        return processSubmission('getBoundPolicySummary_WMIC', [jobNumber, termNumber], additionalHeaders);
    }
}