import React, { useCallback } from 'react';
import { MetadataContent } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import { useHistory } from 'react-router-dom';
import { WMICButton } from 'wmic-components-platform-react';

import { phoneNumber } from '../utils/WMICEnrollmentConstants';
import { translateRichText } from '../utils/WMICRichTextUtil';

import metadata from './ForgotEmailPage.metadata.json5';
import messages from './ForgotEmailPage.messages';

function ForgotEmailPage(props) {

    const translator = useTranslator();
    const history = useHistory();

    const saveForm = () => {
        history.push('/');
    }

    const overrideProps = {
        pageDescription: {
            content: translateRichText(translator(messages.forgotEmailDescription, {
                phoneNumber: phoneNumber.FORMATTED,
                phoneNumberRaw: phoneNumber.RAW
            }))
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            saveForm: saveForm
        },
        resolveComponentMap: {
            WMICButton
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

export default ForgotEmailPage;