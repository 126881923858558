import { defineMessages } from "react-intl";

export default defineMessages({
    reviewAndConfirmHeader: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.Review and Confirm Changes",
        defaultMessage: "Review and Confirm Changes",
    },
    reviewAndConfirmHeaderAria: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.Review and Confirm Changes Aria",
        defaultMessage: "Review and Confirm Changes. Here is a summary of the policy edits you’ve selected and resulting change in your premium. You can confirm and apply these changes below.",
    },
    hereIsASummaryOfThePolicyEditsYouVeSelected: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.Here is a summary of the policy edits you’ve selected and resulting change in your premium. You can confirm and apply these changes below.",
        defaultMessage:
            "Here is a summary of the policy edits you’ve selected and resulting change in your premium. You can confirm and apply these changes below.",
    },
    editedVehicleCoverages: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.Edited Vehicle Coverages",
        defaultMessage: "Edited Vehicle Coverages",
    },
    reviewYourVehicleEdits: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.Review Your Vehicle Edits",
        defaultMessage: "Review Your Vehicle Edits",
    },
    policyChangeSubmitTrackButtonIdentifierGeneric: {
        id: "wmic.policy-change.component.review-policy-change-pa.tracking.Policy Change - Submit",
        defaultMessage: "Policy Change - Submit",
    },
    policyChangeSubmitTrackButtonIdentifierSpecific: {
        id: "wmic.policy-change.component.review-policy-change-pa.tracking.Policy Change - Submit - (policyChangeType)",
        defaultMessage: "Policy Change - Submit - {policyChangeType}",
    },
    removeVehicle: {
        id: "wmic.policy-change.component.review-policy-change-pa.policy-change-type.tracking.Remove Vehicle",
        defaultMessage: "Remove Vehicle",
    },
    adjustCoveragesOrDeductibles: {
        id: "wmic.policy-change.component.review-policy-change-pa.policy-change-type.tracking.Adjust Coverages or Deductibles",
        defaultMessage: "Adjust Coverages or Deductibles",
    },
    editPolicy: {
        id: "wmic.policy-change.component.review-policy-change-pa.Edit Policy",
        defaultMessage: "Edit Policy",
    },
    premiumSavingSummaryAriaMessage: {
        id: "wmic.policy-change.component.review-policy-change-pa.premiumSavingSummaryAriaMessage",
        defaultMessage: "Old Premium is {oldPremium} dollars, New premium is {newPremium} dollars. You have {savingAmount} dollars savings in premium.",
    },
    premiumIncreaseSummaryAriaMessage: {
        id: "wmic.policy-change.component.review-policy-change-pa.premiumIncreaseSummaryAriaMessage",
        defaultMessage: "Old Premium is {oldPremium} dollars, New premium is {newPremium} dollars. You have {increaseAmount} dollars increase in premium.",
    },
    oldPremium: {
        id: "wmic.policy-change.component.review-policy-change-pa.Old Premium",
        defaultMessage: "Old Premium",
    },
    newPremium: {
        id: "wmic.policy-change.component.review-policy-change-pa.New Premium",
        defaultMessage: "New Premium",
    },
    savingsInPremium: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.Savings in Premium.",
        defaultMessage: "Savings in Premium",
    },
    increaseInPremium: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.Increase in Premium.",
        defaultMessage: "Increase in Premium",
    },
    theChangesYouVeSelectedWillResultInAnIncreaseToYourPremium: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.The changes you’ve selected will result in an increase to your premium.",
        defaultMessage:
            "The changes you’ve selected will result in an increase to your premium.",
    },
    theChangesYouVeSelectedWillResultInASavingsToYourPremium: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.The changes you’ve selected will result in a savings to your premium.",
        defaultMessage:
            "The changes you’ve selected will result in a savings to your premium.",
    },
    ifYouAreReadyToFinalizeTheseChangesYouMayConfirmAndApplyThemBelow: {
        id:
            "wmic.policy-change.component.review-policy-change-pa. If you are ready to finalize these changes, you may confirm and apply them below.",
        defaultMessage:
            " If you are ready to finalize these changes, you may confirm and apply them below.",
    },
    yourPolicyWillReflectTheseChangesAfterMidnightTonight: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.Your policy will reflect these changes after midnight tonight.",
        defaultMessage:
            "Your policy will reflect these changes after midnight tonight.",
    },
    creditInformationWillBeProvidedAfterYourChangesHaveBeenProcessed: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.Credit information will be provided after your changes have been processed.",
        defaultMessage:
            "Credit information will be provided after your changes have been processed.",
    },
    paymentInformationWillBeProvidedAfterYourChangesHaveBeenProcessed: {
        id:
            "wmic.policy-change.component.review-policy-change-pa.Payment information will be provided after your changes have been processed.",
        defaultMessage:
            "Payment information will be provided after your changes have been processed.",
    },
    cancelEdit: {
        id: "wmic.policy-change.component.review-policy-change-pa.Cancel Edit",
        defaultMessage: "Cancel Edit",
    },
    continue: {
        id: "wmic.policy-change.component.review-policy-change-pa.Continue",
        defaultMessage: "Continue",
    },
    quoteNumber: {
        id: "wmic.policy-change.component.review-policy-change-pa.Quote #",
        defaultMessage: "Quote #",
    },
    changeCouldNotBeProcessed: {
        id: "wmic.policy-change.component.bound-policy-change-pa.Your change request could not be processed and requires additional review",
        defaultMessage:
            "Your change request could not be processed and requires additional review.",
    },
    transactionNumber: {
        id: "wmic.policy-change.component.bound-policy-change-pa.Transaction Number: (transactionNumber)",
        defaultMessage: "Transaction Number: {transactionNumber}",
    },
    expires: {
        id: "wmic.policy-change.component.bound-policy-change-pa.Expires: (expiryDate)",
        defaultMessage: "Expires: {expiryDate}",
    },
    callToSpeakWithAgent: {
        id: "wmic.policy-change.component.bound-policy-change-pa.Please call (phoneNumber) to speak to one of our Customer Service Agents who will be pleased to process your change request",
        defaultMessage: 'Please call us for assistance to make changes to this policy at &lt;a href="tel:1-800-640-2920"&gt;1-800-640-2920&lt;/a&gt;. Our customer service team is available Monday – Friday 7:30 AM – 7:30 PM and Saturday 8:00 AM – 4:30 PM PT.'
    },
});
