/* eslint-disable indent */
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { useTranslator } from '@jutro/locale';
import { DateField } from '@jutro/components';
import styles from '../WMICRemoveVehicleWizard.module.scss';
import messages from '../WMICRemoveVehicleWizard.messages';
import { getVehicleName } from '../WMICRemoveVehicleWizardUtils';
import { MAX_DAYS_IN_PAST, STATE_KEYS, PERMITTED_FUTURE_POLICY_CHANGES } from '../WMICRemoveVehicleWizardConsts';

export const checkEffectiveDate = (modelState, policy, policyTransactions) => {
    const isEffectiveDateValidCheck = !_.isEmpty(modelState[STATE_KEYS.EFFECTIVE_DATE]);
    if (isEffectiveDateValidCheck) {
        const effectiveDate = moment(modelState[STATE_KEYS.EFFECTIVE_DATE]).startOf('day');
        let diffDays = 0;
        let isInPolicyRange = true;
        diffDays = moment().startOf('day').diff(effectiveDate, 'days');
        isInPolicyRange = effectiveDate.isBetween(
            moment(policy.currentPeriod.effective).startOf('day'),
            moment(policy.currentPeriod.expiration).endOf('day'),
            undefined,
            '[)'
        );

        const _policyTransactions = policyTransactions.policyTransactions.filter((pt) => !PERMITTED_FUTURE_POLICY_CHANGES.includes(pt.type));
        const hasFutureTransaction = _.some(_policyTransactions, (pt) =>
            moment(pt.completionDate).startOf('day').isAfter(effectiveDate) || moment(pt.completionDate).startOf('day').isSame(effectiveDate));

        const checkResult = ({
            isDiffInRange: diffDays < MAX_DAYS_IN_PAST,
            diffDays: diffDays,
            isInPolicyRange: isInPolicyRange,
            hasFutureTransaction: hasFutureTransaction
        });

        checkResult.summary = (
            !checkResult.isDiffInRange || checkResult.diffDays < 0
            || !checkResult.isInPolicyRange || hasFutureTransaction);

        return checkResult;
    }

    return false;
};

const WMICRemoveVehicleEffectiveDateStep = (props) => {
    const { modelState, modelStateDispatch, focusRemoveVehicleWizardTitle, ...otherProps } = props;
    const translator = useTranslator();
    const [firstLoad, setFirstLoad] = useState(true); //used so react redraw due to value change would not trigger reset of focus

    useEffect(() => {   
        if (firstLoad) {
            setFirstLoad(false);
            focusRemoveVehicleWizardTitle();
        }
    });

    return (
        <div {...otherProps}>
            <p className="mb-5">
                <div className={styles['wmic-field-label-fit-to-content']} >
                    {WMICRichTextUtil.translateRichText(translator(messages.effectiveDateMessage, { vehicleName: getVehicleName(modelState[STATE_KEYS.VEHICLE_TO_REMOVE]) }))}
                </div>
            </p>
            <div className="ww-edit-field-container">
                <DateField
                    id={`poc_remove_vehicle_date${modelState[STATE_KEYS.JOB_ID]}`}
                    label={translator(messages.effectiveDateLabel)}
                    className={styles['wmic-field-fit-to-content']}
                    labelClassName={styles['wmic-field-label-fit-to-content']}
                    maxDate="2100-01-01T00:00:00.000Z"
                    minDate="1900-01-01T00:00:00.000Z"
                    onValueChange={(v) => modelStateDispatch({ type: STATE_KEYS.EFFECTIVE_DATE, value: v })}
                    value={modelState[STATE_KEYS.EFFECTIVE_DATE]}
                />
            </div>
        </div>
    );
}

WMICRemoveVehicleEffectiveDateStep.propTypes = {
    modelState: PropTypes.shape({}).isRequired,
    modelStateDispatch: PropTypes.func.isRequired,
    focusRemoveVehicleWizardTitle: PropTypes.func
};

export default WMICRemoveVehicleEffectiveDateStep;
