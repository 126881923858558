/* eslint-disable max-len */
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { CONSTANTS } from 'wmic-portals-utils-js';
import {
    WMICControllerUtil,
    WMICPolicyChangeUtilsService,
    BodilyInjuryModel,
    WMICPolicyChangeStateService
} from 'wmic-capability-policychange';
import { useModal } from '@jutro/components';
import { publish, JUTRO_TOPICS } from "@jutro/events";
import { WMICModal } from 'gw-capability-policy-react';
import { WMICClickableIconTooltipComponent } from 'gw-capability-policy-common-react';
import { WMICButton, WMICDropdownSelect} from 'wmic-components-platform-react';

import metadata from './WMICBodilyInjuryComponent.metadata.json5';
import messages from './WMICBodilyInjuryComponent.messages';
import styles from './WMICBodilyInjuryComponent.module.scss';

function WMICBodilyInjuryComponent(props) {
    const modalApi = useModal();
    const {
        policyVM,
        match,
        history,
        loadingState,
        setLoadingState
    } = props;
    const translator = useTranslator();
    const [hasChanged, updateHasChanged] = useState(false);
    const [biLiabilityOption, updateBILiabilityOption] = useState('');
    const [pdLiabilityOption, updatePDLiabilityOption] = useState('');
    const [umLiabilityOption, updateUMLiabilityOption] = useState('');
    const [piLiabilityOption, updatePILiabilityOption] = useState('');
    const [initialCoverages, updateInitialCoverages] = useState({});
    const [uninsuredBIError, updateUninsuredBIError] = useState(false);

    const { authHeader, userInfo: authUserData } = useAuthentication();

    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');
    const policyType = _.get(match, 'params.policyType');
    const accountHolder = _.get(policyVM, 'currentPeriod.contacts')
        .filter((contact) => { return contact.contact.accountHolder === true; })[0];


    // To resolve issues where screen readers don't announce content on page load for SPAs
    // Reference: https://www.gatsbyjs.com/blog/2019-07-11-user-testing-accessible-client-routing/
    useEffect(() => {
        const headerTag = document.getElementById('bodilyInjuryHeader');
        if (headerTag && !loadingState) {
            headerTag.focus({preventScroll: true});
        }
    }, [loadingState])

    const getInitialCoverages = (polData) => {
        const vehicles = _.get(polData, 'currentPeriod.lobs.personalAuto.vehicleDTOs');
        const firstVehicle = vehicles.reduce((current, veh) => {
            return current.vehicleNumber < veh.vehicleNumber ? current : veh;
        }, vehicles[0]);

        return firstVehicle.coverages;
    };

    const isCalifornia = useCallback(() => {
        return _.get(policyVM, 'currentPeriod.policyAddress.country') === CONSTANTS.COUNTRY.US
            && _.get(policyVM, 'currentPeriod.policyAddress.state') === CONSTANTS.STATE.CA;
    }, [policyVM]);

    const hadPersonalInjuryDeductible = () => {
        return initialCoverages.personalInjuryDeductible !== 'No Coverage';
    };

    const getBIAvailableValues = () => {
        const biAvailableValues = [
            {
                id: '$25,000 / $50,000',
                displayName: '$25,000 / $50,000'
            },
            {
                id: '$50,000 / $100,000',
                displayName: '$50,000 / $100,000'
            },
            {
                id: '$100,000 / $300,000',
                displayName: '$100,000 / $300,000'
            },
            {
                id: '$250,000 / $500,000',
                displayName: '$250,000 / $500,000'
            }
        ];

        if (isCalifornia()) {
            biAvailableValues.unshift({
                id: '$15,000 / $30,000',
                displayName: '$15,000 / $30,000'
            });
        }
        return biAvailableValues;
    };

    const getPDAvailableValues = () => {
        const pdAvailableValues = [
            {
                id: '$25,000',
                displayName: '$25,000'
            },
            {
                id: '$50,000',
                displayName: '$50,000'
            },
            {
                id: '$100,000',
                displayName: '$100,000'
            }
        ];

        if (isCalifornia()) {
            [
                {
                    id: '$10,000',
                    displayName: '$10,000'
                },
                {
                    id: '$5,000',
                    displayName: '$5,000'
                },
            ].map((item) => pdAvailableValues.unshift(item));
        } else {
            pdAvailableValues.unshift({
                id: '$20,000',
                displayName: '$20,000'
            });
            [
                {
                    id: '$250,000',
                    displayName: '$250,000'
                },
                {
                    id: '$500,000',
                    displayName: '$500,000'
                }
            ].map((item) => pdAvailableValues.push(item));
        }
        return pdAvailableValues;
    };

    const umCAAvailableValues = [
        {
            id: '$15,000 / $30,000',
            displayName: '$15,000 / $30,000'
        },
        {
            id: '$25,000 / $50,000',
            displayName: '$25,000 / $50,000'
        },
        {
            id: '$30,000 / $60,000',
            displayName: '$30,000 / $60,000'
        },
        {
            id: '$50,000 / $100,000',
            displayName: '$50,000 / $100,000'
        },
        {
            id: '$100,000 / $300,000',
            displayName: '$100,000 / $300,000'
        }
    ];

    const umAvailableValues = [
        {
            id: '$25,000 / $50,000',
            displayName: '$25,000 / $50,000'
        },
        {
            id: '$50,000 / $100,000',
            displayName: '$50,000 / $100,000'
        },
        {
            id: '$100,000 / $300,000',
            displayName: '$100,000 / $300,000'
        },
        {
            id: '$250,000 / $500,000',
            displayName: '$250,000 / $500,000'
        }
    ];

    const piAvailableValues = [
        {
            id: 'No Coverage',
            displayName: 'No Coverage'
        },
        {
            id: '$1,000',
            displayName: '$1,000'
        },
        {
            id: '$2,000',
            displayName: '$2,000'
        },
        {
            id: '$5,000',
            displayName: '$5,000'
        }
    ];

    const fixDeductibleValue = (value) => {
        if (!value) {
            return 'No Coverage';
        }
        const { limit } = value;
        if (limit.indexOf('/') > 0) {
            const values = limit.split(' / ');
            return `$${values[0]} / $${values[1]}`;
        }
        return `$${limit}`;
    };

    const getBodilyInjuryTitle = () => {
        const title = translator(messages.bodiliyInjuryTitle);

        return (
            <Fragment>
                <i className={`fa fa-file-text ${styles.titleIconBlue}`} />
                <h2 className={styles['wmic-policy-title']}>{title}</h2>
            </Fragment>
        );
    };

    const getBodilyInjuryLiabilityTitle = () => {
        const title = translator(messages.bodilyInjuryLiability);
        const tooltipMessage = translator(messages.biTooltip);
        return (
            <Fragment>
                <h3 className={`${styles.covTitleContent}`}>{title}</h3>
                <WMICClickableIconTooltipComponent tooltipMessage={tooltipMessage} />
            </Fragment>
        );
    };

    const getPropertyDamageLiabilityTitle = () => {
        const title = translator(messages.propertyDamageLiability);
        const tooltipMessage = translator(messages.pdTooltip);
        return (
            <Fragment>
                <h3 className={`${styles.covTitleContent}`}>{title}</h3>
                <WMICClickableIconTooltipComponent tooltipMessage={tooltipMessage} />
            </Fragment>
        );
    };

    const getUninsuredMotoristLiabilityTitle = () => {
        const title = isCalifornia()
            ? translator(messages.uninsuredUnderInsuredMotoristLiability)
            : translator(messages.uninsuredMotoristLiability);
        const tooltipMessage = translator(messages.umTooltip);
        return (
            <Fragment>
                <h3 className={`${styles.covTitleContent}`}>{title}</h3>
                <WMICClickableIconTooltipComponent tooltipMessage={tooltipMessage} />
            </Fragment>
        );
    };

    const getPersonalInjuryLiabilityTitle = () => {
        const title = isCalifornia()
            ? translator(messages.medicalPaymentsCoverage)
            : translator(messages.personalInjuryProtection);
        const tooltipMessage = translator(messages.piTooltip);
        return (
            <Fragment>
                <h3 className={`${styles.covTitleContent}`}>{title}</h3>
                <WMICClickableIconTooltipComponent tooltipMessage={tooltipMessage} />
            </Fragment>
        );
    };

    const getPersonalInjuryLiabilityLabel = () => {
        return hadPersonalInjuryDeductible()
            ? translator(messages.currentCoverage, { currentCoverage: initialCoverages.personalInjuryDeductible })
            : translator(messages.noCoverageSelected);
    };

    const validateMBI = useCallback(() => {
        if (!isCalifornia()) {
            const max = parseFloat(umLiabilityOption.split(' / $')[1]);
            const min = parseFloat(biLiabilityOption.split(' / $')[1]);
            updateUninsuredBIError(max < min);
            return max < min;
        }
        updateUninsuredBIError(false);
        return false;
    }, [biLiabilityOption, isCalifornia, umLiabilityOption]);

    const onBILiabilityChange = useCallback((value) => {
        updateHasChanged(initialCoverages.bodilyInjuryDeductible !== value);
        validateMBI();
        updateBILiabilityOption(value);
    }, [initialCoverages.bodilyInjuryDeductible, validateMBI]);

    const onPDLiabilityChange = useCallback((value) => {
        updateHasChanged(initialCoverages.propertyDamageDeductible !== value);
        validateMBI();
        updatePDLiabilityOption(value);
    }, [initialCoverages.propertyDamageDeductible, validateMBI]);

    const onUMLiabilityChange = useCallback((value) => {
        updateHasChanged(initialCoverages.uninsuredBIDeductible !== value);
        validateMBI();
        updateUMLiabilityOption(value);
    }, [initialCoverages.uninsuredBIDeductible, validateMBI]);

    const onPILiabilityChange = useCallback((value) => {
        updateHasChanged(initialCoverages.personalInjuryDeductible !== value);
        validateMBI();
        updatePILiabilityOption(value);
    }, [initialCoverages.personalInjuryDeductible, validateMBI]);

    const fireChange = (coverages) => {
        updateHasChanged(Object.keys(coverages).some((key) => coverages[key] !== initialCoverages[key]));
    };

    useEffect(() => {
        setLoadingState(true);
        const controllerUtilProps = {
            history: history,
            policyNumber: policyNumber,
            termNumber: termNumber,
            policyType: policyType,
            accountHolder: accountHolder,
            authHeader: authHeader,
            authUserData: authUserData
        };
        WMICControllerUtil.getPolicyData(controllerUtilProps)
            .then((polData) => {
                const initialCovs = getInitialCoverages(polData);

                const coverages = {
                    bodilyInjuryDeductible: fixDeductibleValue(initialCovs.find((cov) => { return cov.patternCode_WMIC === BodilyInjuryModel.coverageInfo.bodilyInjuryDeductible; })),
                    propertyDamageDeductible: fixDeductibleValue(initialCovs.find((cov) => { return cov.patternCode_WMIC === BodilyInjuryModel.coverageInfo.propertyDamageDeductible; })),
                    uninsuredBIDeductible: fixDeductibleValue(initialCovs.find((cov) => { return cov.patternCode_WMIC === BodilyInjuryModel.coverageInfo.uninsuredBIDeductible; })),
                    personalInjuryDeductible: fixDeductibleValue(initialCovs.find((cov) => { return cov.patternCode_WMIC === BodilyInjuryModel.coverageInfo.personalInjuryDeductible; }))
                };

                updateInitialCoverages(coverages);
                updateBILiabilityOption(coverages.bodilyInjuryDeductible);
                updatePDLiabilityOption(coverages.propertyDamageDeductible);
                updateUMLiabilityOption(coverages.uninsuredBIDeductible);
                updatePILiabilityOption(coverages.personalInjuryDeductible);

                const changes = WMICPolicyChangeStateService.getChangeRequest('bodily-injury', 'change');
                if (changes) {
                    // eslint-disable-next-line no-unused-vars
                    Object.entries(coverages).forEach(([key, value]) => {
                        const change = changes.changeModel[key];
                        if (change) {
                            coverages[key] = change;
                        }
                    });
                    fireChange(coverages);
                }
            }).finally(() => {
                setLoadingState(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const parseToModel = (model) => {
        return {
            changeType: 'bodily-injury',
            objectType: 'BodilyInjuryModel',
            changeModel: model
        };
    };

    const onCancel = useCallback(() => {
        return history.push(`/account-policy-change/${policyNumber}/${termNumber}/${policyType}/select-type`);
    }, [history, policyNumber, policyType, termNumber]);

    const onSubmit = useCallback(() => {
        if (uninsuredBIError) {
            return;
        }

        const biModel = {
            bodilyInjuryDeductible: initialCoverages.bodilyInjuryDeductible !== biLiabilityOption ? biLiabilityOption : undefined,
            propertyDamageDeductible: initialCoverages.propertyDamageDeductible !== pdLiabilityOption ? pdLiabilityOption : undefined,
            uninsuredBIDeductible: initialCoverages.uninsuredBIDeductible !== umLiabilityOption ? umLiabilityOption : undefined,
            personalInjuryDeductible: initialCoverages.personalInjuryDeductible !== piLiabilityOption ? piLiabilityOption : undefined,
            country: _.get(policyVM, 'currentPeriod.policyAddress.country'),
            state: _.get(policyVM, 'currentPeriod.policyAddress.state')
        };
        const model = new BodilyInjuryModel(biModel);
        const modalBody = WMICPolicyChangeUtilsService.getModifiedBody(model, messages.confirmModalBody, translator)
        modalApi.showModal(
            <WMICModal
                id="editUninsuredSubmitModal"
                modalContainerClassname={styles.bodilyInjuryModal}
                modalHeader={translator(messages.confirmModalTitle)}
                modalBody={modalBody}
                onConfirmCallback={() => {
                    setLoadingState(true);

                    publish(JUTRO_TOPICS.BUTTON_CLICKED, { trackButtonIdentifier: translator(messages.submitButtonTracking)});

                    WMICPolicyChangeStateService.addChangeRequest('bodily-injury', 'change', parseToModel(model));
                    WMICControllerUtil.handleSubmit({
                        history: history,
                        policyNumber: policyNumber,
                        termNumber: termNumber,
                        policyData: policyVM,
                        policyType: policyType,
                        accountInfo: accountHolder,
                        authHeader: authHeader
                    },
                    translator);
                }}
                onCancelCallback={() => _.noop()}
                confirmButtonText={translator(messages.confirmationLabel)}
                cancelButtonText={translator(messages.cancel)}
            />
        );
    }, [
        accountHolder,
        authHeader,
        biLiabilityOption,
        history,
        initialCoverages,
        pdLiabilityOption,
        piLiabilityOption,
        policyNumber,
        policyType,
        policyVM,
        termNumber,
        translator,
        umLiabilityOption,
        uninsuredBIError
    ]);

    const overrideProps = {
        bodilyInjuryContainer: {
            visible: !loadingState
        },
        bodilyInjuryTitle: {
            content: getBodilyInjuryTitle()
        },
        bodilyInjurySubtitle: {
            content: translator(messages.allVehicleCoverages)
        },
        bodilyInjuryLiabilityTitle: {
            content: getBodilyInjuryLiabilityTitle()
        },
        bodilyInjuryLiabilityDropdown: {
            label: translator(messages.currentCoverage,
                { currentCoverage: initialCoverages.bodilyInjuryDeductible }),
            labelClassName: styles.dropdownLabel,
            availableValues: getBIAvailableValues(),
            onValueChange: (value) => onBILiabilityChange(value),
            value: biLiabilityOption
        },
        propertyDamageLiabilityTitle: {
            content: getPropertyDamageLiabilityTitle()
        },
        propertyDamageLiabilityDropdown: {
            label: translator(messages.currentCoverage,
                { currentCoverage: initialCoverages.propertyDamageDeductible }),
            labelClassName: styles.dropdownLabel,
            availableValues: getPDAvailableValues(),
            onValueChange: (value) => onPDLiabilityChange(value),
            value: pdLiabilityOption
        },
        uninsuredMotoristLiabilityTitle: {
            content: getUninsuredMotoristLiabilityTitle()
        },
        uninsuredMotoristLiabilityDropdown: {
            label: translator(messages.currentCoverage,
                { currentCoverage: initialCoverages.uninsuredBIDeductible }),
            labelClassName: styles.dropdownLabel,
            availableValues: isCalifornia() ? umCAAvailableValues : umAvailableValues,
            onValueChange: (value) => onUMLiabilityChange(value),
            value: umLiabilityOption
        },
        uninsuredMotoristErrorMessage: {
            content: translator(messages.uninsuredMotoristErrorMessage),
            visible: uninsuredBIError
        },
        personalInjuryLiabilityTitle: {
            content: getPersonalInjuryLiabilityTitle()
        },
        personalInjuryLiabilityDropdown: {
            label: getPersonalInjuryLiabilityLabel(),
            labelClassName: styles.dropdownLabel,
            availableValues: piAvailableValues,
            onValueChange: (value) => onPILiabilityChange(value),
            value: piLiabilityOption
        },
        cancelButton: {
            content: translator(messages.cancel),
            onClick: () => onCancel()
        },
        submitButton: {
            content: translator(messages.continue),
            disabled: !hasChanged || uninsuredBIError,
            onClick: () => onSubmit()
        }
    };

    const resolvers = {
        resolveComponentMap: {
            wmicclickableicontooltipcomponent: WMICClickableIconTooltipComponent,
            WMICButton,
            WMICDropdownSelect
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICBodilyInjuryComponent.propTypes = {
    policyVM: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default WMICBodilyInjuryComponent;
