import { setComponentMapOverrides } from '@jutro/uiconfig';
import WMICFNOLPAPage from './pages/WMICFNOLPAPage/WMICFNOLPAPage';
import WMICFNOLPAReview from './pages/WMICFNOLPAReview/WMICFNOLPAReview';
import WMICFNOLPASuccess from './pages/WMICFNOLPASuccess/WMICFNOLPASuccess';
import WMICFNOLHOPage from './pages/WMICFNOLHOPage/WMICFNOLHOPage';
import WMICFNOLHOReview from './pages/WMICFNOLHOReview/WMICFNOLHOReview';
import WMICFNOLHOSuccess from './pages/WMICFNOLHOSuccess/WMICFNOLHOSuccess';
import FNOLPAAutoGlassComponent from './components/FNOLPAAutoGlassComponent/FNOLPAAutoGlassComponent';
import FNOLDisclaimer from './components/FNOLDisclaimer/FNOLDisclaimer';
import FNOLYourInformationComponent from './components/FNOLYourInformationComponent/FNOLYourInformationComponent';
import FNOLLossInformationComponent from './components/FNOLLossInformationComponent/FNOLLossInformationComponent';
import FNOLInsuredInformationComponent from './components/FNOLInsuredInformationComponent/FNOLInsuredInformationComponent';
import FNOLInformationDetailComponent from './components/FNOLInsuredInformationComponent/components/FNOLInformationDetailComponent';
import FNOLVehicleInformationComponent from './components/FNOLVehicleInformationComponent/FNOLVehicleInformationComponent';
import FNOLPoliceReportComponent from './components/FNOLPoliceReportComponent/FNOLPoliceReportComponent';
import FNOLOtherPartiesComponent from './components/FNOLOtherPartiesComponent/FNOLOtherPartiesComponent';
import FNOLWitnessesComponent from './components/FNOLWitnessesComponent/FNOLWitnessesComponent';
import FNOLPassengersComponent from './components/FNOLPassengersComponent/FNOLPassengersComponent';
import FNOLReviewBlockComponent from './components/FNOLReviewBlockComponent/FNOLReviewBlockComponent';
import FNOLMortgageesComponent from './components/FNOLMortgageesComponent/FNOLMortgageesComponent'
import FNOLConstants from './config/FNOLConstants';
import broadcastEvent from './config/broadcastEvent';

setComponentMapOverrides(
    {
        WMICFNOLPAPage: {component: 'WMICFNOLPAPage'},
        WMICFNOLPAReview: {component: 'WMICFNOLPAReview'},
        WMICFNOLPASuccess: {component: 'WMICFNOLPASuccess'},
        WMICFNOLHOPage: {component: 'WMICFNOLHOPage'},
        WMICFNOLHOReview: {component: 'WMICFNOLHOReview'},
        WMICFNOLHOSuccess: {component: 'WMICFNOLHOSuccess'},
        FNOLPAAutoGlassComponent: {component: 'FNOLPAAutoGlassComponent'},
        FNOLYourInformationComponent: {component: 'FNOLYourInformationComponent'},
        FNOLDisclaimer: {component: 'FNOLDisclaimer'},
        FNOLLossInformationComponent: {component: 'FNOLLossInformationComponent'},
        FNOLInsuredInformationComponent: {component: 'FNOLInsuredInformationComponent'},
        FNOLInformationDetailComponent: {component: 'FNOLInformationDetailComponent'},
        FNOLVehicleInformationComponent: {component: 'FNOLVehicleInformationComponent'},
        FNOLPoliceReportComponent: {component: 'FNOLPoliceReportComponent'},
        FNOLOtherPartiesComponent: {component: 'FNOLOtherPartiesComponent'},
        FNOLWitnessesComponent: {component: 'FNOLWitnessesComponent'},
        FNOLPassengersComponent: {component: 'FNOLPassengersComponent'},
        FNOLReviewBlockComponent: {component: 'FNOLReviewBlockComponent'},
        FNOLMortgageesComponent: {component: 'FNOLMortgageesComponent'}
    },
    {
        WMICFNOLPAPage,
        WMICFNOLHOPage,
        WMICFNOLPAReview,
        WMICFNOLPASuccess,
        WMICFNOLHOReview,
        WMICFNOLHOSuccess,
        FNOLPAAutoGlassComponent,
        FNOLYourInformationComponent,
        FNOLDisclaimer,
        FNOLLossInformationComponent,
        FNOLInsuredInformationComponent,
        FNOLInformationDetailComponent,
        FNOLVehicleInformationComponent,
        FNOLPoliceReportComponent,
        FNOLOtherPartiesComponent,
        FNOLWitnessesComponent,
        FNOLPassengersComponent,
        FNOLReviewBlockComponent,
        FNOLMortgageesComponent
    }
)

export { FNOLConstants };
export { broadcastEvent };
export { default as WMICFNOLPAPage } from './pages/WMICFNOLPAPage/WMICFNOLPAPage';
export { default as WMICFNOLPAReview } from './pages/WMICFNOLPAReview/WMICFNOLPAReview';
export { default as WMICFNOLPASuccess } from './pages/WMICFNOLPASuccess/WMICFNOLPASuccess';
export { default as WMICFNOLHOPage } from './pages/WMICFNOLHOPage/WMICFNOLHOPage';
export { default as WMICFNOLHOReview } from './pages/WMICFNOLHOReview/WMICFNOLHOReview';
export { default as WMICFNOLHOSuccess } from './pages/WMICFNOLHOSuccess/WMICFNOLHOSuccess';
export { default as FNOLPAAutoGlassComponent } from './components/FNOLPAAutoGlassComponent/FNOLPAAutoGlassComponent';
export { default as FNOLYourInformationComponent } from './components/FNOLYourInformationComponent/FNOLYourInformationComponent';
export { default as FNOLDisclaimer } from './components/FNOLDisclaimer/FNOLDisclaimer';
export { default as FNOLLossInformationComponent } from './components/FNOLLossInformationComponent/FNOLLossInformationComponent';
export { default as FNOLInsuredInformationComponent } from './components/FNOLInsuredInformationComponent/FNOLInsuredInformationComponent';
export { default as FNOLInformationDetailComponent } from './components/FNOLInsuredInformationComponent/components/FNOLInformationDetailComponent';
export { default as FNOLVehicleInformationComponent } from './components/FNOLVehicleInformationComponent/FNOLVehicleInformationComponent';
export { default as FNOLPoliceReportComponent } from './components/FNOLPoliceReportComponent/FNOLPoliceReportComponent';
export { default as FNOLOtherPartiesComponent } from './components/FNOLOtherPartiesComponent/FNOLOtherPartiesComponent';
export { default as FNOLWitnessesComponent } from './components/FNOLWitnessesComponent/FNOLWitnessesComponent';
export { default as FNOLPassengersComponent } from './components/FNOLPassengersComponent/FNOLPassengersComponent';
export { default as FNOLReviewBlockComponent } from './components/FNOLReviewBlockComponent/FNOLReviewBlockComponent';
export { default as FNOLMortgageesComponent } from './components/FNOLMortgageesComponent/FNOLMortgageesComponent';
