/* eslint-disable indent */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import { DropdownSelectField } from '@jutro/components';
import { Flex, FlexItem } from "@jutro/layout";
import { WMICClickableIconTooltipComponent } from 'gw-capability-policy-common-react';
import classNames from 'classnames';
import styles from '../WMICRemoveVehicleWizard.module.scss';
import messages from '../WMICRemoveVehicleWizard.messages';
import { getVehicleName, getOccasionalDrivers } from '../WMICRemoveVehicleWizardUtils';
import { STATE_KEYS } from '../WMICRemoveVehicleWizardConsts';

export const checkOccasionalPrimaryDrivers = (modelState) => {
    return modelState[STATE_KEYS.OCCASIONAL_DRIVERS].length === 0 || _.some(modelState[STATE_KEYS.OCCASIONAL_DRIVERS], (d) => d.vehicleId === '' || !d.vehicleId);
};

const WMICRemoveVehicleAssignOccasionalDriversStep = (props) => {
    const { modelState, modelStateDispatch, focusRemoveVehicleWizardTitle, ...otherProps } = props;
    const translator = useContext(TranslatorContext);
    const vehicles = _.map(modelState[STATE_KEYS.REMAINING_VEHICLES], (v) => ({ code: v.fixedId, name: getVehicleName(v) }) );
    const occasionalDrivers = getOccasionalDrivers(modelState);
    const [firstLoad, setFirstLoad] = useState(true); //used so react redraw due to value change would not trigger reset of focus

    useEffect(() => {   
        if (firstLoad) {
            setFirstLoad(false);
            focusRemoveVehicleWizardTitle();
        }
    });

    return (
        <div {...otherProps}>
            <p className={classNames('mb-6', styles['wmic-edit-title'])}>
                <div className={styles['assign-drivers-title']}>
                    {WMICRichTextUtil.translateRichText(translator(messages.assignOccasionalDriversMessage))}
                    <span className={styles.wmicTooltipContainer}>
                        <WMICClickableIconTooltipComponent
                            tooltipMessage={translator(messages.assignOccasionalDriversToTheVehicleToolTip)}
                            placement="top"
                        />
                    </span>
                </div>
                <div className={styles['assign-drivers-title']}>
                    {WMICRichTextUtil.translateRichText(translator(messages.assignDriversCallMessage))}
                </div>
            </p>

            <Flex gap='none' className={styles.wmicFlexContainer} role='list'>
                {
                    _.map(occasionalDrivers, (driver, idx) => (
                        <div className={styles.wmicFlexColumn} role='listitem'>
                            <FlexItem grow={1} className={styles.wmicBox}>
                                <div className={styles.wmicBoxContent} key={idx}>
                                    <h3 htmlFor={`driver${idx}`}>
                                        <span className={styles.wmicStrong}>{driver.displayName}</span>
                                    </h3>
                                    <DropdownSelectField
                                        id={`driver${idx}`}
                                        className={classNames('field py-4')}
                                        availableValues={vehicles}
                                        value={driver.vehicleId}
                                        label={driver.displayName}
                                        labelClassName='is-hidden'
                                        onValueChange={(v) => {
                                            modelStateDispatch({ type: `${STATE_KEYS.OCCASIONAL_DRIVERS}.${idx}`, value: {...driver, vehicleId: v} });
                                        }}
                                    />
                                </div>
                            </FlexItem>
                        </div>
                        )
                    )
                }
            </Flex>
        </div>
    );
}

WMICRemoveVehicleAssignOccasionalDriversStep.propTypes = {
    modelState: PropTypes.shape({}).isRequired,
    modelStateDispatch: PropTypes.func.isRequired,
    focusRemoveVehicleWizardTitle: PropTypes.func
};

export default WMICRemoveVehicleAssignOccasionalDriversStep;
