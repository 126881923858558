import React, { useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { MetadataContent } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';

import { FNOLConstants, WMICFNOLHOReview } from 'wmic-capability-fnol-react';
import { WMICFnolStateService } from 'wmic-capability-fnol';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { WMICButton } from 'wmic-components-platform-react';

import metadata from './WMICFNOLHOSuccess.metadata.json5';
import messages from './WMICFNOLHOSuccess.messages.js';
import styles from './WMICFNOLHOSuccess.module.scss'

function WMICFNOLHOSuccess(props) {
    const { match } = props;
    const DESIGNATED_TEMP_CLAIM_NUMBER = "999";
    const stateParam = _.get(match, 'params.state');
    const trackingNumberParam = _.get(match, 'params.trackingNumber');
    const isTempClaim = trackingNumberParam === DESIGNATED_TEMP_CLAIM_NUMBER;
    const translator = useTranslator();
    const history = useHistory();
    const claimType = FNOLConstants.CLAIM_TYPE.RESIDENTIAL;


    const state = WMICFnolStateService.getState();

    useEffect(() => {
        if (history.location.state === undefined || !history.location.state.success) {
            history.push(`/${stateParam}/${claimType}-claim`);
            WMICFnolStateService.clearState();
            return;
        }
        const locationState = { ...history.location.state };
        delete locationState.success;
        history.replace(locationState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!state) {
            history.push({pathname: `/${stateParam}/residential-claim`});        }
    }, [stateParam, history, state])

    const onPrintClaim = () => window.print();
    
    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICButton,
            WMICFNOLHOReview
        },
        resolveCallbackMap: {
            onPrintClaim
        }
    };

    const overrideProps = {
        FNOLHOSuccessTrackingNumberText: {
            content: isTempClaim ? '' : trackingNumberParam,
        },
        FNOLHOSuccessRecordTrackingNumberText: {
            content: isTempClaim ? WMICRichTextUtil.translateRichText(translator(''))
                : WMICRichTextUtil.translateRichText(translator(messages.FNOLHOSuccessRecordTrackingNumberText)),
        },
        FNOLHOSuccessAvailablityText: {
            content: WMICRichTextUtil.translateRichText(translator(messages.FNOLHOSuccessAvailablityText)),
        },
        FNOLHOSuccessContactText: {
            content: WMICRichTextUtil.translateRichText(translator(messages.FNOLHOSuccessContactText)),
        }
    }

    return (
        <div>
            <MetadataContent
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                {...resolvers} />
        </div>
    );
}

export default withRouter(WMICFNOLHOSuccess)

