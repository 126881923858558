
import React, { useState } from 'react';
import { MetadataForm } from '@jutro/uiconfig';
import _ from 'lodash';
import metadata from './FNOLPoliceReportComponent.metadata.json5';
import styles from '../FNOLStyle.module.scss'

function FNOLPoliceReportComponent(props){
    const { WMICFnolSharedDataService, validateFields} = props;

    const [policeReport, setPoliceReport] = useState(WMICFnolSharedDataService.policeInformation)
    const [policeReportSelected, setPoliceReportSelected] = useState(WMICFnolSharedDataService.policeReportSelected.value)

    const handleToggleChange = (value) => {
        setPoliceReportSelected(value);
        WMICFnolSharedDataService.policeReportSelected.value = value;
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleToggleChange,
        }
    }

    const overrideProps = {
        FNOLPoliceReportToggle:{
            value: policeReportSelected
        },
        FNOLPoliceReportSelectedContainer: {
            visible: policeReportSelected === true
        },
        FNOLPoliceReportToggleError: {
            visible: validateFields('policeReportSelected.value', 'toggle')
        }
    }

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            data={policeReport}
            onDataChange={(value, path) => {
                const newData = {...policeReport};
                _.set(newData, path, value)
                WMICFnolSharedDataService.policeInformation = newData
                setPoliceReport(newData)
            }} />
    );
}

export default FNOLPoliceReportComponent;
