import React, { Fragment } from 'react';
import { MetadataContent } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import CONSTANTS from 'wmic-portals-utils-js/StringConstants';


import metadata from './FNOLDisclaimer.metadata.json5';
import messages from './FNOLDisclaimer.messages.js'

function FNOLDisclaimer(props) {
    const translator = useTranslator();

    const getDisclaimerText = (showCaliforniaLawDisclaimer) => {
        return (
            <Fragment>
                <p>
                    {translator(messages.FNOLDisclaimerText)}
                    <a 
                        target="_blank"
                        rel="noreferrer"
                        href={`https://www.wawanesa.com/us/legal/privacy-policy.html?language_id=1&state=${props.state}`}
                    >
                        {translator(messages.FNOLDisclaimerLink)}
                    </a>
                    .
                </p>
                {showCaliforniaLawDisclaimer && (
                    <p>{WMICRichTextUtil.translateRichText(translator(messages.FNOLDisclaimerCalifornia))}</p>
                )}
            </Fragment>
        );
    }
    
    const overrideProps = {
        FNOLDisclaimerContainer: {
            content: getDisclaimerText(props.showCaliforniaLawDisclaimer)
        }
    }
    const resolvers = {}

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

export default FNOLDisclaimer