import { defineMessages } from "react-intl";

export default defineMessages({
    yourPolicyHasBeenUpdated: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.YOUR POLICY HAS BEEN UPDATED",
        defaultMessage: "YOUR POLICY HAS BEEN UPDATED",
    },
    yourPolicyChangeHasBeenBound: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.Your policy change has been bound",
        defaultMessage: "Your policy change has been bound.",
    },
    creditOwedAndRemainingPayments: {
        // hasAmountCredit && hasRemainingPayments
        id:
            "wmic.policy-change.component.bound-policy-change-pa.creditOwedAndRemainingPayments",
        defaultMessage:
            "The changes you’ve selected result in a {amount} credit to your premium. This credit will be applied to your future payment(s).",
    },
    creditOwedAndCheckOutIn14Days: {
        // hasAmountCredit && !hasRemainingPayments
        id:
            "wmic.policy-change.component.bound-policy-change-pa.creditOwedAndCheckOutIn14Days",
        defaultMessage:
            "The changes you’ve selected result in a {amount} credit to your premium. This credit will be processed and a check will be automatically mailed to you within 14 business days.",
    },
    amountOwedAndRemainingPayments: {
        // hasAmountOwing && hasRemainingPayments
        id:
            "wmic.policy-change.component.bound-policy-change-pa.amountOwedAndRemainingPayments",
        defaultMessage:
            "The changes you’ve selected result in a {amount} increase to your premium. This increase will be applied to your future payment(s).",
    },
    amountOwedAndNoRemainingPayments: {
        // hasAmountOwing && !hasRemainingPayments
        id:
            "wmic.policy-change.component.bound-policy-change-pa.amountOwedAndNoRemainingPayments",
        defaultMessage:
            "These changes result in a {amount} increase to your premium. You can pay this amount now or wait to receive your invoice.",
    },
    amountNoChange: {
        // hasAmountOwing && hasRemainingPayments
        id:
            "wmic.policy-change.component.bound-policy-change-pa.amountNoChange",
        defaultMessage:
            "The changes you’ve selected result in no change to your premium.",
    },
    contactUs: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.If you have any questions, please contact us at 1-800-640-2920",
        defaultMessage:
            "If you have any questions, please contact us at 1-800-640-2920.",
    },
    yourPolicyWillReflect: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.Your policy will reflect these changes after midnight.",
        defaultMessage:
            "Your policy will reflect these changes after midnight.",
    },
    pleaseNoteItMayTakeAnAdditional: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.Please Note it may take an additional two business days for the changes to be reflected on your upcoming renewal.",
        defaultMessage:
            "&lt;strong&gt;Please Note:&lt;/strong&gt; It may take an additional two business days for the changes to be reflected on your upcoming renewal.",
    },
    pleaseWait: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.Please wait...",
        defaultMessage: "Please wait...",
    },
    wellProvideYourNewPayment: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.We'll provide your new payment schedule shortly",
        defaultMessage: "We'll provide your new payment schedule shortly",
    },
    pleaseWaitAndDontCloseYourBrowserOrLeaveThisPage: {
        id:
            "wmic.policy-change.component.bound-policy-change-pa.Please wait and don't close your browser or leave this page",
        defaultMessage:
            "Please wait and don't close your browser or leave this page.",
    },
    payNow: {
        id: "wmic.policy-change.component.bound-policy-change-pa.Pay now",
        defaultMessage: "Pay now",
    },
});
