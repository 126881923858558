import React, { useState, useCallback, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import WMICEnrollmentErrorCodes from '../utils/WMICEnrollmentErrorCodes';
import { MetadataContent } from '@jutro/uiconfig';

import CheckEmailRegistrationDialog from '../dialogs/CheckEmailRegistrationDialog/CheckEmailRegistrationDialog';

import metadata from './MigrationNoticePage.metadata.json5';
import styles from './MigrationNoticePage.module.scss';
import messages from './MigrationNoticePage.messages';

function MigrationNoticePage(props) {
    const [isLoading, setIsLoading] = useState(false);

    const [emailAddress, setEmailAddress] = useState();
    const overrideProps = {
        confirmationDialog: {
            emailAddress
        },
    };

    const resolvers = {
        resolveComponentMap: {
            confirmationDialog: CheckEmailRegistrationDialog,
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

export default withRouter(MigrationNoticePage);