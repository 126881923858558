import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { MetadataForm } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import cx from 'classnames';

import CONSTANTS from 'wmic-portals-utils-js/StringConstants';
import { FNOLConstants } from 'wmic-capability-fnol-react';

import metadata from './FNOLMortgageesComponent.metadata.json5';
import styles from '../FNOLStyle.module.scss';
import messages from './FNOLMortgageesComponent.messages.js';


function FNOLMortgageesComponent(props){
    const { WMICFnolSharedDataService, state, WMICFnolUtilsService, validateFields } = props;
    const [mortgagees, setMortgagees] = useState(WMICFnolSharedDataService.mortgagees);
    const [toggleValue, setToggleValue] = useState(WMICFnolSharedDataService.mortgageesSelected.value);
    const [count, setCount] = useState(1);
    const translator = useTranslator();

    useEffect(() => {
        handleUpdateList()
    }, [count, handleUpdateList])

    const handleUpdateList = useCallback(() => {
        const listLength = mortgagees.list.length;
        let updatedList = [...mortgagees.list];

        if (count !== listLength) {
            if (count > listLength) {
                updatedList.push(...Array.from({length: count - listLength}, () => WMICFnolUtilsService.otherParty(state)))
            } else  {
                mortgagees.list.splice(count);
                updatedList = mortgagees.list;
            }
        }
        
        setMortgagees({
            ...mortgagees,
            list: updatedList
        })
        WMICFnolSharedDataService.mortgagees.list = updatedList;
    }, [WMICFnolUtilsService.mortgagees, WMICFnolSharedDataService.mortgagees, mortgagees, count])

    const onDropdownChange = useCallback((item) => {
        setCount(item);
    }, [setCount]);

    const onToggleChange = (value) => {
        setToggleValue(value)
        WMICFnolSharedDataService.mortgageesSelected.value = value;
    };

    const setZipLabel = useCallback((country) => {
        return country === CONSTANTS.COUNTRY.US ? translator(messages.FNOLMortgageesFormZipCode)  : translator(messages.FNOLMortgageesFormPostalCode) 
    }, [translator])

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onToggleChange,
        },
    }

    const renderTabs = () => {
        return _.map(mortgagees.list, (item, index) => {
            const itemId = index + 1;
            return {
                id: `tab-${itemId}`,
                type: 'element',
                component: 'tab',
                componentProps: {
                    id: `mortgagees-tab-${itemId}`,
                    heading: itemId,
                    showFrame: true,
                    className: 'fnolTab'
                },
                content: [
                    {
                        id: `FNOLMortgageesTabDescription_tab${itemId}`,
                        type: 'element',
                        component: 'h4',
                        content: translator(messages.FNOLMortgageesDescription, {num: itemId})
                    },
                    {
                        id: "FNOLMortgageesNameContainer",
                        type:"container",
                        component:"div",
                        componentProps: {
                            className:"ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLMortgageesName_tab${itemId}`,
                                type:"field",
                                component:"input",
                                componentProps: {
                                    value: item.name,
                                    labelClassName:"input-required",
                                    path:`list[${index}].name`,
                                    label: {
                                        id: "wmic.fnol-page.mortgagees.name-label",
                                        defaultMessage: "Name"
                                    },
                                    maxLength: 50
                                }
                            },
                            {
                                id: `FNOLMortgageesNameError_tab${itemId}`,
                                type:"element",
                                component:"span",
                                componentProps: {
                                    className:"error",
                                    visible: validateFields('mortgagees.list', 'tabset', false, 'name')
                                },
                                content:{
                                    id: "wmic.fnol-page.pa.field-required",
                                    defaultMessage: "Field required"
                                }
                            },
                        ]
                    },
                    {
                        id: `FNOLMortgageesAddress_tab${itemId}`,
                        type:"field",
                        component:"input",
                        componentProps: {
                            value: item.address,
                            className:"entry-item ww-input--75",
                            path:`list[${index}].address.street`,
                            label: {
                                id: "wmic.fnol-page.mortgagees.address-label",
                                defaultMessage: "Address"
                            },
                            maxLength: 50
                        }
                    },
                    {
                        id: `FNOLMortgageesCity_tab${itemId}`,
                        type:"field",
                        component: "input",
                        componentProps: {
                            value: item.city,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].address.city`,
                            label: {
                                id: "wmic.fnol-page.mortgagees.city-label",
                                defaultMessage: "City"
                            },
                            maxLength: 50
                        }
                    },
                    {
                        id: `FNOLMortgageesCountryContainer_tab${itemId}`,
                        type: "container",
                        component:"div",
                        componentProps: {
                            className:"ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLMortgageesCountry_tab${itemId}`,
                                type: "field",
                                component: "dropdownselect",
                                componentProps: {
                                    availableValues: WMICFnolUtilsService.getSelectCanadaUSA(),
                                    path: `list[${index}].address.country`,
                                    className: "wmic-fnol-dropdown",
                                    label: {
                                        id: "wmic.fnol-page.mortgagees.country-label",
                                        defaultMessage: "Country"
                                    },
                                    internalClassNames: {
                                        option: "dropdownOption"
                                    }
                                }
                            }
                        ]
                    },
                    {
                        id: `FNOLMortgageesStateProvinceContainer_tab${itemId}`,
                        type: "container",
                        component:"div",
                        componentProps: {
                            className:"ww-input--75 entry-item"
                        },
                        content: [
                            {
                                id: `FNOLMortgageesStateProvince_tab${itemId}`,
                                type: "field",
                                component: "dropdownselect",
                                componentProps: {
                                    availableValues: WMICFnolUtilsService.getStatesShown(item),
                                    path: `list[${index}].address.stateProvince`,
                                    className: "wmic-fnol-dropdown",
                                    label: {
                                        id: "wmic.fnol-page.mortgagees.state-province-label",
                                        defaultMessage: "State"
                                    },
                                    internalClassNames: {
                                        option: "dropdownOption"
                                    }
                                }
                            }
                        ]
                    },
                    // REVISAR
                    {
                        id: `FNOLMortgageesZipCode_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "99999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.postalCode,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].address.postalCode`,
                            label: {
                                id: "wmic.fnol-page.mortgagees.zipcode-label",
                                defaultMessage: "Zip Code"
                            }   
                        }
                    },
                    {
                        id: `FNOLMortgageesHomePhone_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "(999) 999-9999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.phoneNumber,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].phoneNumber`,
                            label: {
                                id: "wmic.fnol-page.mortgagees.home-phone-label",
                                defaultMessage: "Home Phone Number"
                            }   
                        }
                    },
                    {
                        id: `FNOLMortgageesBusinessPhone_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "(999) 999-9999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.homeBusinessNumber,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].businessPhoneNumber`,
                            label: {
                                id: "wmic.fnol-page.mortgagees.business-phone-label",
                                defaultMessage: "Business Phone Number"
                            }   
                        }
                    },
                    {
                        id: `FNOLMortgageesExt_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "99999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            registerValidation: () => _.noop,
                            value: item.ext,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].ext`,
                            label: {
                                id: "wmic.fnol-page.mortgagees.ext-label",
                                defaultMessage: "Ext"
                            }   
                        }
                    },
                    {
                        id: `FNOLMortgageesCellPhone_tab${itemId}`,
                        type:"field",
                        component: "inputmask",
                        componentProps: {
                            mask: "(999) 999-9999",
                            maskChar: "_",
                            alwaysShowMask: true,
                            value: item.cellPhoneNumber,
                            className: "entry-item ww-input--75",
                            path: `list[${index}].cellPhoneNumber`,
                            label: {
                                id: "wmic.fnol-page.mortgagees.cellphone-label",
                                defaultMessage: "Cell Phone Number"
                            }   
                        }
                    }
                ]
            };
        })
    }

    
    const zipOverrides = useMemo(() => {
        const overrides = _.get(mortgagees, 'list')
            .reduce((partial, mortgage, index) => ({
                ...partial,
                [`FNOLMortgageesZipCode_tab${index+1}`]: {
                    mask: FNOLConstants.setZipMask(mortgage?.address.country),
                    label: setZipLabel(mortgage?.address.country)
                }
            }), {});
        return overrides
    }, [mortgagees, setZipLabel])

    const overrideProps = {
        ...zipOverrides,
        FNOLMortgageesCount: {
            availableValues: FNOLConstants.AVAILABLE_COUNTS,
            value: count,
            onValueChange: onDropdownChange
        },
        FNOLMortgageesToggle: {
            value: toggleValue
        },
        FNOLMortgageesFormContainer: {
            visible: toggleValue === true
        },
        FNOLMortgageesNoMortgageesWarning: {
            visible: toggleValue === false
        }, 
        FNOLMortgageesTabSet: {
            activeTab: 'mortgagees-tab-1',
            content: renderTabs(),
            className: cx('entry-item', styles.fnolTabSet)
        },
        FNOLMortgageesToggleError: {
            visible: validateFields('mortgageesSelected.value', 'toggle')
        }
    }

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            data={mortgagees}
            onDataChange={(value, path) => {
                const newData = {...mortgagees};
                _.set(newData, path, value)
                WMICFnolSharedDataService.mortgagees = newData
                setMortgagees(newData);
                if (path.split('.').at(-1) === 'country') WMICFnolUtilsService.countryOptionChanged(path, value, state, mortgagees, setMortgagees, WMICFnolSharedDataService.mortgagees);
            }} />
    );
}

export default FNOLMortgageesComponent