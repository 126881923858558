import React, { Fragment, useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { MetadataForm } from '@jutro/uiconfig';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { EMQ_USAGE_TYPES } from 'wmic-portals-utils-js/StringConstants';

import WMICVehicleInfo from '../../models/WMICVehicleInfo';
import WMICEMQVehicleDistanceCalcuationComponent from '../WMICEMQVehicleDistanceCalculationComponent/WMICEMQVehicleDistanceCalculationComponent';

import metadata from './WMICEMQVehicleAccordionComponent.metadata.json5';
import styles from './WMICEMQVehicleAccordionComponent.module.scss';
import messages from './WMICEMQVehicleAccordionComponent.messages';


function WMICEMQVehicleAccordionComponent(props) {
    const translator = useTranslator();
    const { data, setData } = props;

    const [localData, updateLocalData] = useState(data);
    const getVehicleUsageOptions = () => {
        return [
            { name: EMQ_USAGE_TYPES.COMMUTING_LONG, code: EMQ_USAGE_TYPES.COMMUTING },
            { name: EMQ_USAGE_TYPES.PLEASURE, code: EMQ_USAGE_TYPES.PLEASURE },
            { name: EMQ_USAGE_TYPES.BUSINESS, code: EMQ_USAGE_TYPES.BUSINESS }
        ];
    };

    const getAnnualMileageText = () => {
        return (
            <Fragment>
                {data.CurrentPolicyVehicle.AnnualMileage}
                {translator(messages.EMQVehicleAccordionMilesYear)}
            </Fragment>
        );
    };
    const availableUsages = getVehicleUsageOptions();

    const commutingFieldsVisible = useMemo(() => {
        return data.NewEmqVehicle.PrimaryUse === EMQ_USAGE_TYPES.COMMUTING;
    }, [data.NewEmqVehicle.PrimaryUse]);

    const vehicleIsValid = useMemo(() => {
        if (data.NewEmqVehicle.PrimaryUse !== null) {
            return data.NewEmqVehicle.isValid();
        }
        return false;
    }, [data.NewEmqVehicle]);

    const getAccordionHeader = () => {
        return (
            <div className={classNames('ww-card-header', styles['ww-vehicle-border'])}>
                <div className={classNames(styles['ww-vehicle-header'])}>
                    <h4>{data.CurrentPolicyVehicle.VehicleName}</h4>
                    <span>
                        {translator(messages.EMQVehicleAccordionVIN)}
                        :&nbsp;
                        {data.CurrentPolicyVehicle.Vin}
                    </span>
                </div>
                {!vehicleIsValid && (
                    <div className={styles['indicator-not-complete']}>
                        <i className={classNames('fa fa-fw fa-exclamation')} />
                        {translator(messages.EMQVehicleAccordionNotComplete)}
                    </div>
                )}
                {vehicleIsValid && (
                    <div className={styles['indicator-complete']}>
                        <i className={classNames('fa fa-fw fa-check')} />
                        {translator(messages.EMQVehicleAccordionComplete)}
                    </div>
                )}
            </div>
        );
    };

    const updateCommutingMiles = (miles) => {
        const newVehicleData = _.cloneDeep(localData.NewEmqVehicle);
        newVehicleData.CommutingMiles = miles.toString();
        updateLocalData({
            ...localData,
            NewEmqVehicle : newVehicleData
        });
    };

    const overrideProps = {
        emqVehicleAccordionHeaderContainer: {
            title: getAccordionHeader()
        },
        emqVehicleAccordionMilesPerYear: {
            content: getAnnualMileageText()
        },
        emqVehicleAccordionPrimaryUsageDropdown: {
            availableValues: availableUsages,
        },
        emqVehicleAccordionDaysPerWeekInput: {
            visible: commutingFieldsVisible
        },
        emqVehicleAccordionHowFarInput: {
            visible: commutingFieldsVisible
        },
        emqVehicleAccordionNeedHelpColumn: {
            visible: commutingFieldsVisible
        },
        emqVehicleCalculateDistance: {
            onUpdateDistance: updateCommutingMiles
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            distanceCalculationComponent: WMICEMQVehicleDistanceCalcuationComponent
        }
    };

    useEffect(() => {
        setData(localData.NewEmqVehicle);
    }, [localData]);

    WMICEMQVehicleAccordionComponent.propTypes = {
        data: PropTypes.shape({
            CurrentPolicyVehicle: PropTypes.shape({
                AnnualMileage: PropTypes.string,
                CommutingMiles: PropTypes.string,
                DaysPerWeekCommute: PropTypes.string,
                OdometerReading: PropTypes.string,
                PrimaryUse: PropTypes.string,
                VehicleName: PropTypes.string,
                VehicleNumber: PropTypes.string,
                Vin: PropTypes.string
            }),
            NewEmqVehicle: PropTypes.shape({
                AnnualMileage: PropTypes.string,
                CommutingMiles: PropTypes.string,
                DaysPerWeekCommute: PropTypes.string,
                OdometerReading: PropTypes.string,
                PrimaryUse: PropTypes.string,
                VehicleName: PropTypes.string,
                VehicleNumber: PropTypes.string,
                Vin: PropTypes.string,
                isValid: PropTypes.func
            })
        }).isRequired,
        setData: PropTypes.func.isRequired
    };

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            overrideProps={overrideProps}
            data={localData.NewEmqVehicle}
            onDataChange={(value, path) => {
                const newFilters = { ...localData.NewEmqVehicle };
                newFilters[path] = value;
                updateLocalData({
                    CurrentPolicyVehicle : { ...localData.CurrentPolicyVehicle },
                    NewEmqVehicle: new WMICVehicleInfo(newFilters)
                });
            }} />
    );
}

export default WMICEMQVehicleAccordionComponent;
