import React, { useContext } from 'react';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import {  useHistory } from 'react-router-dom';
import { WMICButton } from 'wmic-components-platform-react';

import FurtherAssistanceComponent from '../../common/FurtherAssistance/FurtherAssistance';

import metadata from './ExpiredPasswordResetTokenDialog.metadata.json5';
import './ExpiredPasswordResetTokenDialog.messages';

function ExpiredPasswordResetTokenDialog(props) {
    const history = useHistory();

    const saveForm = () => {
        history.push('/enrollment/reset-password');
    }

    const overrideProps = {}

    const resolvers = {
        resolveCallbackMap: {
            saveForm: saveForm,
        },
        resolveComponentMap: {
            furtherassistancecomponent: FurtherAssistanceComponent,
            WMICButton
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

export default ExpiredPasswordResetTokenDialog;