/* eslint-disable max-len */
import React, {
    useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { AccountBillingDetailsService, BillingService } from 'gw-capability-billing';
import { PolicyService } from 'gw-capability-policy';
import { EndorsementService } from 'gw-capability-policychange';
import { useAuthentication } from 'wmic-digital-auth-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WMICButton } from 'wmic-components-platform-react';
import { useModal } from '@jutro/components';
import WMICPaymentPlanModal from 'gw-capability-policy-react/WMICBillingInfoBox/WMICManagePaymentPlan/WMICPaymentPlanModal';
import WMICPolicyDetailsUtil from 'gw-capability-policy-react/WMICPolicyDetailsUtil';
import { WMICPolicyChangeStateService} from 'wmic-capability-policychange';
import { WMICLoader } from 'gw-components-platform-react';
import { WMICRichTextUtil, CONSTANTS } from 'wmic-portals-utils-js';

import metadata from './WMICBoundPolicyChangePA.metadata.json5';
import messages from './WMICBoundPolicyChangePA.messages';
import styles from './WMICBoundPolicyChangePA.module.scss';

function WMICBoundPolicyChangePA(props) {
    const modalApi = useModal();
    const { policyVM, policyNumber, loadingState, setLoadingState } = props;

    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [policyData, setPolicyData] = useState();
    const [boundPolicy, setBoundPolicy] = useState();
    const [billingData, setBillingData] = useState();
    const [billingSummary, setBillingSummary] = useState();
    const [paymentsUpdated, setPaymentsUpdated] = useState(false);
    const [canShowPaymentPlan, setCanShowPaymentPlan] = useState(true);
    
    //Following specific for delete-vehicle flow which could be back dated. other policychange would take today by default.
    const deleteVehicleChangeRequest = WMICPolicyChangeStateService.getChangeRequest('deleteVehicle', 'delete-vehicle');
    const showChangeReflectMessageFlag = useMemo(() => {
        if (deleteVehicleChangeRequest) {
            const { date: pcEffectiveLocaleDate } = deleteVehicleChangeRequest?.changeModel;
            const removeVehicleEffectDate = new Date(new Date(pcEffectiveLocaleDate.year, pcEffectiveLocaleDate.month, pcEffectiveLocaleDate.day).setHours(24,0,0,0));
            return removeVehicleEffectDate >= new Date(new Date().setHours(24,0,0,0)); 
        } else {
            return true; 
        }
    }, [deleteVehicleChangeRequest]);

    const { WMICPaymentModal } = useDependencies('WMICPaymentModal');
    const history = useHistory();

    const timerId = useRef(null);
    const secondsToRepeatBillingRefreshCall = 30;
    const billingDetailsReloadInterval = 6;
    const termNumber = _.get(policyVM, 'currentPeriod.termNumber_WMIC');

    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const { authHeader, userInfo: authUserData } = useAuthentication();

    const hasAmountOwing = (boundPolicySummary) => {
        return Math.sign(boundPolicySummary?.totalCostDifference?.amount) === 1;
    };

    const hasAmountCredit = (boundPolicySummary) => {
        return Math.sign(boundPolicySummary?.totalCostDifference?.amount) === -1;
    };

    const hasRemainingPayments = (billSummary) => {
        return (billSummary
            && billSummary.unpaidInvoices && billSummary.unpaidInvoices.length > 0);
    };

    const isBillingUnpaidInvoiceUpdated = (unpaidInvoices, boundPolicySummary) => {
        return unpaidInvoices && unpaidInvoices.length > 0 && _.some(unpaidInvoices, (unpaidInvoice) => {
            return unpaidInvoice.updateTime_WMIC >= boundPolicySummary.jobCloseDate;
        });
    };

    const updateBillingData = (newData) => {
        _.set(newData, 'policyStatus', WMICPolicyDetailsUtil.getBillingInfoPolicyStatus(policyVM));
        _.set(newData, 'renewalPolicyStatus', WMICPolicyDetailsUtil.getBillingInfoPolicyStatus(policyVM));
        setBillingData(newData);
    };

    const repopulateBillingData = () => {
        BillingService.getPolicyPaymentSummaryByPolicyNumber_WMIC(policyNumber, authHeader).then((billingDataResult) => {
            updateBillingData(billingDataResult);
        }).catch(() => {});
    };

    const reloadBillingSummaryData = (boundPolicySummary) => {
        AccountBillingDetailsService.getPolicyBillingSummary_WMIC(policyNumber, termNumber, authHeader).then((result) => {
            const arePaymentsUpdated = isBillingUnpaidInvoiceUpdated(result.unpaidInvoices, boundPolicySummary);
            setPaymentsUpdated(arePaymentsUpdated);

            if (arePaymentsUpdated) {
                setBillingSummary(result);
                repopulateBillingData();
                if (hasAmountCredit(boundPolicySummary) && !hasRemainingPayments(result)) {
                    setCanShowPaymentPlan(false);
                }
                clearInterval(timerId);
                setPaymentsLoading(false);
            }
        }).catch(() => {});
    };

    useEffect(() => {
        Promise.all([
            BillingService.getPolicyPaymentSummaryByPolicyNumber_WMIC(policyNumber, authHeader),
            PolicyService.getAccountPolicyDetails_WMIC(policyNumber, termNumber, authHeader, authUserData.firstName, authUserData.lastName),
            EndorsementService.getBoundPolicySummary_WMIC(policyNumber, termNumber, authHeader)
        ]).then(([billDetails, accPolDetails, boundPolicyResult]) => {
            updateBillingData(billDetails);
            setPolicyData(accPolDetails);
            setBoundPolicy(boundPolicyResult);

            AccountBillingDetailsService.getPolicyBillingSummary_WMIC(policyNumber, termNumber, authHeader).then((result) => {
                if (hasAmountCredit(boundPolicyResult) && (!result.unpaidInvoices || result.unpaidInvoices.length === 0)) {
                    setCanShowPaymentPlan(false);
                } else {
                    timerId.current = setInterval(() => {
                        reloadBillingSummaryData(boundPolicyResult);
                    }, billingDetailsReloadInterval * 1000);

                    const arePaymentsUpdated = isBillingUnpaidInvoiceUpdated(result.unpaidInvoices, boundPolicyResult);
                    setPaymentsUpdated(arePaymentsUpdated);

                    if (arePaymentsUpdated) {
                        if (hasAmountCredit(boundPolicyResult) && !hasRemainingPayments(result)) {
                            setCanShowPaymentPlan(false);
                        }
                        setPaymentsLoading(false);
                        clearInterval(timerId.current);
                    } else {
                        setTimeout(() => {
                            clearInterval(timerId.current);
                            setPaymentsLoading(false);
                            reloadBillingSummaryData(boundPolicyResult);
                        }, (secondsToRepeatBillingRefreshCall * 1000));
                    }
                }

                setBillingSummary(result);
                setLoadingState(false);
            });
        });
    }, []);

    useEffect(() => {
        focusBoundPolicyChangeTitle();
    }, [boundPolicy]);

    const focusBoundPolicyChangeTitle = () => {
        const headerTag = document.getElementById('boundPolicyChangePATitle');
        if (headerTag) {
            headerTag.setAttribute('tabindex', '0');
            headerTag.focus({preventScroll: true});
        }
    };

    const premiumChangeMessage = useMemo(() => {
        if (boundPolicy && billingSummary) {
            const amount = intl.formatNumber(
                Math.abs(boundPolicy.totalCostDifference.amount),
                {
                    style: 'currency',
                    currency: boundPolicy.totalCostDifference.currency,
                    currencyDisplay: 'symbol'
                }
            );

            let paymentMsg = showChangeReflectMessageFlag ? WMICRichTextUtil.translateRichText(translator(messages.yourPolicyWillReflect)) + ' ' : '';

            if (hasAmountOwing(boundPolicy)) {
                paymentMsg += hasRemainingPayments(billingSummary)
                    ? WMICRichTextUtil.translateRichText(translator(messages.amountOwedAndRemainingPayments, { amount: amount }))
                    : WMICRichTextUtil.translateRichText(translator(messages.amountOwedAndNoRemainingPayments, { amount: amount }));
            } else if (hasAmountCredit(boundPolicy)) {
                paymentMsg += hasRemainingPayments(billingSummary)
                    ? WMICRichTextUtil.translateRichText(translator(messages.creditOwedAndRemainingPayments, { amount: amount }))
                    : WMICRichTextUtil.translateRichText(translator(messages.creditOwedAndCheckOutIn14Days, { amount: amount }));
            } else {
                paymentMsg += WMICRichTextUtil.translateRichText(translator(messages.amountNoChange));
            }

            return paymentMsg;
        }

        return '';
    }, [boundPolicy, billingSummary]);

    const getLoadingPaymentPlanContent = () => {
        return (
            <div>
                <h4 className={styles['wmic-loading-quote-title']}>{ translator(messages.pleaseWait) }</h4>
                <div className={styles['wmic-loader-div']}>
                    <WMICLoader isInline />
                </div>
                <p>
                    { translator(messages.wellProvideYourNewPayment) }
                    <br />
                    { translator(messages.pleaseWaitAndDontCloseYourBrowserOrLeaveThisPage) }
                </p>
            </div>
        );
    };

    const getUpcomingRenewalReflectMsgContent = () => {
        return WMICRichTextUtil.translateRichText(translator(messages.pleaseNoteItMayTakeAnAdditional));
    };

    const canShowPayNowButton = useMemo(() => {
        if (boundPolicy && billingSummary) {
            if (
                hasAmountOwing(boundPolicy) &&
                canShowPaymentPlan &&
                paymentsUpdated
            ) {
                return (
                    boundPolicy &&
                    paymentsUpdated &&
                    billingSummary &&
                    billingSummary.unpaidInvoices.length === 1 &&
                    billingSummary.unpaidInvoices[0].updateTime_WMIC >=
                        boundPolicy.jobCloseDate
                );
            }
        }
        return false;
    }, [boundPolicy, canShowPaymentPlan, paymentsUpdated, billingSummary]);

    const onClickPayNow = useCallback((e) => {
        e.preventDefault();
        const policyModalData = {
            billingData: billingData,
            policyData: policyData
        };
        return modalApi.showModal(
            <WMICPaymentModal data={policyModalData} />
        ).then(() => {
            history.push(`/account-policy-details/${policyNumber}/${termNumber}`);
        }).catch(_.noop);
    }, [billingData, policyData, history, policyNumber, termNumber]);

    const isUpcomingRenewalMsgVisible = () => {
        const isRenewalScheduled = (status) => {
            const renewalStatus = status?.toLowerCase();
            
            return renewalStatus === CONSTANTS.LOB_STATUSES.SCHEDULED;
        };

        return deleteVehicleChangeRequest != null && isRenewalScheduled(policyData?.renewedPeriod?.lobs?.personalAuto?.status);
    };

    const overrideProps = {
        boundPolicyChangeContainer: {
            visible: !loadingState
        },
        premiumChangeMessage: {
            content: premiumChangeMessage
        },
        loadingPaymentPlanContent: {
            visible: canShowPaymentPlan && !paymentsUpdated && paymentsLoading,
            content: getLoadingPaymentPlanContent()
        },
        buttonPayNow: {
            visible: canShowPayNowButton,
            type: 'danger',
            size: 'small',
            icon: 'cust-dollar-sign',
            iconClassName: 'payNowIcon',
            content: translator(messages.payNow),
            onClick: onClickPayNow
        },
        paymentPlanTable: {
            visible: canShowPaymentPlan && billingData && paymentsUpdated,
            isEditMode: false,
            billingData: billingData,
            onEdit: () => {},
            showContactUs: true
        },
        upcomingRenewalReflectMessage: {
            visible: isUpcomingRenewalMsgVisible(),
            content: getUpcomingRenewalReflectMsgContent()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            paymentPlan: WMICPaymentPlanModal,
            WMICButton
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICBoundPolicyChangePA.propTypes = {
    policyVM: PropTypes.shape({}).isRequired,
    policyNumber: PropTypes.string.isRequired,
};

export default WMICBoundPolicyChangePA;
