/* eslint-disable max-len */
import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { publish, JUTRO_TOPICS } from "@jutro/events";
import classNames from 'classnames';
import _ from 'lodash';
import { CONSTANTS } from 'wmic-portals-utils-js';
import { WMICButton } from 'wmic-components-platform-react';
import {
    WMICControllerUtil,
    WMICPolicyChangeStateService,
    RoadsideAssistanceModel,
    WMICPolicyChangeUtilsService,
} from 'wmic-capability-policychange';
import { useModal } from '@jutro/components';
import { WMICModal } from 'gw-capability-policy-react';
import WMICClickableIconTooltipComponent from 'gw-capability-policy-common-react/components/WMICClickableIconTooltipComponent/WMICClickableIconTooltipComponent';
import VehicleModel from 'wmic-capability-policychange/models/VehicleModel';
import WMICChangeRoadsideAssistanceToggle from './WMICChangeRoadsideAssistanceToggle/WMICChangeRoadsideAssistanceToggle';

import metadata from './WMICChangeRoadsideAssistanceComponent.metadata.json5';
import messages from './WMICChangeRoadsideAssistanceComponent.messages';
import styles from './WMICChangeRoadsideAssistanceComponent.module.scss';

const ROADSIDE_ASSISTANCE_CHANGE_TYPE = {
    ADD: 'ADD',
    DELETE: 'DELETE'
};

function WMICChangeRoadsideAssistanceComponent(props) {
    const modalApi = useModal();
    const { policyVM, match, loadingState, setLoadingState } = props;
    const translator = useTranslator();
    const history = useHistory();
    const { authHeader } = useAuthentication();
    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');
    const policyType = _.get(match, 'params.policyType');
    const accountHolder = _.get(policyVM, 'currentPeriod.contacts')
        .filter((contact) => { return contact.contact.accountHolder === true; })[0];
    const [hasChanges, setHasChanges] = useState(false);

    // To resolve issues where screen readers don't announce content on page load for SPAs
    // Reference: https://www.gatsbyjs.com/blog/2019-07-11-user-testing-accessible-client-routing/
    useEffect(() => {
        const headerTag = document.getElementById('changeRoadsideAssistanceHeader');
        if (headerTag && !loadingState) {
            headerTag.focus({preventScroll: true});
        }
    }, [loadingState])

    const hasRoadsideAssistance = (vehicle) => {
        // vehicle.coverages.push({patternCode_WMIC: CONSTANTS.COVERAGE_NAMES.PA_ROADASSIST, val: true});
        return typeof _.find(vehicle.coverages, {
            patternCode_WMIC: CONSTANTS.COVERAGE_NAMES.PA_ROADASSIST
        }) !== 'undefined';
    };

    const prepareVehiclesData = (personalAuto) => {
        if(!personalAuto.vehicleDTOs || personalAuto.vehicleDTOs.length === 0){
            return [];
        }
        
        return personalAuto.vehicleDTOs.map((vehicle) => ({
            vehicle: {
                make: vehicle.make,
                model: vehicle.model,
                year: vehicle.year,
                vin: vehicle.vin,
                vehicleNumber: vehicle.vehicleNumber
            },
            roadAssistanceAvailable: true,
            hasRoadsideAssistance: hasRoadsideAssistance(vehicle),
            initialRoadside: hasRoadsideAssistance(vehicle)
        }));
    };

    const originalData = prepareVehiclesData(policyVM.currentPeriod.lobs.personalAuto);
    const [vehiclesData, saveVehicleChanges] = useState(_.cloneDeep(originalData));
    const isRoadsideAssistanceAvailable = () => {
        return originalData.length > 0 && originalData.some((v) => {return v.roadAssistanceAvailable});
    }

    const getChangeRoadsideAssistanceTitle = () => {
        return (
            <Fragment>
                <i className={classNames("fa fa-file-text", styles['wmic-title-icon'])} />
                <h2 className={styles['wmic-title']}>{translator(messages.changeRoadsideAssistanceTitle)}</h2>
            </Fragment>
        );
    };

    const getAddRoadsideAssistanceTooltipMessage = () => {
        const vehicleAgeLimitDate = new Date(CONSTANTS.DATE_CONSTANTS.ROADSIDE_ASSIST_VEHICLE_AGE_LIMIT_REMOVAL_DATE);
        const rpcDate = new Date(CONSTANTS.DATE_CONSTANTS.RPC1578_ROADSIDE_ASSIST_LIMIT_INCREASE_DATE);
        const effDate = new Date(policyVM.currentPeriod.effective);
        if (effDate >= rpcDate) {
            return (
                <Fragment>
                    { translator(messages.addRoadsideAssistanceTooltipMessageAfterRPC1578) }
                </Fragment>
            );
        }
        
        if (effDate >= vehicleAgeLimitDate) {
            return (
                <Fragment>
                    { translator(messages.addRoadsideAssistanceTooltipMessageFirst) }
                </Fragment>
            );
        }

        return (
            <Fragment>
                { translator(messages.addRoadsideAssistanceTooltipMessageFirst) }
                { translator(messages.addRoadsideAssistanceTooltipMessageSecond) }
            </Fragment>
        );
    };

    const handleCancel = () => {
        history.push(`/account-policy-change/${policyNumber}/${termNumber}/${policyType}/${CONSTANTS.POLICY_CHANGE_TYPE.SELECT_TYPE}`);
    };

    const handleSubmit = () => {
        const ctrlKey = 'roadside-assistance';
        for(const data of vehiclesData){
            if(data.initialRoadside !== data.hasRoadsideAssistance){
                const changeType = data.hasRoadsideAssistance ? ROADSIDE_ASSISTANCE_CHANGE_TYPE.ADD: ROADSIDE_ASSISTANCE_CHANGE_TYPE.DELETE;
                const id = `PA_RoadAssistCov-${data.vehicle.vehicleNumber}`;
                const raModel = new RoadsideAssistanceModel({changeType:changeType, vehicle: new VehicleModel({
                    year: data.vehicle.year,
                    make: data.vehicle.make,
                    model: data.vehicle.model,
                    vin: data.vehicle.vin
                })});
                const model = {
                    objectType: 'RoadsideAssistanceModel',
                    vehicleNumber: raModel.vehicle.vehicleNumber,
                    changeType: data.hasRoadsideAssistance ? CONSTANTS.POLICY_CHANGE_TYPE.ROADSIDE_ASSISTANCE_ADD: CONSTANTS.POLICY_CHANGE_TYPE.ROADSIDE_ASSISTANCE_DELETE,
                    changeModel: raModel
                };

                if (changeType.toLowerCase() === 'add') {
                    publish(JUTRO_TOPICS.BUTTON_CLICKED, { trackButtonIdentifier: translator(messages.roadsideAssistanceConfirmTrackingText, {changeType: raModel.CHANGE_TYPE.ADD})});
                } else if (changeType.toLowerCase() === 'remove') {
                    publish(JUTRO_TOPICS.BUTTON_CLICKED, { trackButtonIdentifier: translator(messages.roadsideAssistanceConfirmTrackingText, {changeType: raModel.CHANGE_TYPE.DELETE})});
                }

                WMICPolicyChangeStateService.addChangeRequest(ctrlKey, id, model);
            }
        }
        
        const changes = WMICPolicyChangeStateService.getChangeRequests();
        const models = Object.entries(changes).map(([key, value]) => value.changeModel);
        const modalBody = WMICPolicyChangeUtilsService.getModifiedBody(models, messages.confirmPolicyChangesModalBodyTitle, translator);
        modalApi.showModal(
            <WMICModal
                id="changeRoadsideAssistanceSubmitModal"
                modalHeader={translator(messages.confirmPolicyChangesModalTitle)}
                modalBody={modalBody}
                onConfirmCallback={() => {
                    setLoadingState(true);

                    publish(JUTRO_TOPICS.BUTTON_CLICKED, { trackButtonIdentifier: translator(messages.submitTrackingTextRoadsideAssistance)});

                    WMICControllerUtil.handleSubmit({
                        history: history,
                        policyNumber: policyNumber,
                        termNumber: termNumber,
                        policyData: policyVM,
                        policyType: policyType,
                        accountInfo: accountHolder,
                        authHeader: authHeader
                    },
                    translator);
                }}
                onCancelCallback={() => _.noop()}
                confirmButtonText={translator(messages.confirmPolicyChangesModalButtonOk)}
                cancelButtonText={translator(messages.policychangeButtonCancel)}
            />
        );
       
    };

    const inputHasChanged = useCallback(() => {
        saveVehicleChanges(vehiclesData);
        return setHasChanges(!_.isEqual(originalData, vehiclesData));
    }, [originalData, vehiclesData]);

    const getChangeRoadsideAssistanceVehicleToggleRows = () => {
        return vehiclesData;
    };

    const getChangeRoadsideAssistanceConfirmationButtons = () => {
        if(!isRoadsideAssistanceAvailable()) {
            return (
                <Fragment>
                    <WMICButton type="outlined" onClick={handleCancel}>
                        { translator(messages.cancel) }
                    </WMICButton>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <WMICButton type="outlined" onClick={handleCancel}>
                    { translator(messages.cancel) }
                </WMICButton>
                <WMICButton type="primary" onClick={handleSubmit} disabled={!hasChanges}>
                    { translator(messages.continue) }
                </WMICButton>
            </Fragment>
        );
    };

    const overrideProps = {
        changeRoadsideAssistanceTitle: {
            content: getChangeRoadsideAssistanceTitle()
        },
        addRoadsideAssistanceTooltip: {
            tooltipMessage: getAddRoadsideAssistanceTooltipMessage(),
            tooltipId: 'tooltipAddRoadsideAssistance',
            placement: 'right',
            flipBehavior: ['right']
        },
        changeRoadsideAssistanceVehicleToggleList: {
            data: getChangeRoadsideAssistanceVehicleToggleRows(),
            visible: isRoadsideAssistanceAvailable()
        },
        changeRoadsideAssistanceUnavailable: {
            visible: !isRoadsideAssistanceAvailable()
        },
        changeRoadsideAssistanceConfirmationButtons: {
            content: getChangeRoadsideAssistanceConfirmationButtons()
        },
        changeRoadsideAssistanceSection: {
            visible: !loadingState
        }
    };

    const resolvers = {
        resolveComponentMap: {
            wmicclickableicontooltipcomponent: WMICClickableIconTooltipComponent,
            wmicchangeRoadsideAssistanceToggle: WMICChangeRoadsideAssistanceToggle
        },
        resolveCallbackMap: {
            inputHasChanged
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICChangeRoadsideAssistanceComponent.propTypes = {
    policyVM: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired
};

export default WMICChangeRoadsideAssistanceComponent;