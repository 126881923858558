const CONSTANTS = {
    Person: 'Person',
    Company: 'Company',
    cancel: 'cancel',
    UNAVAILABLE: 'unavailable',
    POLICY_CHANGE: 'policy change',
    RENEWAL: 'renewal',
    IN_FORCE: 'in force',
    INFORCE: 'inforce',
    SCHEDULED: 'scheduled',
    FUTURE: 'future',
    CANCELED: 'canceled',
    EXPIRED: 'expired',
    NEW: 'new',
    RENEWING: 'renewing',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    LIST_BILL: 'ListBill',
    FULL_PAY: 'Full Pay',
    TWO_PAY: 'Two Pay',
    THREE_PAY: 'Three Pay',
    MONTHLY_PAY: 'Monthly Automated Recurring',
    MONTHLY: 'monthly',
    FULL_PAYMENT: 'fullPayment',
    TWO_PAYMENTS: 'twoPayments',
    OVERDUE: 'overdue',
    PAYMENT_DUE: 'paymentDue',
    NEXT_PAYMENT: 'nextPayment',
    WAWANESA_URL: 'https://www.wawanesa.com',
    QUOTED: 'Quoted',
    BOUND: 'Bound',
    SKIP_CANCEL_MODAL: ['knockoutpage', 'logout', 'summary'],
    CA_PRIVACY_LINK: 'https://wawanesa.com/us/legal/privacy-policy.html',
    SELECTED_ADDRESS: 'selectedAddress',
    SEARCH_TEXT: 'searchText',
    DEFAULT_SEARCH_KEY: 'search',
    OTHER: 'other',
    UNKNOWN: 'unknown',
    UNK: 'unk',
    MULTIPLE: 'Multiple',
    DRIVER_TYPES: {
        ASSIGNED: 'Assigned',
        UNASSIGNED: 'Unassigned',
        EXCLUDED: 'Excluded'
    },
    DRIVERS_TYPE: {
        PRINCIPAL: 'principal',
        OCCASIONAL: 'occasional',
    },
    ASSIGNMENT: {
        UNASSIGNED: 'Unassigned',
        RESTRICTED: 'Restricted',
        EXCLUDED: 'Excluded'
    },
    REASON: {
        OTHER: 'Other'
    },
    OCCUPATION_TYPES: {
        PA_QC: 'PAOccupationTypesQC',
        GUI_SELECTABLE: 'GUI_Selectable'
    },
    VENDOR_TYPES: {
        INDEPENDENT: 'independent',
        FACILITY: 'repair_facility'
    },
    REPAIR_STATUS: {
        ESTIMATING: 'estimating',
        REPAIRING: 'repairing'
    },
    INCLUDED_PAYMENT_STATUS: {
        VOIDED: 'voided'
    },
    PAYMENT_DETAIL_STATUS: {
        ISSUED: 'Issued',
        VOIDED: 'Voided',
        DEPOSITED: 'Deposited',
        CASHED: 'Cashed'
    },
    CLAIM_STATUS: {
        DRAFT: 'draft',
        OPEN: 'open',
        CLOSED: 'closed',
        ARCHIVED: 'archived'
    },
    COVERAGE_NAMES: {
        PA_COMPREHENSIVE: 'PA_ComprehensiveCov',
        PA_COLLISION: 'PA_CollisionCov',
        PA_RENTAL: 'PA_RentalExpCov',
        PA_VMRP: 'PA_VMRPCov',
        PA_UNIMSPD: 'PA_UNIMSPDCov',
        PA_UNIMSCOLL: 'PA_UNIMSCollCov',
        PA_UMCOLLISIONWAIVER: 'PA_UMCollisionCovWaiver',
        PA_SVE: 'PA_SVECov',
        PA_ROADASSIST: 'PA_RoadAssistCov',
        PA_UMPROPERTYWAIVER: 'PA_UMPropertyCovWaiver',
        PA_BI: 'PA_BICov',
        PA_PD: 'PA_PDCov',
        PA_PIP: 'PA_PIPCov',
        PA_MEDPAY: 'PA_MedPayCov',
        PA_UNIMSBI: 'PA_UNIMSBICov',
        PA_UMWAIVER: 'PA_UMCovWaiver',
        HO_PACKAGE: 'HO_PackageCov',
        HO_FORMTYPE: 'HO_FormType',
        HO_PERSONALPROPERTYREPLACEMENTCOSTSETTLEMENT: 'HO_PersonalPropertyReplacementCostSettlementCov',
        HO_DWELLING: 'HO_DwellingCov',
        HO_OTHERSTRUCTURES: 'HO_OtherStructuresCov',
        HO_PERSONALPROPERTY: 'HO_PersonalPropertyCov',
        HO_PERSONALPROPERTY_LMT: 'HO_PersonalPropertyCovLmt',
        HO_ADDLLIVINGEXP: 'HO_AddlLivingExpCov',
        HO_PERSONALLIABILITY: 'HO_PersonalLiabilityCov',
        HO_MEDICALPAYMENTS: 'HO_MedicalPaymentsCov',
        HO_BUSINESSPURSUITSCLERICAL: 'HO_BusinessPursuitsClericalCov',
        HO_BUSINESSPURSUITSSALESPERSON: 'HO_BusinessPursuitsSalespersonCov',
        HO_BUSINESSPURSUITSTEACHER: 'HO_BusinessPursuitsTeacherCov',
        HO_BUSINESSPURSUITSOTHER: 'HO_BusinessPursuitsOther',
        HO_CREDITCARD: 'HO_CreditCardCov',
        HO_EARTHQUAKE: 'HO_EarthquakeCov',
        HO_HOMECYBERPROTECTION: 'HO_HomeCyberProtection',
        HO_SPECIALLIMITSENHANCEMENT: 'HO_SpecialLimitsEnhancement',
        HO_HOMESYSTEMSPROTECTION: 'HO_HomeSystemsProtection',
        HO_IDENTITYFRAUDEXPENSE: 'HO_IdentityFraudExpense',
        HO_INCRLMTBUSPROPERTY: 'HO_IncrLmtBusPropertyCov',
        HO_LOSSASSESSMENT: 'HO_LossAssessCov',
        HO_SERVICELINE: 'HO_ServiceLineCov',
        HO_WILDFIRERESPONSE: 'HO_WildfireResponse',
        HO_INCRSPECLMTOFLIAB: 'HO_IncrSpecLmtOfLiabCov',
        HO_PERMINCOCCRES: 'HO_PermIncOccResPremCov',
        HO_OTHERSTRUCTURESENDORSEMENT: 'HO_OtherStructuresEndorsementCov',
        HO_WATERCRAFTMOTOR_WMIC: 'HOWatercraftMotor_WMIC',
        HO_SCHEDULEDPERSONALPROPERTY: 'HO_SchedPersPropCov',
        HO_SPECIALPERSONALPROPERTY: 'HO_SpecialPersPropCov',
        PERSONAL_PROPERTY: 'Personal Property',
        ADDITIONAL_LIVING_EXPENSES: 'Additional Living Expenses',
        PERSONAL_LIABILITY: 'Personal Liability',
        MEDICAL_PAYMENTS: 'Medical Payments',
        PERSONAL_PROPERTY_REPLACEMENT: 'Personal Property Replacement Cost Loss Settlement',
        SPECIAL_PERSONAL_PROPERTY: 'Special Personal Property'
    },
    COVERAGE_TERM_NAMES: {
        PA_RENTALEXPCOVLIMIT: 'PA_RentalExpCovLmt',
        PA_COLLISIONCOVRENTALEXPLIMIT: 'PA_CollisionCovRentalExpLmt',
        PA_COMPREHENSIVECOVRENTALEXPLIMIT: 'PA_ComprehensiveCovRentalExpLmt',
        PA_COLLISIONCOVDED: 'PA_CollisionCovDed',
        PA_COMPREHENSIVECOVDED: 'PA_ComprehensiveCovDed'
    },
    VEHICLE_PRIMARY_USE: {
        PLEASURE: 'pleasure',
        BUSINESS: 'business',
        COMMUTING: 'commuting'
    },
    NO_DEDUCTIBLE: 'No Deductible',
    JURISDICTIONS: {
        CA: 'CA',
        OR: 'OR'
    },
    POLICY_TYPES: {
        HO: {
            HOMEOWNERS: 'homeowners',
            HOMEOWNERS_CAPITAL: 'Homeowners',
            HOMEOWNERS_HOE: 'HomeownersLine_HOE',
            HOMEOWNER: 'homeowner',
            TENANT: 'tenant',
            RENTERS: 'renters',
            CONDOMINIUM: 'condominium'
        }
    },
    VEHICLE_TYPES: {
        TRAILER: 'trailer',
        CAMPER: 'camper'
    },
    QUESTION_TYPES: {
        BOOLEAN: 'Boolean',
        INTEGER: 'Integer'
    },
    CONFIG_PERSONA: {
        PRODUCER: 'producer',
        CSR: 'csr'
    },
    RESIDENCE_TYPES: {
        SINGLE_FAMILY: 'SingleFamily_wmic'
    },
    DEVICE_TYPES: {
        ANDROID: 'android',
        IOS: 'ios'
    },
    DOCUMENT_TYPE: {
        VID: 'VID',
        PADEC: 'PADEC',
        HODEC: 'HODEC',
        CADCF: 'CADCF',
        CADCFA: 'CADCFA',
        ORDCF: 'ORDCF',
        ORDCFA: 'ORDCFA',
        ORRA: 'ORRA',
        CARA: 'CARA',
        RCN: 'RCN',
        WFRD: 'WFRD'
    },
    DOCUMENT_PATTERNS: {
        STATEMENT_OF_ACCOUNT: 'StatementOfAccount',
        OFFER_OF_EARTHQUAKE: 'OfferOfEarthquake'
    },
    ERROR: 'error',
    CONTACT_ROLES: {
        ACCOUNT_HOLDER: 'AccountHolder',
        ADDITIONAL_INTEREST: 'Additional Interest'
    },
    PHONE_TYPES: {
        HOME: 'home',
        WORK: 'work',
        MOBILE: 'mobile'
    },
    PAYMENT_METHODS: {
        ONE_TIME_WITHDRAWAL: 'OneTimeWithdrawal'
    },
    INTEGRATION_CC_PAYMENT_STATUS: {
        COMPLETE: 'complete',
        ERROR: 'error'
    },
    FINANCE_LEASING: {
        CHANGE_TYPE_ADD: 'ADD',
        CHANGE_TYPE_CHANGE: 'CHANGE',
        CHANGE_TYPE_SEND: 'SEND',
        CHANGE_TYPE_DELETE: 'DELETE',
    },
    COUNTRY: {
        US: 'US',
        US_LONG: 'UnitedStates',
        CA: 'CA'
    },
    STATE: {
        CA: 'CA',
        OR: 'OR',
        CA_LONG: 'california',
        OR_LONG: 'oregon',
        AB: 'AB'
    },
    LOB_STATUSES: {
        EXPIRED: 'expired',
        CANCELLED: 'canceled',
        IN_FORCE: 'inforce',
        SCHEDULED: 'scheduled'
    },
    NOTICE_TYPE: {
        NONE: 'none',
        AMP: 'amp',
        CLAIMS: 'claims',
        CARD: 'card',
        BANK: 'bank',
        MANAGEBANKACCOUNTS: 'manageBankAccounts',
        PAYMENTOPTIONS: 'paymentoptions',
        CUSTOMEREVENTS: 'customerevents',
        POLICYCHANGE: 'policychange',
        FUTUREDATEDOTWPAYMENTS: 'futuredatedotwpayments',
        ASSISTEDPOLICYCHANGE: 'enableWebPolicyChangeForms',
        CLAIMSFNOL: 'enableClaimsFNOL',
        EMQ: 'enableEMQ',
        WALLETPASS: 'walletpass',
        PAYMENTS: 'payments'
    },
    CENTRAL: 'central',
    NONE: 'none',
    POLICY_CHANGE_TYPE: {
        SELECT_TYPE: 'select-type',
        CHANGE_ADDRESS: 'change-address',
        PAYMENT_PLAN: 'payment-plan',
        FINANCE_LEASING: 'finance-leasing',
        FINANCE_LEASING_ADD: 'finance-leasing-add',
        FINANCE_LEASING_CHANGE: 'finance-leasing-change',
        FINANCE_LEASING_DELETE: 'finance-leasing-delete',
        FINANCE_LEASING_SEND: 'finance-leasing-send',
        DELETE_VEHICLE: 'delete-vehicle',
        ROADSIDE_ASSISTANCE: 'roadside-assistance',
        ROADSIDE_ASSISTANCE_ADD: 'roadside-assistance-add',
        ROADSIDE_ASSISTANCE_DELETE: 'roadside-assistance-delete',
        ADJUST_COVERAGES: 'adjust-coverages',
        OTHER_CHANGES: 'other-changes',
        DESIGNATED_PERSON: 'designated-person',
        DESIGNATED_PERSON_ADD: 'designated-person-add',
        DESIGNATED_PERSON_CHANGE: 'designated-person-change',
        DESIGNATED_PERSON_DELETE: 'designated-person-delete',
        EDIT_POLICY_SELF_SERVICE: 'edit-policy-self-service',
        REVIEW_POLICY_CHANGE: 'review-policy-change',
        BOUND_POLICY_CHANGE: 'bound-policy-change',
        EDIT_UNINSURED: 'edit-uninsured',
        BODILY_INJURY: 'bodily-injury',
        SUCCESS_PAGE: 'success-page'
    },
    PRIMARY_NAMED_INSURED: 'Primary Named Insured',
    PRIMARY_NAMED_INSURED_CAMEL_CASE: 'primaryNamedInsured',
    ADD_NAMED_INSURED_CAMEL_CASE: 'addlNamedInsured',
    DAP_PERSON_CAMEL_CASE: 'dapPerson_WMIC',
    EMAIL_SETTINGS: {
        SENDER_EMAIL: 'webserver@wawanesageneral.com',
        RECIPIENT_EMAIL: 'usallother@wawanesa.com'
    },
    QUICK_LINKS: {
        WMIC_US: 'https://www.wawanesa.com/us/',
        CA: {
            RENTERS_COVERAGE: 'https://www.wawanesa.com/us/insurance/renters/ca/',
            RENTERS_DISCOUNTS: 'https://www.wawanesa.com/us/insurance/renters/ca/discounts.html',
            RENTERS_INSURANCE_FAQ: 'https://www.wawanesa.com/us/insurance/renters/faq.html'
        },
        OR: {
            RENTERS_COVERAGE: 'https://www.wawanesa.com/us/insurance/renters/or/',
            RENTERS_DISCOUNTS: 'https://www.wawanesa.com/us/insurance/renters/or/discounts.html',
            RENTERS_INSURANCE_FAQ: 'https://www.wawanesa.com/us/insurance/renters/faq.html'
        }
    },
    DATE_CONSTANTS: {
        RPC1578_ROADSIDE_ASSIST_LIMIT_INCREASE_DATE: '2022-06-01',
        ROADSIDE_ASSIST_VEHICLE_AGE_LIMIT_REMOVAL_DATE: '2021-09-16'
    },
    PAYMENT_TYPES: {
        CREDIT_CARD: 'creditcard',
        WIRE: 'wire',
        REDISTRIBUTE: 'redistribute',
        NONE: 'none'
    },
    FNOL: {
        DRIVER_VALES: {
            OUR_INSURED: 'OurInsured',
            NO_DRIVER: 'NoDriver',
            OTHER_PARTY: 'OtherParty',
            UNKNOWN: 'Unknown'
        },
        VARIANTS: {
            INSURED: 'insured',
            OTHER_PARTY: 'otherParty',
            DRIVER: 'driverOfInsuredVehicle'
        },
        SHARED_DATA_PATHS: {
            INSURED: 'insured',
            OTHER_PARTY_CLAIMANT: 'otherPartyClaimant'
        },
        YOUR_INFORMATION: {
            INSURED: 'INSURED',
            OTHER_PARTY: 'OTHER_PARTY'
        },
        POSTAL_CODE_MASKS: {
            US_CODE: '99999',
            CA_CODE: 'a9a-9a9'
        }
    },
    TITLE: {
        CA: 'Online - Renters Insurance Quotes California | Wawanesa ',
        OR: 'Online - Renters Insurance Quotes Oregon | Wawanesa ',
        DEFAULT: 'Online - Renters Insurance Quotes | Wawanesa '
    },
    CTA_DELINQUENCY_TYPE: {
        NOT_TAKEN: 'NotTaken',
        PAST_DUE: 'PastDue',
        NSF: 'NSF'
    },
    DELINQUENCY_STATUS: {
        OPEN: 'Open'
    },
    GOOGLE_ADDRESS_DETAILS: {
        COUNTY: "administrative_area_level_2"
    },
    DOG_TABLES: {
        OTHER: 'Other',
    },
    PREFILLED_STORAGE_KEYS: {
        PA_POLICY_CHANGE_JOB_NUMBER: 'PA_POLICY_CHANGE_JOB_NUMBER'
    },
    PREQUAL_QUESTIONS_WITH_BULLET_POINTS: ['ISSpecialVehicle_wmic', 'ISSpecialVehicleFsra_Ext', 'BPASSPUPPrequalProfession_WMIC', 'ISForUndesirableCommercialPurposes_wmic', 'ISForUndesirableCommerPurposeFsra_Ext', 'SingleWallTank', 'BPASSCPPrequalPerformOperations_WMIC'],
    FSRA_PREQUAL_QUESTION_EXCLUSIONS: ['ISForUndesirableCommerPurposeFsra_Ext', 'ISCancelledForNonPaymtFor3YearsFsra_Ext', 'ISCancelledNonPay2OrMoreIn3YearsFsra_Ext'],
};

export default CONSTANTS;

/**
 * Flow Step for Validation
 * @readonly
 * @enum {String}
 */
export const FlowStepId = {
    YOUR_INFO: 'YourInfo',
    OFFERING: 'Offering',
    PRE_QUALIFICATION: 'PreQualification'
};

/**
 * Validation Levels
 * @readonly
 * @enum {String}
 */
export const ValidationLevel = {
    LOAD_SAVE: 'loadsave',
    DEFAULT: 'default',
    QUOTABLE: 'quotable',
    BINDABLE: 'bindable',
    READY_FOR_ISSUE: 'readyforissue',
    QUICK_QUOTABLE: 'quickquotable'
};

/**
 * Element position
 * @readonly
 * @enum {String}
 */
export const Position = {
    TOP: 'top',
    LEFT: 'left',
    RIGHT: 'right',
    BOTTOM: 'bottom'
};

/**
 * Device Breakpoints
 * @readonly
 * @enum {String}
 */
export const DeviceBreakpoint = {
    DESKTOP: 'desktop',
    PHONE: 'phone',
    TABLET: 'tablet'
};

/**
 * @readonly
 * @enum {String}
 */
export const LOBConstants = {
    PA: 'PersonalAuto',
    PUP: 'PersonalUmbrella',
    PP: 'Homeowners',
    HOME: 'home',
    PA_LINE: 'PersonalAutoLine',
    PP_LINE: 'HomeownersLine_HOE',
    PUP_LINE: 'PersonalUmbrellaLine_PUE'
};

export const ACTION_TYPES = {
    ADD: 'add',
    REMOVE: 'remove'
};

export const APP_NAMES = {
    CE: 'customer-engage',
    PE: 'producer-engage',
    AMP: 'account-management'
};

export const FORMAT_CODES = {
    myWork: 'myWork',
    FOR_CURRENT_USER: 'FOR_CURRENT_USER',
    all: 'all',
    ALL: 'ALL',
    BY_PRODUCER_CODES: 'BY_PRODUCER_CODES'
};

export const ICON_NAMES = {
    ICON_USER: 'fa-user',
    ICON_BUILDING: 'fa-building'
};

export const POLICY_DETAILS = {
    PROVINCES_WITH_LANGUAGE_PREFERENCE: ['NB', 'NS', 'PE', 'QC'],
    FRENCH: 'french',
    NEW_BRUNSWICK: 'NB',
    NOVA_SCOTIA: 'NS',
    ONTARIO: 'ON',
    PRINCE_EDWARD_ISLAND: 'PE',
    QUEBEC: 'QC',
    SNOW: 'snow',
    NO: 'no',
    YES: 'yes',
    NOTRELATED: 'notrelated',
    AGE_ALLOWED_TO_DRIVER: 14
};

export const WATERCRAFT_TYPES = {
    PATH: 'watercraftType.value.code',
    OUTBOARDMOTOR: 'OutboardMotor',
    PERSONALWATERCRAFT: 'PersonalWatercraft',
    BOATEQUIPMENTTRAILER: 'BoatEquipmentTrailer'
};

export const BOAT_TYPES = {
    PATH: 'boatType.value.code',
    SAILBOAT: 'Sailboat',
    OTHER: 'Other'
};

/**
 * @readonly
 * @enum {String}
 */
export const AddressType = {
    home: 'home'
};

/**
 * @readonly
 * @enum {String}
 */
export const JobType = {
    SUBMISSION: 'Submission',
    POLICY_CHANGE: 'PolicyChange',
    RENEWAL: 'Renewal'
};

export const SUITES = {
    PC: 'pc',
    BC: 'bc',
    CC: 'cc'
};

export const AUTHORITY_TYPE = {
    ACCOUNT: 'ACCOUNT',
    POLICY: 'POLICY'
};

/**
 * @readonly
 * @enum {String}
 */
export const DwellingUsageType = {
    primary: 'prim',
    rental: 'rent'
};

/**
 * @readonly
 * @enum {String}
 */
export const ERROR_CODE = {
    CLUE_REPORT: 'CLUEReport',
    PAYMENT: 'PaymentError',
    CONSENT: 'ConsentError',
    AUTHORIZATION: 'AuthorizationError',
    DAY_TRANSITION: 'DayTransitionError',
    AVAILABILITY: 'Availability',
    TWELVE_PAY_BIND: 'TwelvePayBindError',
    INVALID_SESSION: 'InvalidSession',
    INVALID_SUB_SESSION: 'Invalid Submission Session Exception',
    PORTAL_VALIDATION: 'PortalValidation',
    DECLINE: 'Decline',
    UW_ISSUE: 'UWIssue',
    TECHNICAL_ERROR_RETRIEVE_QUOTE: 'TechnicalErrorRetrieveQuote',
    TECHNICAL_ERROR_CREATE_QUOTE: 'TechnicalErrorCreateQuote',
    TECHNICAL_ERROR_UPDATE_QUOTE: 'TechnicalErrorUpdateQuote',
    TECHNICAL_ERROR: 'TechnicalError',
    OTWCONSENT: 'OTWConsentError',
    MARCONSENT: 'MARConsentError',
    QNB_UNAVAILABLE: 'RentersQnBUnavailable'
};

/**
 * @readonly
 * @enum {String}
 */
export const PaymentPlanID = {
    FULL_PAY: 'bc:2001',
    THREE_PAY: 'bc:2002',
    TWELVE_INSTALLMENT: 'bc:2005'
};

/**
 * @readonly
 * @enum {String}
 */
export const PAYMENT_METHOD = {
    CREDIT_CARD: 'creditcard',
    WIRE: 'wire',
    AUTO_RECURRING: 'autorecurring_wmic',
    AUTO_RECURRING_ACCOUNT_INFO: 'autorecurring_account_info_wmic'
};

export const FEATURE_FLAG_NAMES = {
    AMP: 'operational-amp',
    BANK: 'operational-bank',
    CARD: 'operational-card',
    CLAIMS: 'operational-claims',
    POLICYCHANGE: 'temp-policychange',
    TEST_DATA: 'temp-jsontest',
    CTA: 'operational-calltoaction',
    CTA_PERIODIC_UPDATES: 'operational-ctaperiodicupdates',
    CTA_PERIODIC_UPDATES_ADDRESS: 'operational-ctaperiodicupdatesaddress',
    CTA_RCN: 'operational-ctarcn',
    EMQ: 'operational-emq',
    FNOL: 'operational-fnol',
    FUTUREDATEDOTWPAYMENTS: 'operational-futuredatedotwpayments',
    DELETEVEHICLE: 'temp-deletevehicle',
    WALLETPASS: 'operational-walletpass',
    ASSISTEDPOLICYCHANGE: 'temp-webpolicychangeforms',
    CTA_LISTBILL: 'temp-calltoactionlistbill',
    DASHBOARDCONSENT: 'temp-dashboardconsent',
    MANAGEBANKACCOUNTS: 'temp-managebankaccounts',
    MANAGEBANKACCOUNTSINPROFILE: 'temp-managebankaccountsinprofile',
    POLICYPLANCHANGETOMAR: 'temp-policyplanchangetomar',
    ONEINCPAYMENTS: 'temp-oneincpayment',
    OTWCONSENT: 'temp-otwconsent',
    CTA_PAYMENT_PLAN_RESTRICTION: 'temp-calltoactionpaymentplanrestriction',
    CA_AUTO_FULL_PAY_RESTRICTION: 'temp-caautofullpayrestriction',
    ADDITIONAL_DOCUMENTS: 'temp-additionaldocuments',
    LOGGINGTOSPLUNK: 'temp-loggingtosplunk',
    pc8740: 'usepc8740',
    marp: 'operational-marp',
    recaptchaV3: 'recaptchaV3',
    testOutageFlag: 'testOutage', // for testing purposes only
    CUSTOMEREVENTS: 'operational-customerevents',
    LOGGINGCSRF: 'operational-logging-csrf',
    CLAIMSDEDUCTIBLE: 'operational-claims-deductible',
    CLAIMSADJUSTERS: 'operational-claims-adjusters',
    CLAIMSREPAIRFACILITY: 'operational-claims-repair-facility',
    CLAIMSPAYMENTS: 'operational-claims-payments',
    CLAIMSRENTALS: 'operational-claims-rentals',
    CLAIMSDOCUMENTS: 'operational-claims-documents',
    RENTERSQNB: 'operational-rentersqnb',
    FEATURE_FLAG_TOOL_TYPES: {
        SPLIT_IO: 'split-io'
    },
    CA_AUTO_CREDIT_CARD_RESTRICTION: 'temp-caautocreditcardrestriction',
    PAYMENTS: 'operational-payments',
    PREFERRED_AUTO_SHOPS: 'operational-preferredautoshops'
};

// TODO The flags marked as deprecated are not set in the feature flag vendor anymore. I keep them just in case they are still in use on some applications. They will fall into 'off' if requested and can be removed from here and from FEATURE_FLAG_NAMES in the future
export const FEATURE_FLAG_DEFAULT_VALUES = { // Used when the connection to vendor fails
    pc8740: 'deprecated',
    marp: 'on', // deprecated, on by default
    recaptchaV3: 'deprecated',
    testOutageFlag: 'deprecated', // for testing purposes only
    FNOL: 'on',
    AMP: 'on',
    CLAIMS: 'on',
    CARD: 'on',
    BANK: 'on',
    CUSTOMEREVENTS: 'deprecated',
    DASHBOARDCONSENT: 'on',
    EMQ: 'off',
    FUTUREDATEDOTWPAYMENTS: 'on',
    MANAGEBANKACCOUNTS: 'on',
    POLICYCHANGE: 'on',
    DELETEVEHICLE: 'on',
    ASSISTEDPOLICYCHANGE: 'on',
    LOGGINGCSRF: 'deprecated',
    WALLETPASS: 'on',
    CA_AUTO_FULL_PAY_RESTRICTION: 'on',
    CLAIMSDEDUCTIBLE: 'deprecated',
    CLAIMSADJUSTERS: 'on', // deprecated, on by default
    CLAIMSREPAIRFACILITY: 'deprecated',
    CLAIMSPAYMENTS: 'deprecated',
    CLAIMSRENTALS: 'deprecated',
    CLAIMSDOCUMENTS: 'deprecated',
    CTA: 'on',
    CTA_LISTBILL: 'on',
    CTA_PERIODIC_UPDATES: 'on',
    CTA_PERIODIC_UPDATES_ADDRESS: 'on',
    CTA_PAYMENT_PLAN_RESTRICTION: 'on',
    CTA_RCN: 'on',
    ONEINCPAYMENTS: 'on',
    CA_AUTO_CREDIT_CARD_RESTRICTION: 'on',
    RENTERSQNB: 'on',
    OTWCONSENT: 'on',
    TEST_DATA: 'off',
    POLICYPLANCHANGETOMAR: 'on',
    MANAGEBANKACCOUNTSINPROFILE: 'off',
    LOGGINGTOSPLUNK: 'on',
    ADDITIONAL_DOCUMENTS: 'on',
    PAYMENTS: 'on'
};

/**
 * @readonly
 * @enum {String}
 */
export const ESIGNATURE_STATUS = {
    SIGNED: 'Signed',
    OPTIMISTICALLY_SIGNED: 'OptimisticallySigned',
    PENDING_SIGNATURE: 'PendingSignature',
    EXPIRED: 'Expired',
    DECLINED: 'Declined',
    CANCELLED: 'Cancelled',
};

export const RETRIEVE_AVAILABLE_MESSAGE = {
    QUOTE_NOT_FOUND: 'QuoteNotFound',
    QUOTE_DECLINED: 'QuoteDeclined',
    QUOTE_EXPIRED: 'QuoteExpired',
    UNDERWRITING_ISSUE: 'UnderwritingIssue',
    UNDERWRITER_REVIEW: 'UnderwriterReview',
    QUOTE_NOT_VALID: 'QuoteNotValid',
    QUOTE_NOT_TAKEN: 'QuoteNotTaken',
    PORTAL_VALIDATION: 'PortalValidation'
};

/**
 * @readonly
 * @enum {String}
 */
export const EMQ_USAGE_TYPES = {
    COMMUTING: 'Commuting',
    COMMUTING_LONG: 'Commute to or from work / school',
    PLEASURE: 'Pleasure',
    BUSINESS: 'Business'
};

/**
 * @readonly
 * @enum {String}
 */
export const EMQ_VEHICLE_PROPS = {
    PRIMARYUSE: 'PrimaryUse',
    VIN: 'Vin',
    VEHICLENAME: 'VehicleName',
    VEHICLENUMBER: 'VehicleNumber',
    ODOMETERREADING: 'OdometerReading',
    ANNUALMILEAGE: 'AnnualMileage',
    COMMUTINGMILES: 'CommutingMiles',
    DAYSPERWEEKCOMMUTE: 'DaysPerWeekCommute'
};

export const REFERRAL_WHITELIST_CODES = {
    ADVERTISING: 'advertising',
    FAMILY_FRIENDS: 'familyfriends',
    WAWANESA_CUSTOMER: 'wawanesa_customer',
    DEALERSHIP: 'dealership',
    ACCIDENT_CUSTOMER: 'accident_customer',
    ANOTHER_COMPANY: 'another_company',
    CCCU: 'cccu',
    OTHER: 'other'
};

/**
 * @readonly
 * @enum {String}
 */
export const EMQ_RESPONSE_TYPES = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    POLICY_NOT_FOUND: 'POLICY-NOT-FOUND',
    AMQ_NOT_REQUIRED: 'AMQ-NOT-REQUIRED'
};

/**
 * @readonly
 * @enum {String}
 */
export const EMQ_STEPS = {
    VEHICLE_INFO: 'vehicles-info',
    SUCCESS: 'success',
    NOT_AVAILABLE: 'not-available',
    TECHNICAL_ERROR: 'technical-error'
}

/**
 * @readonly
 * @enum {String}
 */
export const PAYMENT_STATUS = {
    DONE: 'DONE',
    BOUND: 'BOUND',
    WAIT: 'WAIT',
    PAID_WITH_EXCEPTION: 'PAIDWITHEXCEPTION'
};

/**
 * @readonly
 * @enum {String}
 */
export const AUTO_SHOPS = {
    MAP: 'map',
    LIST: 'list',
    MAP_NO_SHOPS_FOUND: 'noShopsFound',
    MAP_REFINED_SEARCH: 'refinedSearch',
    MAP_DEFAULT_VIEW: 'defaultView',
    SERVICE_ERROR: 'serviceError'
};

export const CONVENIENCE_FEES = {
    ONEINC: {
        amount: 4.99,
        currency: 'USD'
    },
    FIS: {
        amount: 2.90,
        currency: 'USD'
    }
}