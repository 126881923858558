import React, { useState, useCallback, useEffect } from 'react';
import { MetadataContent } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MapUtil, WMICRemoteLogger, WMICFeatureFlagUtil } from 'wmic-portals-utils-js';
import _ from 'lodash';

import metadata from './WMICAutoShopsServiceErrorComponent.metadata.json5';
import styles from './WMICAutoShopsServiceErrorComponent.module.scss';
import messages from './WMICAutoShopsServiceErrorComponent.messages';

function WMICAutoShopsServiceErrorComponent(props) {
    const { onShowMap, googleMapsApi } = props;

    // const [mountNode, updateMountNode] = useState('');
    const [originAddress, updateOriginAddress] = useState('');
    const [destinationAddress, updateDestinationAddress] = useState('');
    const [distance, updateDistance] = useState(undefined);
    const [distanceCannotBeCalculated, updateDistanceCannotBeCalculated] = useState(undefined);
    // const [googleMapsApi, updateGoogleMapsApi] = useState(undefined);
    const [mapInstance, updateMapInstance] = useState(undefined);
    const [ldFlags, setLdFlags] = useState({});
    const [logger, setLogger] = useState({});
    const translator = useTranslator();
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const history = useHistory();

    useEffect(() => {
    // Only run once when page is init
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getHeader = () => {
        return (
            <div className={styles['header']}>
                <h1>{translator(messages.autoShopsTitle)}</h1>
                <p className="is-size-3">{translator(messages.autoShopsListMapViewSubTitle)}</p>
            </div>
        );
    };
    
    const overrideProps = {
        autoShopsListMapViewHeaderContainer: {
            content: getHeader()
        },
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

WMICAutoShopsServiceErrorComponent.propTypes = {

};

export default WMICAutoShopsServiceErrorComponent;
