import React, { useState, useEffect } from 'react';
import { MetadataForm } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import { FNOLInformationDetailComponent, FNOLConstants, broadcastEvent } from 'wmic-capability-fnol-react';
import { WMICFnolUtilsService } from 'wmic-capability-fnol';
import _ from 'lodash';
import CONSTANTS from 'wmic-portals-utils-js/StringConstants';

import metadata from './FNOLInsuredInformationComponent.metadata.json5';
import styles from '../FNOLStyle.module.scss';
import messages from './FNOLInsuredInformationComponent.messages.js'

function FNOLInsuredInformationComponent(props){
    const { WMICFnolSharedDataService, variant, claimType, state, showOtherPartySection, validateFields } = props;
    const translator = useTranslator();

    const sharedDataPath = variant === CONSTANTS.FNOL.VARIANTS.OTHER_PARTY ? CONSTANTS.FNOL.SHARED_DATA_PATHS.OTHER_PARTY_CLAIMANT : CONSTANTS.FNOL.SHARED_DATA_PATHS.INSURED

    const [insuredInformation, setInsuredInformation] = useState(WMICFnolSharedDataService[sharedDataPath]);
    const [driverOfInsuredVehicle, setDriverOfInsuredVehicle] = useState(WMICFnolSharedDataService.driverOfInsuredVehicle);
    const [driverIsOurInsured, setDriverIsOurInsured] = useState(true)
    const [showDriverOfInsuredVehicle, setShowDriverOfInsuredVehicle] = useState(true);
    const [canShowReportingParty, setCanShowReportingParty] = useState(true);


    const getSectionTitle = () => {
        return variant === CONSTANTS.FNOL.VARIANTS.OTHER_PARTY ? translator(messages.FNOLOtherPartyInformationTitle) : translator(messages.FNOLInsuredInformationTitle)
    }

    const showInsuredQuestion = () => {
        if(variant === CONSTANTS.FNOL.VARIANTS.OTHER_PARTY) return false;
        if (claimType === FNOLConstants.CLAIM_TYPE.AUTO) return true;
        return false;
    }

    const handleDataChange = (value, path, dataPath) => {
        if (dataPath === CONSTANTS.FNOL.VARIANTS.DRIVER) {
            const newData = {...driverOfInsuredVehicle};
            _.set(newData, path, value);
            WMICFnolSharedDataService.driverOfInsuredVehicle = newData;
            setDriverOfInsuredVehicle(newData);
        } else {
            const newData = {...insuredInformation};
            _.set(newData, path, value);
            WMICFnolSharedDataService[sharedDataPath] = newData;
            setInsuredInformation(newData);
        }
    }

    const updateDriverState = () => {
        if (driverOfInsuredVehicle.address.stateProvince) {
            WMICFnolSharedDataService.driverOfInsuredVehicle.address.stateProvince = state;
        }
    };
    
    const updateOtherParty = (data, model, canUpdate) => {
        if (variant === CONSTANTS.FNOL.VARIANTS.OTHER_PARTY && canUpdate) {
            updateSharedModelInfo(data, model);
            WMICFnolSharedDataService[sharedDataPath] = data;
            setInsuredInformation(data);
        }
    };



    const handleYourInfoBroadcast = (model) => {
        if(model.data.reportingParty) {
            const newData = {...WMICFnolSharedDataService[sharedDataPath]};
            if(WMICFnolSharedDataService.insured.sameAsInformer) {
                updateSharedModelInfo(newData, model.data.reportingParty);
                WMICFnolSharedDataService[sharedDataPath] = newData;
                setInsuredInformation(newData);
            } else {
                updateOtherParty(newData, model.data.reportingParty, model.modifyOtherParty);
            }
        };
    }

    const handleReportingPartyChanged = (data) => {
        if(data) {
            setCanShowReportingParty(data.showReportingParty);
            if (!data.showReportingParty) {
                handleDataChange(false, 'sameAsInformer')
            }
            if (data.showOtherPartySection) {
                const newData = {...WMICFnolSharedDataService[sharedDataPath]};
                updateOtherParty(newData, WMICFnolSharedDataService.reportingParty, data.showOtherPartySection);
            }
        }
    }

    const handleSameAsYourInfoChange = (value, newData) => {
        if (value) {
            updateSharedModelInfo(newData, WMICFnolSharedDataService.reportingParty)
            WMICFnolSharedDataService[sharedDataPath] = newData;
            setInsuredInformation(newData);
        }
    }

    const updateSharedModelInfo = (data, model) => {
        _.set(data, 'firstName', model.firstName);
        _.set(data, 'lastName', model.lastName);
        _.set(data, 'phoneNumber', model.phoneNumber);
        _.set(data, 'businessPhoneNumber', model.businessPhoneNumber);
        _.set(data, 'businessPhoneNumberExt', model.businessPhoneNumberExt.replace(/\D/g,''));
        _.set(data, 'cellPhoneNumber', model.cellPhoneNumber);
    }

    const resetDriver = (keepKeys) => {
        const keys = FNOLConstants.resetDriverKeys;
        Object.keys(driverOfInsuredVehicle).forEach((k) => {
            if (keepKeys && keys.includes(k)) {
                return;
            }
            driverOfInsuredVehicle[k] = WMICFnolSharedDataService.driverOfInsuredVehicleBlank[k];
        });
    };

    const setDriverConfiguration = (driverOptionValue, doNotReset) => {
        switch(driverOptionValue){
            case CONSTANTS.FNOL.DRIVER_VALES.OUR_INSURED:
                setDriverIsOurInsured(true);
                setShowDriverOfInsuredVehicle(true);
                break;
            case CONSTANTS.FNOL.DRIVER_VALES.OTHER_PARTY:
                setDriverIsOurInsured(false);
                setShowDriverOfInsuredVehicle(true);
                if (!doNotReset) {
                    const newData = {...driverOfInsuredVehicle};
                    _.set(newData, 'firstName', '')
                    _.set(newData, 'lastName', '')
                    WMICFnolSharedDataService.driverOfInsuredVehicle = newData
                    setDriverOfInsuredVehicle(newData)
                }
                updateDriverState();
                break;
            case CONSTANTS.FNOL.DRIVER_VALES.NO_DRIVER:
                setDriverIsOurInsured(false);
                setShowDriverOfInsuredVehicle(false);
                if (!doNotReset) {
                    resetDriver();
                }
                break;
            case CONSTANTS.FNOL.DRIVER_VALES.UNKNOWN:
                setDriverIsOurInsured(false);
                setShowDriverOfInsuredVehicle(true);
                if (!doNotReset) {
                    const newData = {...driverOfInsuredVehicle};
                    _.set(newData, 'firstName', 'Unknown')
                    _.set(newData, 'lastName', 'Unknown')
                    WMICFnolSharedDataService.driverOfInsuredVehicle = newData
                    setDriverOfInsuredVehicle(newData) 
                }
                break;
            default:
                setDriverIsOurInsured(false);
                setShowDriverOfInsuredVehicle(false);
                break;

        }
    }

    useEffect(() => {
        broadcastEvent.on("reportingPartyChanged", (data) => {
            handleReportingPartyChanged(data);
        });
        broadcastEvent.on("yourInfoUpdated", (data) => {
            handleYourInfoBroadcast(data)
        });

        if(!insuredInformation.driverOption) {
            const newData = {...insuredInformation};
            _.set(newData, 'driverOption', CONSTANTS.FNOL.DRIVER_VALES.OUR_INSURED);
            WMICFnolSharedDataService[sharedDataPath] = newData;
            setInsuredInformation(newData);
        }
        setDriverConfiguration(WMICFnolSharedDataService.insured.driverOption, true);
        if(variant === CONSTANTS.FNOL.VARIANTS.OTHER_PARTY) {
            setDriverIsOurInsured(false);
            setShowDriverOfInsuredVehicle(false);
        }
        return () => {
            broadcastEvent.remove("reportingPartyChanged");
            broadcastEvent.remove("yourInfoUpdated");
        }
    }, [insuredInformation.driverOption])

    
    const showValidationDeep = (param, type) => {
        return validateFields(`${sharedDataPath}.${param}`, type)
    }

    const showFormatValidationErrorDeep = (param, type, optional) => {
        return validateFields(`${CONSTANTS.FNOL.VARIANTS.DRIVER}.${param}`, type, optional)
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            FNOLInformationDetailComponent
        }
    }

    const overrideProps = {
        FNOLInsuredInformationTitle: {
            content: getSectionTitle()
        },
        FNOLInformationDetailComponentInsured: {
            insuredInformation: insuredInformation,
            claimType,
            variant: CONSTANTS.FNOL.VARIANTS.INSURED,
            state,
            handleDataChange,
            showValidationDeep,
            showFormatValidationErrorDeep
        },
        FNOLInformationDetailComponentDriver: {
            insuredInformation: driverOfInsuredVehicle,
            claimType,
            variant: CONSTANTS.FNOL.VARIANTS.DRIVER,
            state,
            driverOptionValue: insuredInformation.driverOption,
            handleDataChange,
            showValidationDeep,
            showFormatValidationErrorDeep
        },
        FNOLInsuredInformationRadioButton: {
            visible: showInsuredQuestion(),
            availableValues: WMICFnolUtilsService.getSelectDriverOfInsuredVehicle()
        },
        FNOLInsuredInformationInsuredVehicle: {
            visible: showDriverOfInsuredVehicle
        },
        FNOLInsuredInformationInsuredVehicleTitle: {
            visible: !driverIsOurInsured
        },
        FNOLInsuredInformationSameYourInformation: {
            visible: canShowReportingParty
        },
        FNOLInsuredInformationContainer: {
            visible: showOtherPartySection
        }
    }

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            data={insuredInformation}
            onDataChange={(value, path) => {
                const newData = {...insuredInformation};
                _.set(newData, path, value);
                WMICFnolSharedDataService[sharedDataPath] = newData;
                setInsuredInformation(newData);
                if (path === 'sameAsInformer') handleSameAsYourInfoChange(value, newData);
                if (path === 'driverOption') setDriverConfiguration(value, false);
            }} />
    );
}

export default FNOLInsuredInformationComponent;
