/* eslint-disable indent */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { useTranslator } from '@jutro/locale';
import { DropdownSelectField } from '@jutro/components';
import styles from '../WMICRemoveVehicleWizard.module.scss';
import messages from '../WMICRemoveVehicleWizard.messages';
import { getVehicleName, getTomorrowDate, getTodayDate } from '../WMICRemoveVehicleWizardUtils';
import { REASONS_FOR_DELETING, CHANGES_TAKE_EFFECT_AFTER_MIDNIGHT, STATE_KEYS } from '../WMICRemoveVehicleWizardConsts';

const WMICRemoveVehicleWizardInitialStep = (props) => {
    const { modelState, modelStateDispatch, focusRemoveVehicleWizardTitle, ...otherProps } = props; 
    const translator = useTranslator();
    const [firstLoad, setFirstLoad] = useState(true); //used so react redraw due to value change would not trigger reset of focus

    useEffect(() => {   
        if (firstLoad) {
            setFirstLoad(false);
            focusRemoveVehicleWizardTitle(translator(messages.removeVehicleAria));
        }
    });

    return (
        <div {...otherProps}>
            <p className="mb-5">
                <div className={styles['wmic-field-label-fit-to-content']} >
                    {WMICRichTextUtil.translateRichText(translator(messages.indicateTheReasonMessage, { vehicleName: getVehicleName(modelState[STATE_KEYS.VEHICLE_TO_REMOVE]) }))}
                </div>
            </p>
        
            <div className="columns">
                <div className="column is-9">
                    <div className="columns">
                        <div className="column is-12">
                            <DropdownSelectField
                                id={`poc_remove_vehicle_reason${modelState[STATE_KEYS.JOB_ID]}`}
                                availableValues={REASONS_FOR_DELETING}
                                alwaysShowPlaceholder={false}
                                className={styles['wmic-field-fit-to-content']}
                                labelClassName={styles['wmic-field-label-fit-to-content']}
                                label={translator(messages.reasonForDeletionLabel)}
                                value={modelState[STATE_KEYS.REASON]}
                                onValueChange={(v) => modelStateDispatch({ 
                                    value: {
                                        [STATE_KEYS.REASON] : v, 
                                        [STATE_KEYS.EFFECTIVE_DATE]: CHANGES_TAKE_EFFECT_AFTER_MIDNIGHT.includes(v) ? getTomorrowDate() : getTodayDate() 
                                    }})}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

WMICRemoveVehicleWizardInitialStep.propTypes = {
    modelState: PropTypes.shape({}).isRequired,
    modelStateDispatch: PropTypes.func.isRequired,
    focusRemoveVehicleWizardTitle: PropTypes.func
};

export default WMICRemoveVehicleWizardInitialStep;
