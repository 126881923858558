/* eslint-disable max-len */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import WMICLink from 'wmic-components-amp-common-react/WMICLink/WMICLink';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { WMICButton } from 'wmic-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import messages from './WMICErrorModal.messages';
import styles from './WMICErrorModal.module.scss';

const WMICErrorModal = (props) => {
    const { onCancelEdit, content, title, onReject, isOpen, transactionNumber } = props;
    const translator = useContext(TranslatorContext);
    const handleClose = () => {
        onReject();
    };

    return (
        <ModalNext 
            isOpen={isOpen}
            onRequestClose={handleClose} 
            shouldCloseOnEsc>
            <ModalHeader
                title={title || translator(messages.wereSorry)}
                contentLayout={{
                    component: 'grid',
                    componentProps: {
                        wrap: true
                    }
                }}
            />
            <ModalBody>
                { content ?
                    <div className={`${styles['wmic-error-body']}`}>{content}</div>
                    :
                    <div>
                        <div className={`${styles['wmic-error-body']}`}>
                            {WMICRichTextUtil.translateRichText(translator(messages.yourPolicyChangeCannotBeAccessedAtThisTimePleaseTryAgain1))}
                            &nbsp;<WMICLink href={`tel:${translator(messages.phone)}`} aria-label={translator(messages.yourPolicyChangeCannotBeAccessedAtThisTimeAriaMessage)}>{translator(messages.yourPolicyChangeCannotBeAccessedAtThisTimePleasePhoneNumber)}</WMICLink>&nbsp;
                            {WMICRichTextUtil.translateRichText(translator(messages.yourPolicyChangeCannotBeAccessedAtThisTimePleaseTryAgain2))}
                        </div>
                        <div className={`${styles['wmic-error-body']}`}>{WMICRichTextUtil.translateRichText(translator(messages.pleaseProvideTransactionNumber))}</div>
                        <div className={`${styles['wmic-error-body']}`}>{WMICRichTextUtil.translateRichText(translator(messages.policyChangeTransactionNumber, { transactionNumber: transactionNumber }))}</div>
                    </div>
                }
            </ModalBody>
            <ModalFooter>
                <WMICButton type="primary" onClick={() => { onCancelEdit(); handleClose();}} >
                    {translator(messages.close)}
                </WMICButton>
            </ModalFooter>
        </ModalNext>
    );
};

WMICErrorModal.propTypes = {
    onCancelEdit: PropTypes.func.isRequired,
    title: PropTypes.string,
    content: PropTypes.element
};

export default WMICErrorModal;