import React, { useState, useEffect, useCallback } from 'react';
import { MetadataForm } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { WMICAnimatedLoaderButton } from 'wmic-components-platform-react';
import { WMICReCaptchaService } from 'wmic-portals-recaptcha-js';

import metadata from './CreatePasswordPage.metadata.json5';
import styles from './CreatePasswordPage.module.scss';
import messages from './CreatePasswordPage.messages';

import EnrollmentService from '../services/EnrollmentService';

import ExpiredRegistrationTokenDialog from '../dialogs/ExpiredRegistrationTokenDialog/ExpiredRegistrationTokenDialog';
import ExpiredPasswordResetTokenDialog from '../dialogs/ExpiredPasswordResetTokenDialog/ExpiredPasswordResetTokenDialog';
import ErrorCodes from '../utils/WMICEnrollmentErrorCodes';

function CreatePasswordPage (props) {
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [activityTimer, setActivityTimer] = useState(undefined);
    const [showPasswordRequirement, setShowPasswordRequirement] = useState(true);
    const [enrollmentStateToken, setEnrollmentStateToken] = useState(undefined);
    const [formErrorCode, setFormErrorCode] = useState(undefined);
    const [hasTokenExpired, setHasTokenExpired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const translator = useTranslator();

    const TIMER_PLACEHOLDER = 300;

    const { match } = props;
    const { activationToken, passwordPagePath } = match.params;

    const isNewUser = passwordPagePath !== 'change-password';

    useEffect(() => {
        EnrollmentService.activateToken(activationToken)
            .then(resp => {
                if(!resp.ok) {
                    if(resp.status === 401) {
                        setHasTokenExpired(true);
                        return;
                    }
                    throw new Error('Unable to parse email token');
                }
                return resp.json();
            })
            .then(response => {
                const expiryDateTime = new Date(response.expiresAt);
                const expirySecondsFromNow = (expiryDateTime.getTime() - new Date().getTime()) / 1000;

                setEnrollmentStateToken(response.stateToken);
                setActivityTimer(expirySecondsFromNow)
            })
            .catch(() => {
                setHasTokenExpired(true);
            });
    }, []);

    useEffect(() => {
        const timeoutHandler = setTimeout(() => {
            if(activityTimer <= 1) {
                clearTimeout(timeoutHandler);
                setHasTokenExpired(true);
            }
            setActivityTimer(prev => prev - 1);
        }, 1000);

        return () => clearTimeout(timeoutHandler);
    }, [activityTimer])

    const formatSecondsToTimerString = (seconds) => {
        const mins = Math.floor(seconds/60);
        const secs = Math.floor(seconds - (mins * 60));
        return `${mins}:${String(secs).padStart(2,'0')}`;
    };
    const formattedTimerString = formatSecondsToTimerString(activityTimer >= 0 ? activityTimer : TIMER_PLACEHOLDER);

    const handleValueChange = (value, path) => {
        const newFormData = { ...formData };
        newFormData[path] = value;
        setFormData(newFormData);
    };

    const onValidationChange = (newIsValid) => {
        setIsValid(newIsValid);
    };

    const customValidation = useCallback((id, path, value) => {
        if(id === 'confirmPassword' && formData.password !== value) {
            return [translator(messages.passwordConfirmValidation)]
        }
    }, [formData]);

    const saveForm = useCallback(async() => {
        if(!isValid) {
            setSubmitted(true);
            return;
        }
        setIsLoading(true);
        const token = await WMICReCaptchaService.fetchReCaptchaToken('submit', true);

        const createPasswordResponse = await EnrollmentService.createPassword({
            stateToken: enrollmentStateToken, newPassword: formData.password
        }, token);

        if(createPasswordResponse.ok) {
            const response = await createPasswordResponse.json();
            if(isNewUser) {
                EnrollmentService.redirectAmpUserToConsentForm(response.sessionToken);
            } else {
                EnrollmentService.redirectAmpUserToLandingPage(response.sessionToken);
            }
        } else {
            // temp fix till fix in integrations (MULE-7556)
            let error;
            try {
                error = await createPasswordResponse.json();
            } catch(ex) {
                // parse through invalid json obj to check for okta error codes and construct a valid object for error processor.
                if(ex.message?.includes(ErrorCodes.DOES_NOT_MEET_MIN_REQUIREMENT)) {
                    error = { errorOkta: ErrorCodes.DOES_NOT_MEET_MIN_REQUIREMENT };
                }
            }
            setShowPasswordRequirement(true);
            setFormErrorCode(error.errorOkta || error.code || ErrorCodes.GENERIC_ERROR);
            setIsLoading(false);
        }
    }, [isValid]);

    const togglePasswordRequirements = (e) => {
        e.preventDefault();
        setShowPasswordRequirement((prev) => !prev);
    };

    const toggleRequirementMessage = useCallback(() => {
        const msg = showPasswordRequirement ? messages.passwordDescriptionShow : messages.passwordDescriptionHide;
        return (
            <FormattedMessage
                {...messages.passwordDescription}
                values={{
                    passwordRequirementLabel:  <a href="#" aria-expanded={showPasswordRequirement} aria-controls="passwordRequirementItems" onClick={togglePasswordRequirements}>{translator(msg)}</a>
                }}
            />
        );
    }, [showPasswordRequirement]);

    const serverResponseError =  useCallback(() => {
        let msg;
        switch(formErrorCode) {
            case ErrorCodes.DOES_NOT_MEET_MIN_REQUIREMENT:
                msg = messages.passwordReuseErrorMessage;
                break;
            case ErrorCodes.GENERIC_ERROR:
                msg = messages.genericErrorMessage;
                break;
            default:
                msg = undefined;
        }

        return {
            formNotification: {
                message: translator(msg),
                visible: !!msg
            }
        };
    }, [formErrorCode, translator]);

    const overrideProps = {
        '@all': (id, path) => {
            if(['confirmPassword', 'password'].includes(id)) {
                return {
                    messageProps: {
                        requiredField: `${translator(messages[`${id}`])} field is required`
                    }               
                }
            }
        },
        pageTitle: {
            content: isNewUser ? translator(messages.createPassword) : translator(messages.changePassword)
        },
        passwordRequirement: {
            content: toggleRequirementMessage()
        },
        pageTimout: {
            content: translator(messages.createPasswordTimeout, {formattedTimerString})
        },
        passwordRequirementItems: {
            className: showPasswordRequirement ? '' : 'is-hidden'
        },
        expirationDialog: {
            visible: hasTokenExpired
        },
        siteBody: {
            visible: !hasTokenExpired
        },
        submit: {
            isAnimated: isLoading
        },
        ...serverResponseError(),
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            saveForm: saveForm
        },
        resolveComponentMap: {
            expirationDialog: isNewUser ? ExpiredRegistrationTokenDialog : ExpiredPasswordResetTokenDialog,
            WMICButton: WMICAnimatedLoaderButton
        }
    };

    return (
        <MetadataForm
            uiProps={metadata.pageContent}
            data={formData}
            overrideProps={overrideProps}
            onDataChange={handleValueChange}
            onValidationChange={onValidationChange}
            resolveValidation={customValidation}
            showErrors={submitted}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
             />
    );
}

CreatePasswordPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            activationToken: PropTypes.string,
            passwordPagePath: PropTypes.string
        })
    }),
    history: PropTypes.shape({})
};

export default withRouter(CreatePasswordPage);
