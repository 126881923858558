import React, {
    useContext, useCallback, useState, useMemo, useEffect
} from 'react';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withAuthenticationContext } from 'wmic-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { messages as paymentMessages } from 'gw-capability-policychange-common-react';
import getLobSpecificKeyValue from '../../HOProductCodeUtil';
import metadata from './MortgageePage.metadata.json5';
import styles from './MortgageePage.module.scss';
import messages from './MortgageePage.messages';

function MortgageePage(props) {
    const modalApi = useModal();
    const localeService = ServiceManager.getService('locale-service');
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const { wizardData, updateWizardData, authHeader } = props;
    const { submissionVM } = wizardData;
    const {
        initialValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation('MortgageePage');
    const [showMortgagee, setShowMortgagee] = useState(false);
    const [cancelMortgageeShow, setCancelMortgageeShow] = useState(false);
    const [addMortgageeDisable, setAddMortgageeDisable] = useState(true);
    const [mortgageeToBeUpdated, setMortgageeToBeUpdated] = useState(null);

    const mortgagee = useMemo(() => ({
        type: getLobSpecificKeyValue('MORTGAGEE', submissionVM.value.baseData.productCode),
        contractNumber: '',
        description: 'Mortgage',
        certificateRequired: false,
        effectiveDate: new Date().toISOString(),
        policyAdditionalInterest: {
            contactName: '',
            subtype: 'Company',
            primaryAddress: {
                addressLine1: '',
                city: '',
                state: '',
                postalCode: '',
                country: localeService.getDefaultCountryCode(),
                addressType: 'business'
            }
        }
    }), [localeService, submissionVM]);

    const isModelValid = useCallback(() => {
        return submissionVM.aspects
            ? submissionVM.aspects.valid && submissionVM.aspects.subtreeValid : false;
    }, [submissionVM.aspects]);

    useEffect(() => {
        registerComponentValidation(isModelValid);
    }, [registerComponentValidation, isModelValid]);

    const addMortgagee = useCallback(() => {
        const clonedCoverable = _.cloneDeep(submissionVM);
        const coverablesVM = viewModelService.create(
            clonedCoverable,
            'pc',
            'edge.capabilities.policyjob.lob.homeowners.coverables.dto.HoCoverablesDTO'
        );
        const homeAdditonalInterests = _.get(
            coverablesVM.value,
            'lobData.homeowners.coverables.additionalInterests'
        );
        const { _xCenter, _dtoName } = coverablesVM;
        homeAdditonalInterests.value = homeAdditonalInterests.value || [];
        const additionalVM = viewModelService.create(mortgagee, _xCenter, _dtoName);
        homeAdditonalInterests.value.push(additionalVM.value);
        setShowMortgagee(
            `lobData.homeowners.coverables.additionalInterests.children[${
                homeAdditonalInterests.value.length - 1
            }]`
        );
        setCancelMortgageeShow(
            `lobData.homeowners.coverables.additionalInterests.children[${
                homeAdditonalInterests.value.length - 1
            }]`
        );
        setAddMortgageeDisable(true);
        updateWizardData(wizardData);
    }, [submissionVM, viewModelService, mortgagee, updateWizardData, wizardData]);

    const removeMortgagee = useCallback(
        ({ path: additionalInterestPath }) => {
            const additionalInterestListPath = 'lobData.homeowners.coverables.additionalInterests.value';
            const additionalInterestToRemove = _.get(
                submissionVM,
                `${additionalInterestPath}.value`
            );
            const additionalInterestList = _.get(submissionVM, additionalInterestListPath);

            modalApi.showConfirm({
                title: messages.RemoveAdditionalInterest,
                message: messages.AddIntRemovalMsg,
                status: 'warning',
                icon: 'mi-error-outline'
            }).then(
                (results) => {
                    if (results === 'cancel') {
                        return _.noop();
                    }
                    const newAdditionalInterestList = additionalInterestList.filter(
                        (additionalInterest, index) => {
                            // eslint-disable-next-line
                            const additionalInterestListPathWithoutValue = additionalInterestListPath.replace(
                                '.value',
                                ''
                            );
                            const currentAdditionalInterestPath = `${additionalInterestListPathWithoutValue}.children[${index}]`;
                            // eslint-disable-next-line
                            const isCorrectPath = currentAdditionalInterestPath === additionalInterestPath;

                            return !(
                                isCorrectPath
                                && _.isEqual(additionalInterest, additionalInterestToRemove)
                            );
                        }
                    );

                    _.set(submissionVM, additionalInterestListPath, newAdditionalInterestList);
                    updateWizardData(wizardData);
                    setCancelMortgageeShow(false);
                    setAddMortgageeDisable(true);
                    return true;
                }, _.noop
            );
        },
        [submissionVM, updateWizardData, wizardData]
    );
    const editMortgagee = useCallback(({ path }) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        const pathToModify = `${path}.value`;

        setMortgageeToBeUpdated({
            path: pathToModify,
            value: _.get(newSubmissionVM, pathToModify)
        });
        setShowMortgagee(path);
        setCancelMortgageeShow(false);
        setAddMortgageeDisable(true);
    }, [submissionVM, viewModelService]);

    const hideMortgagee = useCallback(() => {
        _.set(
            submissionVM,
            mortgageeToBeUpdated.path,
            mortgageeToBeUpdated.value
        );
        setShowMortgagee();
    }, [submissionVM, mortgageeToBeUpdated]);

    const writeValue = useCallback(
        (value, path) => {
            _.set(submissionVM, path, value);
            setAddMortgageeDisable(false);
            updateWizardData(wizardData);
        },
        [submissionVM, updateWizardData, wizardData]
    );

    const onNext = useCallback(async () => {
        submissionVM.value = await EndorsementService.saveEndorsement(
            [submissionVM.value],
            authHeader
        );
        return wizardData;
    }, [EndorsementService, authHeader, submissionVM, wizardData]);

    const generateMortgageeOverrides = useCallback(() => {
        const additionalInterests = _.get(
            submissionVM,
            'lobData.homeowners.coverables.additionalInterests.value'
        );
        const overrides = {};
        if (!_.isEmpty(additionalInterests)) {
            additionalInterests.forEach((additionalInterest, i) => {
                const subtype = _.get(additionalInterest, 'policyAdditionalInterest.subtype') || 'Company';
                const contactName = _.get(additionalInterest, 'policyAdditionalInterest.contactName')
                    || _.get(additionalInterest, 'policyAdditionalInterest.displayName');
                const currentAdditionalInterestEdit = `lobData.homeowners.coverables.additionalInterests.children[${i}]`;
                overrides[`hoCompanyContainer${i}`] = {
                    visible: subtype === 'Company'
                };
                overrides[`hoPersonalContainer${i}`] = {
                    visible: subtype === 'Person'
                };
                overrides[`personCompanyBinarySwitch${i}`] = {
                    defaultValue: _.get(additionalInterests[0], 'policyAdditionalInterest.subtype'),
                    visible: _.isEmpty(subtype)
                };
                overrides[`hoPolicyChangeMortgagee${i}`] = {
                    content:
                        contactName
                        || translator({
                            id:
                                'endorsement.ho.directives.templates.ho-additional-interests-edit.Add Mortgagee',
                            defaultMessage: 'Add Mortgagee'
                        })
                };
                overrides[`hoPolicyChangeAddMortgageeFormContainer${i}`] = {
                    visible: showMortgagee === currentAdditionalInterestEdit
                };
                overrides[`hoPolicyChangeDeleteMortgagee${i}`] = {
                    disabled:
                        showMortgagee === currentAdditionalInterestEdit
                        && currentAdditionalInterestEdit !== cancelMortgageeShow
                };
                overrides[`hoPolicyChangeEditMortgagee${i}`] = {
                    disabled: showMortgagee === currentAdditionalInterestEdit,
                    visible: currentAdditionalInterestEdit !== cancelMortgageeShow
                };
                overrides[`cancelBtnId${i}`] = {
                    visible: currentAdditionalInterestEdit !== cancelMortgageeShow
                };
            });
        }
        return overrides;
    }, [cancelMortgageeShow, showMortgagee, submissionVM, translator]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'left',
            phoneWide: {
                labelPosition: 'top'
            }
        },
        hoPolicyChangeAddMortgageeBtn: {
            disabled: cancelMortgageeShow && addMortgageeDisable
        },
        ...generateMortgageeOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddMortgagee: addMortgagee,
            onRemoveMortgagee: removeMortgagee,
            onEditMortgagee: editMortgagee,
            onHideMortgagee: hideMortgagee
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            cancelLabel={translator(appConfig.persona === 'policyholder' ? paymentMessages.cancelAllChanges : paymentMessages.cancel)}
            showPrevious
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

MortgageePage.propTypes = wizardProps;
export default withAuthenticationContext(MortgageePage);
