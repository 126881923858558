import React, { useState, useCallback, useMemo } from 'react';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';

import { translateRichText } from '../../utils/WMICRichTextUtil';
import { confirmationPage } from '../../utils/WMICEnrollmentConstants';
import { FurtherAssistanceComponent } from '../..';
import EnrollmentService from '../../services/EnrollmentService';

import metadata from './CheckEmailRegistrationDialog.metadata.json5';
import messages from './CheckEmailRegistrationDialog.messages';

function CheckEmailRegistrationDialog(props) {
    const [hasResentEmail, setHasResentEmail] = useState(false);
    const { emailAddress, textToReadCallback } = props;
    const translator = useTranslator();
    const modalApi = useModal();

    const resendEmailHandler = useCallback(async(e) => {
        e.preventDefault();

        if(!hasResentEmail) {
            const fetchResponse =  await EnrollmentService.resendRegistrationEmail(emailAddress);
            modalApi.showConfirm({
                title: messages.dialogCheckResendEmailConfirmModalTitle,
                message: translator(messages.dialogCheckResendEmailConfirmModalMesssage, { email: emailAddress }),
                status: 'success',
                icon: 'mi-success-outline',
                confirmButtonText: messages.dialogCheckResendEmailConfirmModalButtonText,
                cancelButtonText: ''
            });
            setHasResentEmail(true);
        }
    }, [emailAddress]);

    const resentEmailLink= useCallback(() => {
        return (
            <FormattedMessage
                {...messages.dialogCheckResendEmail}
                values={{
                    resendEmail: <a href="#" onClick={resendEmailHandler}>{translator(messages.dialogCheckResendEmailLink)}</a>
                }}
            />
        );
    }, [translator]);

    const loginLink = useCallback(() => {
        return (
            <FormattedMessage
                {...messages.loginLink}
                values={{
                    loginLink: <Link to='/login'>{translator(messages.loginNow)}</Link>
                }}
            />
        );
    }, [translator]);

    const formattedEmailDescription = useCallback((omitFormatting) => {
        let checkEmailDescription;
        switch(props.fromPage) {
            case confirmationPage.MIGRATION:
                checkEmailDescription = omitFormatting ? messages.dialogCheckEmailDescriptionMigrationUnformatted : messages.dialogCheckEmailDescriptionMigration;
                break;
            case confirmationPage.RESET_PASSWORD:
                checkEmailDescription = omitFormatting ? messages.dialogCheckEmailDescriptionResetUnformatted : messages.dialogCheckEmailDescriptionReset;
                break;
            default:
                checkEmailDescription = omitFormatting ? messages.dialogCheckEmailDescriptionUnformatted : messages.dialogCheckEmailDescription;
                break;
        }

        return checkEmailDescription;
    }, [translator]);

    const checkEmailTitle = useMemo(() => {
        let checkEmailDescription;
        if(props.fromPage == confirmationPage.MIGRATION) {
            checkEmailDescription = messages.dialogMigrationCheckEmail;
        } else {
            checkEmailDescription = messages.dialogCheckEmail;
        }
        return hasResentEmail ? messages.dialogRecheckEmail : checkEmailDescription;
    }, [props.fromPage, hasResentEmail]);

    const overrideProps = {
        migrationNotice: {
            visible:  props.fromPage == confirmationPage.MIGRATION,
            content: translateRichText(translator(messages.dialogCheckEmailMigrationNotice))
        },
        pageDescription: {
            content: translateRichText(translator( formattedEmailDescription(false), { email: emailAddress }))
        },
        pageTitle: {
            content: translator(checkEmailTitle)
        },
        resendingEmail: {
            content: resentEmailLink()
        },
        loginLink: {
            visible: props.fromPage != confirmationPage.MIGRATION,
            content: loginLink()
        }
    };

    const resolvers = {
        resolveComponentMap: {
            furtherassistancecomponent: FurtherAssistanceComponent,
        }
    };

    const getTextToReadViaScreenReader = () => {
        const desc = translator( formattedEmailDescription(true), { email: emailAddress });
        const resendLink = translator( messages.dialogCheckResendEmailUnformatted);
        const furtherAssistanceText = translator( messages.furtherAssistanceTextUnformatted );
        return `${translator(checkEmailTitle)}. ${desc} ${resendLink} ${furtherAssistanceText}`;
    };

    // Since this is a dialog box, it gets inserted into several different pages. This way of reading text via the
    // screen reader allows for us to build the pages flexibly without worrying about which DOM elements are visible / rendered
    // when the page loads. The only requirement is that the callback function is hooked up on the parent page.
    if (textToReadCallback) {
        textToReadCallback(getTextToReadViaScreenReader());
    }

    return (
        <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

CheckEmailRegistrationDialog.propTypes = {
    emailAddress: PropTypes.string,
    fromPage: PropTypes.string,
    textToReadCallback: PropTypes.func
};

CheckEmailRegistrationDialog.defaultProps = {
    emailAddress: '',
    fromPage: confirmationPage.REGISTRATION
};

export default CheckEmailRegistrationDialog;
