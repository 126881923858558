import { defineMessages } from 'react-intl';

export default defineMessages({
    warningMessageTitle: {
        id: 'wmic.policy-change.component.change-type-selector.You have pending policy changes',
        defaultMessage: 'You have pending policy changes'
    },
    warningMessageBody: {
        id: 'wmic.policy-change.component.change-type-selector.Weve detected you have previously submitted policy changes',
        defaultMessage: 'We\'ve detected you have previously submitted policy changes. Please note some changes can take a few business days to complete and must be processed manually by an agent.'
    },
    selectAPolicyChange: {
        id: 'wmic.policy-change.component.change-type-selector.Select a Policy Change',
        defaultMessage: 'Select a Policy Change'
    },
    selectAPolicyChangeAria: {
        id: 'wmic.policy-change.component.change-type-selector.Select a Policy Change Aria',
        defaultMessage: 'Select a Policy Change from the list of items below'
    },
    policyChangeSelectTrackButtonIdentifier: {
        id: 'wmic.policy-change.component.change-type-selector.tracking.Policy Change Select',
        defaultMessage: 'Policy Change - Select - {selectedChange}'
    },
    policyChangeSelectContentAria: {
        id: 'wmic.policy-change.component.change-type-selector.tracking.Policy Change Select Content Aria',
        defaultMessage: 'Make a policy change selection from the items below, then press Continue to proceed'
    },
    changeAddress: {
        id: 'wmic.policy-change.component.change-type-selector.Change of Address',
        defaultMessage: 'Change of Address'
    },
    paymentPlan: {
        id: 'wmic.policy-change.component.change-type-selector.Change Payment Plan',
        defaultMessage: 'Change Payment Plan'
    },
    financeLeasing: {
        id: 'wmic.policy-change.component.change-type-selector.Update Finance or Leasing Company',
        defaultMessage: 'Update Finance or Leasing Company'
    },
    deleteVehicle: {
        id: 'wmic.policy-change.component.change-type-selector.Remove Vehicle',
        defaultMessage: 'Remove Vehicle'
    },
    roadsideAssistance: {
        id: 'wmic.policy-change.component.change-type-selector.Add or Remove Roadside Assistance',
        defaultMessage: 'Add or Remove Roadside Assistance'
    },
    adjustCoverages: {
        id: 'wmic.policy-change.component.change-type-selector.Adjust Coverages or Deductibles',
        defaultMessage: 'Adjust Coverages or Deductibles'
    },
    otherChanges: {
        id: 'wmic.policy-change.component.change-type-selector.Id like to change or update something else',
        defaultMessage: 'I\'d like to change or update something else'
    },
    designatedPerson: {
        id: 'wmic.policy-change.component.change-type-selector.Add or Update Recipient for Notice of Cancellation or Nonrenewal',
        defaultMessage: 'Add or Update Recipient for Notice of Cancellation or Nonrenewal'
    },
    cancel: {
        id: 'wmic.policy-change.component.change-type-selector.Cancel',
        defaultMessage: 'Cancel'
    },
    continue: {
        id: 'wmic.policy-change.component.change-type-selector.Continue',
        defaultMessage: 'Continue'
    },
    callUs: {
        id: 'wmic.policy-change.component.change-type-selector.Please call us',
        defaultMessage: 'Please call us'
    },
    changesUnavailableOnline: {
        id: 'wmic.policy-change.component.change-type-selector.Changes Unavailable Online',
        defaultMessage: 'We\'re sorry the changes you need aren\'t available online. Our agents are ready to help, call us at &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.'
    },
    close: {
        id: 'wmic.policy-change.component.change-type-selector.Close',
        defaultMessage: 'Close'
    },
    validation: {
        id: 'wmic.policy-change.component.change-type-selector.Validation',
        defaultMessage: 'You must make a selection from the choices above'
    },
    legalDisclaimerCA: {
        id: 'wmic.policy-change.component.change-type-selector.legalDisclaimerCA',
        defaultMessage: '&lt;span&gt;California law requires us to inform you of the following:&lt;br/&gt;Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.&lt;/span&gt;'
    }
});
