import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useHistory } from 'react-router-dom';
import { getConfigValue } from '@jutro/config';

import metadata from './WMICLogoutPage.metadata.json5';

function WMICLogoutPage() {
    const { authenticated, logout } = useAuthentication();
    const history = useHistory();

    useEffect(() => {
        if (authenticated) {
            logout();
        } else {
            history.push('/logout-confirmation');
        }
    }, [])

    const overrideProps = {
        logoutContainer: {
        }
    };

    const resolvers = {
        resolveComponentMap: {}
    };

    WMICLogoutPage.propTypes = {
        match: PropTypes.shape({}).isRequired
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default withRouter(WMICLogoutPage);
