import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Link } from '@jutro/components';
import { useTranslator, IntlContext } from '@jutro/locale';
import { Flex, FlexItem } from "@jutro/layout";
import { CONSTANTS } from 'wmic-portals-utils-js';
import { WMICPolicyChangeStateService } from 'wmic-capability-policychange';
import { WMICButton } from 'wmic-components-platform-react';
import classNames from 'classnames';

import messages from './WMICPolicyChangeReviewVehicleEdit.messages';
import { STEPS } from '../../WMICRemoveVehiclePA/WMICRemoveVehicleWizard/WMICRemoveVehicleWizardConsts';
import styles from './WMICPolicyChangeReviewVehicleEdit.module.scss';

const WMICPolicyChangeReviewVehicleEdit = (props) => {
    const { removedVehicle, remainingVehicles, jobId, policy } = props;
    const termNumber = policy.termNumber_WMIC;
    const policyNumber = policy.policyNumber;

    const translator = useTranslator();
    const history = useHistory();
    const intl = useContext(IntlContext);

    const handleGoToDrivers = useCallback(() => handleEdit(STEPS.ASSIGN_PRIMARY_DRIVERS), [handleEdit]);

    const handleGoToVehicles = useCallback(() => handleEdit(STEPS.VEHICLE_USING), [handleEdit]);

    const handleEdit = useCallback( (step) => {
        WMICPolicyChangeStateService.addState({ editDeleteVehicle: { step, jobId, vehicleNumber: remainingVehicles[0]?.vehicleNumber?.toString() }});
        history.push(`/account-policy-change/${policyNumber}/${termNumber}/auto/${CONSTANTS.POLICY_CHANGE_TYPE.DELETE_VEHICLE}`);
    }, [history, jobId, policyNumber, remainingVehicles, termNumber]);

    const getRemovedVehicleCard = (vehicle) => (
        <div className="ww-card ww-card-flat ww-card-alert ww-card__content mb-6">
            <h4>{vehicle.displayName}</h4>
        </div>
    );

    const formattedNumber = (amount) => intl.formatNumber(amount);

    const getRemainingVehicleCard = (vehicle) => (
        <div key={vehicle?.vehicleNumber} className="ww-card ww-card-flat ww-card-success ww-card__content ww-card-footer" role='listitem'>
            <h4 className="hasTextWeightBold">{vehicle.displayName}</h4>

            <Flex gap='none' className={styles.wmicFlexContainer}>
                <div className={classNames(styles.remainingVehicleCol)}>
                    <FlexItem grow={1} className={styles.wmicBox}>
                        <div className={styles.wmicBoxContent}>
                            <p className="hasTextWeightBold">{translator(messages.use)}</p>
                            <p>{vehicle.primaryUse}</p>
                        </div>  
                    </FlexItem>
                </div>
                <div className={classNames(styles.remainingVehicleCol)}>
                    <FlexItem grow={1} className={styles.wmicBox}>
                        <div className={styles.wmicBoxContent}>
                            <p className="hasTextWeightBold">{translator(messages.mileage)}</p>
                            <p>{formattedNumber(vehicle.annualMileage)}</p>
                        </div>  
                    </FlexItem>
                </div>
                <div className={classNames(styles.remainingVehicleCol)}>
                    <FlexItem grow={1} className={styles.wmicBox}>
                        <div className={styles.wmicBoxContent}>
                            <p className="hasTextWeightBold">{translator(messages.primaryDriver)}</p>
                            <p data-di-mask>{vehicle.primaryDriverName}</p>
                        </div>
                    </FlexItem>
                </div>
                {vehicle.occasionalDriver && (
                    <div className={classNames(styles.remainingVehicleCol)}>
                        <FlexItem grow={1} className={styles.wmicBox}>
                            <div className={styles.wmicBoxContent}>
                                <p className="hasTextWeightBold">{translator(messages.occasionalDriver)}</p>
                                <p data-di-mask>{vehicle.occasionalDriversNames}</p>
                            </div>
                        </FlexItem>
                    </div>
                )}
            </Flex>
        </div>
    );

    return (
        <div>
            <div className="pt-0 pb-0" >
                <Flex gap='none' className={classNames(styles.wmicFlexContainer, styles.remainingVehiclesButtonsContainer)}>
                    <div className={classNames(styles.wmicFlexColumn, styles.wmicRemovedVehiclesContainer)}>
                        <FlexItem grow={1} className={styles.wmicBox}>
                            <div>
                                <h3 className={styles.wmicSubTitle}>{translator(messages.removedVehicle)}</h3>
                            </div>  
                        </FlexItem>
                    </div>
                </Flex>
                {getRemovedVehicleCard(removedVehicle)}
            </div>
            <div className="pt-0 pb-0" >
                <Flex gap='none' className={classNames(styles.wmicFlexContainer, styles.remainingVehiclesButtonsContainer)}>
                    <div className={styles.wmicFlexColumn}>
                        <FlexItem grow={1} className={styles.wmicBox}>
                            <div>
                                <h3 className={styles.wmicSubTitle}>{translator(messages.vehiclesRemainingOnPolicy)}</h3>
                            </div>  
                        </FlexItem>
                    </div>
                </Flex>

                <div role='list'>{remainingVehicles.map(getRemainingVehicleCard)}</div>

                <Flex gap='none' className={classNames(styles.wmicFlexContainer, styles.remainingVehiclesButtonsContainer)}>
                    <div className={styles.wmicFlexColumn}>
                        <FlexItem grow={1} className={[styles.wmicButtons, styles.wmicFlexRight]}>
                            {policy.lobs?.personalAuto?.driverDTOs.length > 1 && ([
                                <WMICButton type="outlined" onClick={handleGoToDrivers} className={styles.wmicTabletFullWidth}>{translator(messages.editDrivers)}</WMICButton>
                            ])}
                            <WMICButton type="outlined" onClick={handleGoToVehicles} className={styles.wmicTabletFullWidth}>{translator(messages.editVehicles)}</WMICButton>
                        </FlexItem>
                    </div>
                </Flex>
            </div>
        </div>
    );
};

WMICPolicyChangeReviewVehicleEdit.propTypes = {
    removedVehicle: PropTypes.shape({
        displayName: PropTypes.string.isRequired
    }),
    remainingVehicles: PropTypes.arrayOf(PropTypes.shape({
        displayName: PropTypes.string.isRequired
    })),
    jobId: PropTypes.string,
    policyNumber: PropTypes.string,
    termNumber: PropTypes.number
};

export default WMICPolicyChangeReviewVehicleEdit;