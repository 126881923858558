import React, { useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useAuthentication } from 'wmic-digital-auth-react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICDesignatedPersonDetailsForm } from 'gw-capability-policychange-common-react';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { WMICModal } from 'gw-capability-policy-react';
import { CONSTANTS } from 'wmic-portals-utils-js';
import {
    WMICControllerUtil,
    WMICPolicyChangeUtilsService,
    WMICPolicyChangeStateService,
    DesignatePersonModel,
    DesignateAdditionalPersonModel
} from 'wmic-capability-policychange';
import { WMICButton } from 'wmic-components-platform-react';
import metadata from './WMICChangeDesignatedPersonAddContactComponent.metadata.json5';
import messages from './WMICChangeDesignatedPersonAddContactComponent.messages';
import styles from './WMICChangeDesignatedPersonAddContactComponent.module.scss';

function WMICChangeDesignatedPersonAddContactComponent(props) {
    const modalApi = useModal();
    const {
        policyVM,
        match,
        setActiveStep,
        designatedPersonData,
        handleChangeLoading,
        isLoading
    } = props;

    const translator = useTranslator();
    const history = useHistory();
    const { authHeader } = useAuthentication();
    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');
    const policyType = _.get(match, 'params.policyType');
    const accountHolder = _.get(policyVM, 'currentPeriod.contacts')
        .find((contact) => contact.contact.accountHolder === true);
    const hasDesignatedPerson = designatedPersonData.contact !== undefined;
    const { street, unit } = hasDesignatedPerson ? WMICPolicyChangeUtilsService.splitStreetUnit(designatedPersonData.contact.primaryAddress.addressLine1) : { street: '', unit: '' };
    const originalPersonData = {
        name: hasDesignatedPerson ? designatedPersonData.contact.displayName : '',
        street: street,
        unit: unit,
        city: hasDesignatedPerson ? designatedPersonData.contact.primaryAddress.city : '',
        state: hasDesignatedPerson ? designatedPersonData.contact.primaryAddress.state : '',
        zip: hasDesignatedPerson ? designatedPersonData.contact.primaryAddress.postalCode : '',
        permission: undefined
    };
    const [data, setData] = useState(originalPersonData);
    const [isValid, setValidity] = useState(true);

    const cleanUp = () => {
        // Remove current addressChangeType and addressToUpdate
        WMICPolicyChangeStateService.clearState();
    };

    const hasModelChanged = () => {
        if (!data || !originalPersonData) return false;
        return Object.entries(data).some(([k, v]) => v !== designatedPersonData[k]);
    };

    const handleCancel = () => {
        if (hasModelChanged()) {
            modalApi.showModal(
                <WMICModal
                    id="designatedCancelModal"
                    modalHeader={translator(messages.cancellationModalTitle)}
                    modalBody={translator(messages.cancellationModalBody)}
                    onConfirmCallback={() => {
                        cleanUp();
                        setActiveStep(CONSTANTS.POLICY_CHANGE_TYPE.DESIGNATED_PERSON);
                    }}
                    onCancelCallback={() => _.noop()}
                    confirmButtonText={translator(messages.cancellationModalConfirm)}
                    cancelButtonText={translator(messages.cancellationModalCancel)}
                />
            );
        } else {
            cleanUp();
            setActiveStep(CONSTANTS.POLICY_CHANGE_TYPE.DESIGNATED_PERSON);
        }
    };

    const handleSave = () => {
        const changeType = hasDesignatedPerson ? 'CHANGE' : 'ADD';
        const designatePerson = new DesignatePersonModel(data);
        const designateAdditionalPersonModel = new DesignateAdditionalPersonModel({ changeType, designatePerson });

        modalApi.showModal(
            <WMICModal
                id="designatedSubmitModal"
                modalHeader={translator(messages.submissionModalConfirm)}
                modalBody={translator(messages.submissionModalBody)}
                onConfirmCallback={() => {
                    handleChangeLoading(true);
                    WMICPolicyChangeStateService.addChangeRequest('designated-person', 'change', {
                        changeType: `designated-person-${hasDesignatedPerson ? 'change' : 'add'}`,
                        objectType: 'DesignateAdditionalPersonModel',
                        changeModel: designateAdditionalPersonModel
                    });
                    WMICControllerUtil.handleSubmit({
                        history: history,
                        policyNumber: policyNumber,
                        termNumber: termNumber,
                        policyData: policyVM,
                        policyType: policyType,
                        accountInfo: accountHolder,
                        authHeader: authHeader
                    },
                    translator);
                }}
                onCancelCallback={() => _.noop()}
                confirmButtonText={translator(messages.submissionModalSubmit)}
                cancelButtonText={translator(messages.submissionModalCancel)}
            />
        );
    };

    const getButtonsPanelContent = () => {
        return (
            <div className="ww-btn-group-gap-3">
                <WMICButton type="outlined" onClick={handleCancel}>
                    { translator(messages.cancel) }
                </WMICButton>
                <WMICButton type="primary" onClick={() => { handleSave(); }} disabled={!isValid}>
                    { translator(messages.continue) }
                </WMICButton>
            </div>
        );
    };

    const overrideProps = {
        wmicDesignatedPersonDetailsForm: {
            contactDetails: data,
            setAddress: setData,
            setValidity: setValidity
        },

        designatedPersonButtonsPanelContent: {
            content: getButtonsPanelContent()
        }
    };

    const resolvers = {
        resolveComponentMap: {
            wmicDesignatedPersonDetailsForm: WMICDesignatedPersonDetailsForm
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={designatedPersonData}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICChangeDesignatedPersonAddContactComponent.propTypes = {
    designatedPersonData: PropTypes.shape({
        contactRole: PropTypes.string,
        contact: PropTypes.shape({
            displayName: PropTypes.string,
            primaryAddress: PropTypes.shape({
                addressLine1: PropTypes.string,
                city: PropTypes.string,
                state: PropTypes.string,
                postalCode: PropTypes.string,
                country: PropTypes.string
            })
        })
    }),
    policyVM: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired
};


WMICChangeDesignatedPersonAddContactComponent.defaultProps = {
    designatedPersonData: {}
};

export default WMICChangeDesignatedPersonAddContactComponent;