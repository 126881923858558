import React, { useState, useCallback, useEffect, useRef, Fragment } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { AUTO_SHOPS } from 'wmic-portals-utils-js/StringConstants';
import { InputField } from '@jutro/components';
import PropTypes from 'prop-types';
import WMICAutoShopsMapComponent from '../WMICAutoShopsMapComponent/WMICAutoShopsMapComponent';
import metadata from './WMICAutoShopsListMapViewComponent.metadata.json5';
import messages from './WMICAutoShopsListMapViewComponent.messages';
import styles from './WMICAutoShopsListMapViewComponent.module.scss';
import centerMarkerSrc from "../WMICAutoShopsMapComponent/images/centerMarker.png";

const SEARCH_BOUNDS = {
    west: -125.17973017584025,
    east: -113.18265981408601,
    south: 31.785494674550232,
    north: 46.19608239514482
};

const ZOOMED_IN_LEVEL_FROM_SEARCH = 11;
const ZOOMED_IN_LEVEL_FROM_LIST_ITEM_CLICK = 15;
const INITIAL_ZOOM = 4;
const INITIAL_CENTER = { lat: 38.96845787313289, lng: -118.04360916884603 };
const SEARCH_INPUT_SELECTOR = "#autoShopsListMapViewBodySearchInputContainer input[type='text']";

function WMICAutoShopsListMapViewComponent(props) {
    const { onShowListOnly, googleMapsApi, loadDataPromise } = props;
    const translator = useTranslator();
    const autocomplete = useRef(undefined);
    const mapInstance = useRef(undefined);
    const placesService = useRef(undefined);
    const latLngBoundsOregonAndCalifornia = useRef(undefined);
    const listDisplayMode = useRef(AUTO_SHOPS.DEFAULT_VIEW);
    const markerAtSearchLocation = useRef(undefined);
    const [filteredSearchResults, setFilteredSearchResults] = useState([]);
    const [selectedOrgId, setSelectedOrgId] = useState(undefined);
    const [locationInput, setLocationInput] = useState('');
    const [mapViewConfig, setMapViewConfig] = useState({zoomLevel: INITIAL_ZOOM, centerCoordinates: INITIAL_CENTER});

    const handleShowListView = () => {
        onShowListOnly();
    };

    const handleLocationInputChange = (value) => {
        setLocationInput(value);
    };

    const handleLocationInputBlur = (event) => {
        setLocationInput(event.target.value);
    };

    const handleSearch = () => {
        const searchInputElement = document.querySelector(SEARCH_INPUT_SELECTOR);
        if (searchInputElement !== null) {
            setLocationInput(searchInputElement.value);
        }

        new googleMapsApi.places.AutocompleteService().getPlacePredictions({
            bounds: latLngBoundsOregonAndCalifornia.current,
            input: searchInputElement.value
        }, (predictions) => {
            const request = {
                placeId: predictions[0].place_id,
                fields: ['place_id', 'geometry', 'name', "formatted_address",]
            };
            
            placesService.current.getDetails(request, (place, status) => {
                if (status === googleMapsApi.places.PlacesServiceStatus.OK && place && place.geometry && place.geometry.location) {
                    searchInputPlaceChangedCallback(place);
                }
              });
        });
    };

    const getHeader = () => {
        return (
            <div className={styles['header']}>
                <h1>{translator(messages.autoShopsTitle)}</h1>
                <p className="is-size-3">{translator(messages.autoShopsListMapViewSubTitle)}</p>
            </div>
        );
    };

    const getInputField = () => {
        return (
                <InputField
                    id='autoShopsListMapViewBodySearchInput'
                    label='Enter an address or zip code and press enter to update the list of shops and the map'
                    onValueChange={(locationInput) => { handleLocationInputChange(locationInput); }}
                    onBlur={(locationInput) => { handleLocationInputBlur(locationInput); }}
                    onEnter={() => { handleSearch();}}
                    className={`input ${styles['list-map-address-input']}`}
                    labelClassName='label'
                    placeholder='Enter your address or zip'
                    autoComplete={true}
                    aria-required={true}
                    aria-invalid={false}
                    aria-label='Enter your address or zip code and press enter to update the list of shops and the map'
                    aria-describedby='testing descby'
                    aria-labelledby='testing labeledby'
                    role='testing role'
                    value={locationInput}
                />
        );
    };

    const getFacilitiesInViewText = useCallback(() => {
        let numberOfResults = '';
        
        if (filteredSearchResults.length === 0) {
            numberOfResults = `${translator(messages.autoShopsListMapViewNoFacilityInView)}`;
        } else {
            numberOfResults = filteredSearchResults.length === 1 ? `${filteredSearchResults.length} ${translator(messages.autoShopsListMapViewFacilityInView)}` : `${filteredSearchResults.length} ${translator(messages.autoShopsListMapViewFacilitiesInView)}`;
        }
        
        return numberOfResults
    }, [filteredSearchResults]);

    const getDirectionsLatLng = (lat, lng) => {
        const directionsLatLng = {
            lat: parseFloat(lat),
            lng: parseFloat(lng)
        };
        return directionsLatLng;
    };

    const onClickAutoShop = (event, lat, lng, orgId) => {
        event.preventDefault();
        setSelectedOrgId(orgId);
        const newCenter = { lat: parseFloat(lat), lng: parseFloat(lng) };
        setMapViewConfig({zoomLevel: ZOOMED_IN_LEVEL_FROM_LIST_ITEM_CLICK, centerCoordinates: newCenter});
    };

    const getAutoShopsListAsHtml = useCallback(() => {
        return filteredSearchResults.map((preferredAutoShopModel) => {
            return preferredAutoShopModel.toHTML({translator, selectedOrgId, onClickAutoShop, getDirectionsLatLng});
        });
    }, [filteredSearchResults, selectedOrgId, translator]);

    const getResultsList = useCallback(() => {
        switch(listDisplayMode.current) {
            case AUTO_SHOPS.MAP_NO_SHOPS_FOUND:
                return (
                    <div className={`${styles['far-result-error']} p-4`}>
                        <h2 className="mb-2">{translator(messages.autoShopsNoRepairShopsFound)}</h2>
                        <p>{translator(messages.autoShopsNoRepairShopsFoundInstructions)}</p>
                    </div>
                );
            case AUTO_SHOPS.MAP_REFINED_SEARCH:
                return (
                    <ul className={`${styles['far-repair-list']} py-3 pl-0`} id="repair-list">
                        {
                            getAutoShopsListAsHtml()
                        }
                    </ul>
                );
            default:
                return (
                    <div className={`${styles['far-result-error']} p-4`}>
                        <h2 className="mb-2">{translator(messages.autoShopsRefineYourSearch)}</h2>
                        <p>{translator(messages.autoShopsRefineYourSearchInstructions)}</p>
                    </div>
                );
        }

    }, [getAutoShopsListAsHtml, translator]);

    const searchInputPlaceChangedCallback = (placeFromInput) => {
        let place;
        if (placeFromInput !== undefined) {
            place = placeFromInput;
        } else {
            place = autocomplete.current.getPlace();
        }
        const mapCenter = mapInstance.current.getCenter();

        if (place.geometry && mapCenter.lat !== place.geometry.location.lat() && mapCenter.lng !== place.geometry.location.lng()) {
            const newCenter = { lat: parseFloat(place.geometry.location.lat()), lng: parseFloat(place.geometry.location.lng()) };

            if (markerAtSearchLocation.current) {
                markerAtSearchLocation.current.setMap(null);   // remove the marker from the map
            }
            markerAtSearchLocation.current = new googleMapsApi.Marker({
                position: newCenter,
                icon: {
                    url: centerMarkerSrc
                },
                title: place.resourceName,
                map: mapInstance.current
            });

            setMapViewConfig({zoomLevel: ZOOMED_IN_LEVEL_FROM_SEARCH, centerCoordinates: newCenter});
        }
    };

    useEffect(() => {
        if (googleMapsApi) {
            latLngBoundsOregonAndCalifornia.current = new googleMapsApi.LatLngBounds(
                new googleMapsApi.LatLng(SEARCH_BOUNDS.south, SEARCH_BOUNDS.west),
                new googleMapsApi.LatLng(SEARCH_BOUNDS.north, SEARCH_BOUNDS.east));

            const input = document.querySelector(SEARCH_INPUT_SELECTOR);

            if (input !== null) {
                const options = {
                    bounds: latLngBoundsOregonAndCalifornia.current,
                    query: input.value,
                    componentRestrictions: {country: 'us'},
                    fields: ['place_id', 'geometry', 'name'],
                    strictBounds: true
                };

                autocomplete.current = new googleMapsApi.places.Autocomplete(input, options);
                autocomplete.current.addListener('place_changed', searchInputPlaceChangedCallback);
            }
        }
    }, [googleMapsApi]);

    const populateResultsListCallback = (limitedShopsInView) => {
        if (limitedShopsInView.length === 0) {
            listDisplayMode.current = AUTO_SHOPS.MAP_NO_SHOPS_FOUND;
        } else {
            listDisplayMode.current = AUTO_SHOPS.MAP_REFINED_SEARCH;
        }
        setFilteredSearchResults(limitedShopsInView);
    };

    const markerClickCallback = (orgId) => {
        // highlight the list item corresponding to this orgId
        setSelectedOrgId(orgId);
        const selectedEntryElement = document.getElementById(`entry-${orgId}`);
        if (selectedEntryElement) {
            selectedEntryElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    };

    const onSetMapInstance = (map) => {
        mapInstance.current = map;
        placesService.current = new googleMapsApi.places.PlacesService(map);
    };

    const hasResultsInView = () => {
        return filteredSearchResults.length > 0;
    };

    const overrideProps = {
        autoShopsListMapViewHeaderContainer: {
            content: getHeader()
        },
        autoShopsListMapViewResultsList: {
            content: getResultsList()
        },
        googleMapsComponentContainer: {
            mountNodeId: "mapContent",
            googleMapsApi,
            loadDataPromise,
            populateResultsListCallback,
            markerClickCallback,
            onSetMapInstance,
            mapViewConfig,
            selectedOrgId
        },
        autoShopsListMapViewBodySearchInput: {
            content: getInputField()
        },
        autoShopsListMapViewNumberOfResults: {
            content: getFacilitiesInViewText(),
            className: hasResultsInView() ? "far-facilities-in-view" : "far-facilities-in-view sr-only",
            role: "alert"
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleShowListView
        },
        resolveComponentMap: {
            WMICAutoShopsMapComponent
        }
    };

    WMICAutoShopsListMapViewComponent.propTypes = {
        onShowListOnly: PropTypes.func.isRequired,
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            overrideProps={overrideProps}
        />
    );
}

export default WMICAutoShopsListMapViewComponent;
