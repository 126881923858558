import React, {useCallback, useEffect, useMemo, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {ModalFooter, ModalNext,} from '@jutro/components';
import {useTranslator} from '@jutro/locale';
import {WMICChangePaymentPlanErrorModal, WMICManageBankingInformation} from 'gw-capability-policy-react';
import {useAuthentication} from 'wmic-digital-auth-react';
import {WMICButton} from 'wmic-components-platform-react';
import {AccountBillingDetailsService, BillingService} from 'gw-capability-billing';
import {CONSTANTS, WMICFeatureFlagUtil, WMICPaymentAccountContactUtil} from 'wmic-portals-utils-js';
import {PolicyService} from 'gw-capability-policy';
import {WMICLoader} from 'gw-components-platform-react';
import WMICPaymentPlanModal from './WMICPaymentPlanModal';
import paymentUtility from './ManagePaymentPlanUtility';
import WMICSelectPaymentPlanModal from './WMICSelectPaymentPlanModal';
import WMICComparePaymentPlanModal from './WMICComparePaymentPlanModal';
import WMICSelectBankAccountModal from './WMICSelectBankAccountModal';
import WMICChangePaymentPlanResultModal from './WMICChangePaymentPlanResultModal';
import styles from './WMICManagePaymentPlan.module.scss';
import messages from './WMICManagePaymentPlan.messages';

const PAYMENT_PAGE = 1;
const SELECT_PAYMENT_PAGE = 2;
const COMPARE_PAYMENT_PAGE = 3;
const SELECT_BANK_ACCOUNT_PAGE = 4;
const MANAGE_BANKING_INFO = 5;
const CHANGE_RESULT = 6;
const PAYMENT_INVALID_ROUTING_TRANSIT_NUMBER = 7;
const CHANGE_FAILURE = 8;

const WMICManagePaymentPlan = (props) => {
    const {
        isOpen,
        onReject,
        stepName,
        updateBillingDetails: updatePolicyDetailsPage,
        fetchDataOnClose,
        isRenewal,
        availablePaymentPlans
    } = props;
    const translator = useTranslator();
    const [step, updateStep] = useState(PAYMENT_PAGE);
    const [isLoading, setLoading] = useState(false);
    const [paymentOption, updatePaymentOption] = useState();
    const [newPaymentPlan, updateNewPaymentPlan] = useState();
    const [accountInfo, updateAccountInfo] = useState(undefined);
    const [saveDisabled, updateSaveDisabled] = useState(true);
    const [changeResult, updateChangeResult] = useState(false);
    const [modalLoaded, updateModalLoaded] = useState(false);
    const [accountContact, updateAccountContact] = useState(undefined);
    const [bankAccount, updateBankAccount] = useState(undefined);
    const [MARpaymentPlanName, setMARPaymentPlanName] = useState(availablePaymentPlans);
    const [ldFlags, setLdFlags] = useState({});
    const [billingData, updateBillingData] = useState(props.billingData);
    const { authHeader, userInfo, authUserData, email } = useAuthentication();
    const { policyId, termNumber } = _.get(props, 'billingData', {});
    const monthlyPay = CONSTANTS.MONTHLY_PAY;
    const focusRef = useRef(null);

    const stepMapping = useMemo(() => [
        {
            stepNumber: PAYMENT_PAGE,
            contextDescription: translator(messages.paymentPageDescriptionAria)
        },
        {
            stepNumber: SELECT_PAYMENT_PAGE,
            contextDescription: translator(messages.selectPaymentPageDescriptionAria)
        },
        {
            stepNumber: COMPARE_PAYMENT_PAGE,
            contextDescription: translator(messages.comparePaymentPageDescriptionAria)
        },
        {
            stepNumber: SELECT_BANK_ACCOUNT_PAGE,
            contextDescription: translator(messages.selectBankAccountPageDescriptionAria)
        },
        {
            stepNumber: MANAGE_BANKING_INFO,
            contextDescription: translator(messages.manageBankingInfoPageDescriptionAria)
        },
        {
            stepNumber: CHANGE_RESULT,
            contextDescription: translator(messages.changeResultPageDescriptionAria)
        },
        {
            stepNumber: PAYMENT_INVALID_ROUTING_TRANSIT_NUMBER,
            contextDescription: translator(messages.paymentInvalidRoutingTransitNumberPageDescriptionAria)
        },
        {
            stepNumber: CHANGE_FAILURE,
            contextDescription: translator(messages.changeFailurePageDescriptionAria)
        },
    ], [translator]);

    const initFeatureFlags = useCallback(async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);

        setLdFlags(rFlags);
    }, [authUserData]);

    const setFocusDialog = useCallback(() => {
        setTimeout(() => {
            focusRef?.current?.focus()
        }, 0);
    }, []);

    const updateStepAndFocus = useCallback((nextStep) => {
        updateStep(nextStep);
        setFocusDialog();
    }, [setFocusDialog]);

    const goToPrevious = () => {
        updateStepAndFocus(step - 1);
    };

    const goToNext = () => {
        updateStepAndFocus(step + 1);
    };

    const saveMARPlanChanges = useCallback((accountBankDetailsDTO, contactID) => {
        setLoading(true);
        // need log this for testing in stg/ qa env
        // eslint-disable-next-line no-console
        console.log('MARpaymentPlanName ::', MARpaymentPlanName);

        const billingSummaryPromise = AccountBillingDetailsService.changePaymentPlanToMAR(policyId, termNumber, MARpaymentPlanName, accountBankDetailsDTO, contactID, authHeader);

        billingSummaryPromise.then((result) => {
            updateChangeResult(result);
            updateStepAndFocus(CHANGE_RESULT);
        }).finally(() => setLoading(false));
    }, [MARpaymentPlanName, authHeader, policyId, termNumber, updateStepAndFocus]);


    useEffect(() => {
        if (!_.isUndefined(stepName)) {
            updateStepAndFocus(stepName);
        }
    }, [stepName, updateStepAndFocus]);

    const isReadonlyPaymentPlan = useMemo(() => billingData.amountOverDue.amount > 0 || billingData.outstandingAmount.amount === 0, [billingData]);

    const title = useMemo(() => {
        if (step === CHANGE_RESULT) {
            return translator(messages.paymentPlanUpdated);
        }

        const messageKey = paymentUtility.getModalType(step, PAYMENT_PAGE, isReadonlyPaymentPlan);

        // eslint-disable-next-line security/detect-object-injection
        return translator(messages[messageKey]);
    }, [translator, step, isReadonlyPaymentPlan]);

    const onEditPaymentPlan = useCallback(() => {
        updateStepAndFocus(SELECT_PAYMENT_PAGE);
        updatePaymentOption(undefined);
    }, [updateStepAndFocus]);

    const stepContextDescriptionForSr = useMemo(() => _.find(stepMapping, (currentMapping) => currentMapping.stepNumber === step)?.contextDescription, [step, stepMapping]);

    const modalBody = useMemo(() => {
        if (step === SELECT_PAYMENT_PAGE) {
            return (
                <WMICSelectPaymentPlanModal
                    billingData={billingData}
                    paymentOption={paymentOption}
                    updatePaymentOption={updatePaymentOption}
                    updateNewPaymentPlan={updateNewPaymentPlan}
                    setMARPaymentPlanName={setMARPaymentPlanName}
                />
            );
        }

        if (step === COMPARE_PAYMENT_PAGE) {
            return (
                <WMICComparePaymentPlanModal
                    billingData={billingData}
                    paymentOption={paymentOption}
                    newPaymentPlan={newPaymentPlan}
                    updateSaveDisabled={updateSaveDisabled}
                />
            );
        }

        if (step === SELECT_BANK_ACCOUNT_PAGE) {
            return (
                <WMICSelectBankAccountModal
                    billingData={billingData}
                    onManageBankingInformation={(accInfo) => {
                        updateAccountInfo(accInfo);
                        updateStepAndFocus(MANAGE_BANKING_INFO);
                    }}
                    updateSaveDisabled={updateSaveDisabled}
                    saveMARPlanChanges={saveMARPlanChanges}
                    accountContact={accountContact}
                    updateBankAccount={updateBankAccount}
                    updateModalLoaded={updateModalLoaded}
                />
            );
        }

        if (step === MANAGE_BANKING_INFO) {
            return (
                <WMICManageBankingInformation
                    accountInfo={accountInfo}
                    onBack={() => updateStepAndFocus(SELECT_BANK_ACCOUNT_PAGE)}
                    ldFlags={ldFlags}
                />
            );
        }

        if (step === CHANGE_RESULT) {
            return (
                <WMICChangePaymentPlanResultModal
                    newPaymentPlan={paymentOption}
                    changeResult={changeResult}
                    onReject={onReject}
                />
            );
        }

        if (step === PAYMENT_INVALID_ROUTING_TRANSIT_NUMBER) {
            return (
                <WMICChangePaymentPlanErrorModal
                    invalidRoutingTransitNumber
                    onClose={() => updateStepAndFocus(SELECT_BANK_ACCOUNT_PAGE)}
                />
            );
        }

        if (step === CHANGE_FAILURE) {
            return (
                <WMICChangePaymentPlanErrorModal
                    onClose={() => updateStepAndFocus(SELECT_BANK_ACCOUNT_PAGE)}
                />
            );
        }

        return (
            <WMICPaymentPlanModal
                onEdit={onEditPaymentPlan}
                billingData={billingData}
                isEditMode={!isReadonlyPaymentPlan}
                ldFlags={ldFlags}
            />
        );
    }, [step, onEditPaymentPlan, billingData, isReadonlyPaymentPlan, ldFlags, paymentOption, newPaymentPlan, saveMARPlanChanges, accountContact, updateStepAndFocus, accountInfo, changeResult, onReject]);

    const onSaveChanges = useCallback(() => {
        setLoading(true);

        if (paymentOption === monthlyPay) {
            const bankAccountRecord = bankAccount;

            bankAccountRecord.isOneIncPaymentEnabled = true;
            saveMARPlanChanges(bankAccountRecord, accountContact.publicID, onReject);
        } else {
            setLoading(true);
            AccountBillingDetailsService.changePaymentPlanTo(
                policyId, termNumber, paymentOption, authHeader
            ).then((result) => {
                setLoading(false);
                updateChangeResult(result);
                updateStepAndFocus(CHANGE_RESULT);
            }, () => {
                setLoading(false);
                onReject();
            });
        }
    }, [paymentOption, monthlyPay, bankAccount, saveMARPlanChanges, accountContact?.publicID, onReject, policyId, termNumber, authHeader, updateStepAndFocus]);

    const onClose = useCallback(() => {
        fetchDataOnClose();
        onReject();
    }, [fetchDataOnClose, onReject]);

    const onRefresh = () => {
        updateStepAndFocus(PAYMENT_PAGE);
        BillingService.getPolicyPaymentSummaryByPolicyNumber_WMIC(billingData.policyId, authHeader)
            .then((response) => {
                if (isRenewal && response.renewalPaymentDetails) {
                    let updatedBillingDataFromRenewal = {};

                    updatedBillingDataFromRenewal = response.renewalPaymentDetails;
                    updatedBillingDataFromRenewal.paymentHistory = response.paymentHistory;
                    updatedBillingDataFromRenewal.policyId = response.policyId;
                    updatedBillingDataFromRenewal.accountSummary = response.accountSummary;
                    updatePolicyDetailsPage(updatedBillingDataFromRenewal);
                    updateBillingData(updatedBillingDataFromRenewal);
                } else {
                    updatePolicyDetailsPage(response);
                    updateBillingData(response);
                }
            });
    };

    const getPolicyDetails = useCallback(async () => {
        const policy = await PolicyService.getAccountPolicyDetails_WMIC(billingData.policyId, billingData.termNumber, authHeader, userInfo.firstName, userInfo.lastName);

        await WMICPaymentAccountContactUtil.getPickedContact(authHeader, userInfo, policy.currentPeriod.contacts).then(pickedContact => {
            if (pickedContact) {
                updateAccountContact(pickedContact);
            } else {
                // this is just the fallback plan. If the user was properly enrolled then they shouldn't get in here
                policy.currentPeriod.contacts.forEach((currentContact) => {
                    if (currentContact.contact.emailAddress1 === email
                        && (currentContact.contactRole === translator(messages.primaryNamedInsured)
                            || currentContact.contactRole === translator(messages.namedInsured)
                            || currentContact.contactRole === CONSTANTS.CONTACT_ROLES.ACCOUNT_HOLDER)) {
                        updateAccountContact(currentContact.contact);
                    }
                });
            }
        }).finally(() => {
            setLoading(false);
        });
    }, [authHeader, billingData.policyId, billingData.termNumber, email, translator, userInfo]);

    const handleClose = () => {
        onReject();
    };

    useEffect(() => {
        setLoading(true);
        initFeatureFlags();
        getPolicyDetails();
    }, [getPolicyDetails, initFeatureFlags]);

    return (
        <ModalNext
            className={modalLoaded ? styles.hideModal : ''}
            isOpen={isOpen}
            shouldCloseOnEsc
            onRequestClose={handleClose}
            shouldCloseOnOverlayClick={false}
            contentLayout={{
                component: 'Grid',
                componentProps: {
                    rows: ['1fr', 'auto'],
                },
            }}
        >  
            <div ref={focusRef} tabIndex={1} aria-live="assertive" className={styles.modal}>
                <div id="modal-header" tabIndex="-1" >
                    {step !== MANAGE_BANKING_INFO &&
                        step !== PAYMENT_INVALID_ROUTING_TRANSIT_NUMBER &&
                        step !== CHANGE_FAILURE && (
                            <h1 className="wmic-modal-header-title">
                                {title}
                                <span className="sr-only">{stepContextDescriptionForSr}</span>
                            </h1>
                        )}
                </div>
                <div className={styles.modalBody}>
                    {isLoading ? <WMICLoader isInline /> : modalBody}
                </div>
            </div>
            
            {step !== MANAGE_BANKING_INFO &&
                step !== PAYMENT_INVALID_ROUTING_TRANSIT_NUMBER &&
                step !== CHANGE_FAILURE && (
                <ModalFooter>
                    {!isLoading && (
                        <React.Fragment>
                            {step === CHANGE_RESULT && (
                                <WMICButton
                                    onClick={() => onRefresh()}
                                    type="primary"
                                >
                                    {translator(messages.close)}
                                </WMICButton>
                            )}
                            {step > SELECT_PAYMENT_PAGE &&
                                step !== CHANGE_RESULT && (
                                <WMICButton
                                    onClick={() => goToPrevious()}
                                    type="primary"
                                    disabled={isLoading}
                                >
                                    {translator(messages.back)}
                                </WMICButton>
                            )}
                            {((step === COMPARE_PAYMENT_PAGE &&
                                paymentOption !== monthlyPay) ||
                                step === SELECT_BANK_ACCOUNT_PAGE) && (
                                <WMICButton
                                    onClick={() => onSaveChanges()}
                                    type="primary"
                                    disabled={saveDisabled || isLoading}
                                >
                                    {translator(messages.savePlanChanges)}
                                </WMICButton>
                            )}
                            {((step > PAYMENT_PAGE &&
                                step < COMPARE_PAYMENT_PAGE) ||
                                (step === COMPARE_PAYMENT_PAGE &&
                                paymentOption === monthlyPay)) && (
                                <WMICButton
                                    onClick={() => goToNext()}
                                    type="primary"
                                    disabled={_.isEmpty(paymentOption)}
                                >
                                    {translator(messages.next)}
                                </WMICButton>
                            )}
                            {step !== CHANGE_RESULT && (
                                <WMICButton
                                    onClick={() => onClose()}
                                    type={
                                        step === PAYMENT_PAGE
                                            ? 'primary'
                                            : 'outlined'
                                    }
                                >
                                    {translator(messages.close)}
                                </WMICButton>
                            )}
                        </React.Fragment>
                    )}
                </ModalFooter>
            )}
        </ModalNext>
    );
};

WMICManagePaymentPlan.propTypes = {
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    billingData: PropTypes.shape({
        outstandingAmount: PropTypes.shape({
            amount: PropTypes.number
        })
    }),
    stepName: PropTypes.number,
    updateBillingDetails: PropTypes.func.isRequired,
    ldFlags: PropTypes.shape({}),
    fetchDataOnClose: PropTypes.func,
    isRenewal: PropTypes.bool.isRequired
};


WMICManagePaymentPlan.defaultProps = {
    billingData: {},
    stepName: undefined,
    ldFlags: {},
    fetchDataOnClose: () => _.noop,
    isRenewal: false
};

export default WMICManagePaymentPlan;