import React from 'react';
import { useTranslator } from '@jutro/locale';
import { MetadataContent } from '@jutro/uiconfig';

import metadata from './FurtherAssistance.metadata.json5';
import messages from './FurtherAssistance.messages';

import { translateRichText } from '../../utils/WMICRichTextUtil';

function FurtherAssistance(props) {
    const translator = useTranslator();

    const overrideProps = {
        furtherAssistance: {
            content: translateRichText(translator(messages.assistanceVerbiage))
        }
    };

    return (
        <div>
            <MetadataContent uiProps={metadata.componentContent} overrideProps={overrideProps} />
        </div>
    );
}

export default FurtherAssistance;
