/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ModalFooter, InputField } from '@jutro/components';
import { Grid, GridItem } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import policyNumberLocationImg from 'wmic-amp-digital-theme-styles/images/policy-number-location.gif';
import { WMICLoader } from 'gw-components-platform-react';
import { WMICButton } from 'wmic-components-platform-react';
import messages from './WMICAddPolicyModal.messages';
import styles from './WMICAddPolicyModal.module.scss';

const WMICPolicyNumbersModal = (props) => {
    const {
        onReject,
        policyNumbers,
        updatePolicyNumbers,
        onFindPoliciesSuccess,
        onFindPoliciesFailure
    } = props;

    const [loading, updateLoading] = useState(false);
    const [policyNumber, updatePolicyNumber] = useState('');
    const [errorMessage, updateErrorMessage] = useState('');
    const { authHeader } = useAuthentication();

    const translator = useTranslator();
    const { EnrollmentService } = useDependencies('EnrollmentService');

    const DOM_ELEMENT_IDS = {
        ACCOUNT_POLICY_NUMBER_INPUT: 'accountOrPolicyNumberInput',
    };

    const stripDashSection = (value) => {
        return value.replace(/-.*/, '');
    };

    const onClickAddAnotherPolicy = (e, hideError) => {
        e.preventDefault();
        if (policyNumber || policyNumbers.length > 0) {
            const strippedNumber = stripDashSection(policyNumber);
            if (policyNumber.trim().length === 0) {
                if (!hideError) {
                    updateErrorMessage(translator(messages.missingPolicy));
                }
                return policyNumbers;
            }
            if (!(new RegExp('^\\d+[0,1]+(-\\d+){0,1}$')).test(policyNumber)) {
                if (!hideError) {
                    updateErrorMessage(translator(messages.wrongPolicy));
                }
                return policyNumbers;
            }
            if (policyNumbers.find((num) => stripDashSection(num) === strippedNumber)) {
                if (!hideError) {
                    updateErrorMessage(translator(messages.duplicatedPolicy));
                }
                return policyNumbers;
            }
            const updatedPolicyNumbers = [...policyNumbers, policyNumber];
            updatePolicyNumbers(updatedPolicyNumbers);
            updateErrorMessage('');
            updatePolicyNumber('');
            return updatedPolicyNumbers;
        }
        updateErrorMessage(translator(messages.missingPolicy));
        return policyNumbers;
    };

    const onClickRemovePolicy = useCallback((e, pNumber) => {
        e.preventDefault();
        updatePolicyNumbers([...policyNumbers.filter((num) => stripDashSection(num) !== stripDashSection(pNumber))]);
    }, [policyNumbers, updatePolicyNumbers]);

    const handleFailure = (policyNums) => {
        const message = translator(messages.youEnteredTheFollowing, { info: policyNums.join(', ') });
        onFindPoliciesFailure(`${message}\n${translator(messages.unableToAddPolicies)}`);
    };

    const handlePolicySearchResult = (response, updatedPolicyNumbers) => {
        if (response && response.possiblePolicies && response.possiblePolicies.length > 0) {
            onFindPoliciesSuccess(response.possiblePolicies);
        } else {
            handleFailure(updatedPolicyNumbers);
        }
    };

    const canSubmitSearch = (updatedPolicyNumbers) => {
        if (loading) {
            return false;
        }
        if (updatedPolicyNumbers.length === 0) {
            if (!errorMessage) {
                updateErrorMessage(translator(messages.missingPolicy));
            }
            return false;
        }
        return true;
    };

    const findPolicies = (e) => {
        const updatedPolicyNumbers = onClickAddAnotherPolicy(e, true);
        if (!canSubmitSearch(updatedPolicyNumbers)) {
            return;
        }

        updateLoading(true);
        const request = {
            type: 'ACCOUNT',
            policyNumbers: updatedPolicyNumbers.map((pNumber) => stripDashSection(pNumber))
        };

        EnrollmentService.searchForLinkablePolicies_WMIC(request, authHeader).then((response) => {
            updateLoading(false);
            handlePolicySearchResult(response, updatedPolicyNumbers);
        }).catch(() => {
            updateLoading(false);
            handleFailure(updatedPolicyNumbers);
        });
    };

    return (
        <div className={styles.modal}>
            <h1 className="wmic-modal-header-title">
                {translator(messages.addPoliciesHeader)}
            </h1>
            <div className={styles.modalBody}>
                <p className={styles['wmic-sm-footing']}>
                    {translator(messages.useFormToAddPolicies)}
                </p>

                    <div
                        id="addPolicyErrorMessages"
                        aria-live="assertive"
                        className="ww-error"
                    >
                        {errorMessage && (<p className={styles['wmic-sm-footing']}>
                            {errorMessage}
                        </p>)}
                    </div>

                <Grid
                    gap="small"
                    justifyItems="left"
                    className={styles.gridLayout}
                >
                    <GridItem tag="div" className={styles.wmicGridItem}>
                        <label
                            htmlFor={
                                DOM_ELEMENT_IDS.ACCOUNT_POLICY_NUMBER_INPUT
                            }
                            className={styles.numberLabel}
                        >
                            {translator(messages.accountOrPolicyNumber)}
                            <ul
                                className={
                                    styles['wmic-policy-number-list']
                                }
                            >
                                {policyNumbers.map((pNumber) => (
                                    <li key={pNumber}>
                                        <span aria-hidden>{pNumber}</span>{' '}
                                        <span className="sr-only">{translator(messages.enteredPolicyNumberToTheListAria, {pNumber})}</span>
                                        <button
                                            aria-label={translator(messages.removeNumberAria, {selectedNumber: pNumber})}
                                            className={cx(
                                                styles[
                                                    'wmic-policy-remove-button'
                                                ],
                                                'ww-link-button'
                                            )}
                                            onClick={(e) => {
                                                onClickRemovePolicy(
                                                    e,
                                                    pNumber
                                                );
                                            }}
                                        >
                                            <i className="fa fa-times" />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <input type="text"
                                   id={ DOM_ELEMENT_IDS.ACCOUNT_POLICY_NUMBER_INPUT }
                                   value={policyNumber}
                                   onChange={(e) => {
                                       updatePolicyNumber(e.target.value);
                                   }}
                                   data-testid={DOM_ELEMENT_IDS.ACCOUNT_POLICY_NUMBER_INPUT}
                                   className={styles.wmicInputField}
                                   aria-label={translator(messages.enterTheAccountOrPolicyNumberAria)}
                            />
                            <button
                                className={cx(
                                    'ww-link-button',
                                    'jut__Link__link'
                                )}
                                onClick={onClickAddAnotherPolicy}
                            >
                                {translator(messages.addAnotherPolicy)}
                            </button>
                        </label>
                    </GridItem>
                    <GridItem tag="div" className={styles.wmicGridItem}>
                        <img
                            src={policyNumberLocationImg}
                            alt={translator(
                                messages.policyNumberLocation
                            )}
                        />
                    </GridItem>
                </Grid>
            </div>
            <ModalFooter>
                <WMICButton
                    onClick={() => {
                        onReject();
                    }}
                    type="outlined"
                >
                    {translator(messages.cancel)}
                </WMICButton>
                <WMICButton
                    onClick={(e) => {
                        findPolicies(e);
                    }}
                    type="primary"
                >
                    {!loading ? (
                        translator(messages.next)
                    ) : (
                        <WMICLoader isInline />
                    )}
                </WMICButton>
            </ModalFooter>
        </div>
    ); 
};

WMICPolicyNumbersModal.propTypes = {
    onReject: PropTypes.func.isRequired,
    policyNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
    updatePolicyNumbers: PropTypes.func.isRequired,
    onFindPoliciesSuccess: PropTypes.func.isRequired,
    onFindPoliciesFailure: PropTypes.func.isRequired,
};


export default WMICPolicyNumbersModal;