import React, { Fragment, useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { ToggleField, useModal } from '@jutro/components';
import { WMICModal } from 'gw-capability-policy-react';
import { AddressForm } from 'gw-capability-policychange-common-react';
import { WMICButton } from 'wmic-components-platform-react';
import {
    WMICPolicyChangeUtilsService,
    WMICPolicyChangeStateService,
    AddressInfoModel,
    ChangeAddressModel,
    WMICControllerUtil
} from 'wmic-capability-policychange';
import { useHistory } from 'react-router-dom';
import { publish, JUTRO_TOPICS } from "@jutro/events";

import metadata from './WMICChangeAddressEditComponent.metadata.json5';
import messages from './WMICChangeAddressEditComponent.messages';
import styles from './WMICChangeAddressEditComponent.module.scss';

function WMICChangeAddressEditComponent(props) {
    const modalApi = useModal();
    const {
        address,
        setEditMode,
        policyData,
        accountHolder,
        match,
        handleChangeLoading
    } = props;
    const [sameAddress, setSameAddress] = useState(undefined);
    const translator = useTranslator();
    const history = useHistory();
    const { authHeader } = useAuthentication();
    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');
    const policyType = _.get(match, 'params.policyType');
    const { street, unit } = WMICPolicyChangeUtilsService.splitStreetUnit(address.addressLine1);
    const originalData = {
        street: street,
        unit: unit,
        city: address.city,
        state: address.state,
        zip: address.postalCode,
        isSameAsAddress: { value: sameAddress }
    };

    const [data, setData] = useState(originalData);
    const [isValid, setValidity] = useState(true);
    const [questionRequired, setQuestionRequired] = useState(false);

    const [secondaryAddress, setSecondaryAddress] = useState({});


    const secondaryAddressOption = policyType === 'home' ? messages.samePropertyAddress : messages.sameVehicleAddress;
    const secondaryAddressFormTitle = policyType === 'home' ? messages.addPropertyAddress : messages.addVehicleAddress;

    // To resolve issues where screen readers don't announce content on page load for SPAs
    // Reference: https://www.gatsbyjs.com/blog/2019-07-11-user-testing-accessible-client-routing/
    useEffect(() => {
        const headerTag = document.getElementById('editAddressSubtitle');
        if (headerTag) {
            headerTag.focus({preventScroll: true});
        }
    }, [])

    const handleToggle = (value) => {
        setSameAddress(value);
        setQuestionRequired(false);
    };

    const cleanUp = () => {
        // Remove current addressChangeType and addressToUpdate
        WMICPolicyChangeStateService.clearState();
        setEditMode(false);
    };

    const hasModelChanged = useMemo(() => {
        if (!data && !originalData) return false;
        const addressFields = Object.entries(data).filter(([, value]) => typeof value === "string"); // avoid isSameAsAddress
        return addressFields.some(([key, value])  => value !== originalData[key]);
    }, [data, originalData]);

    const hasChanges = useMemo(() => {
        const secondaryAddressHasChanges = !sameAddress && !_.isUndefined(sameAddress) && Object.keys(secondaryAddress).length !== 0;
        return hasModelChanged || secondaryAddressHasChanges;
    }, [hasModelChanged, sameAddress, secondaryAddress]);

    const handleCancel = () => {
        if (hasChanges) {
            modalApi.showModal(
                <WMICModal
                    id="editAddressCancelModal"
                    modalHeader={translator(messages.cancellationModalTitle)}
                    modalBody={translator(messages.cancellationModalBody)}
                    onConfirmCallback={() => {
                        cleanUp();
                    }}
                    onCancelCallback={() => _.noop()}
                    confirmButtonText={translator(messages.cancellationModalConfirm)}
                    cancelButtonText={translator(messages.cancellationModalCancel)}
                />
            );
        } else {
            cleanUp();
        }
    };

    const canSubmit = () => {
        const notAswered = sameAddress === undefined;
        if (notAswered) {
            setQuestionRequired(true);
        }
        return notAswered;
    };

    const handleSave = () => {
        // Validating if it is possible to submit
        if (canSubmit()) {
            return;
        }

        const changeType = 'change-address';
        const addressInfoModel = new AddressInfoModel(data);
        const type = policyType;
        const model = new ChangeAddressModel({
            type: type,
            oldAddress: new AddressInfoModel(originalData),
            newAddress: addressInfoModel,
            mailingPurpose: sameAddress
        });

        let changes = model;

        if (sameAddress !== undefined && sameAddress === false) {
            const garageModel = new ChangeAddressModel({
                type: policyType,
                oldAddress: undefined,
                newAddress: new AddressInfoModel(secondaryAddress),
                mailingPurpose: sameAddress
            });

            changes = [model, garageModel];
        }
        modalApi.showModal(
            <WMICModal
                id="editAddressConfirmModal"
                modalHeader={translator(messages.submissionModalConfirm)}
                modalBody={translator(messages.submissionModalBody)}
                onConfirmCallback={() => {
                    handleChangeLoading(true);

                    publish(JUTRO_TOPICS.BUTTON_CLICKED, { trackButtonIdentifier: translator(messages.submissionModalTrackingText)});

                    WMICPolicyChangeStateService.addChangeRequest('change-address', changeType, {
                        changeType: 'change-address',
                        objectType: 'ChangeAddressModel',
                        changeModel: changes
                    });
                    WMICControllerUtil.handleSubmit({
                        history: history,
                        policyNumber: policyNumber,
                        termNumber: termNumber,
                        policyData: policyData,
                        policyType: policyType,
                        accountInfo: accountHolder,
                        authHeader: authHeader
                    },
                    translator);
                }}
                onCancelCallback={() => _.noop()}
                confirmButtonText={translator(messages.submissionModalSubmit)}
                cancelButtonText={translator(messages.submissionModalCancel)}
            />
        );
    };

    const getEditAddressButtonSection = () => {
        return (
            <Fragment>
                <WMICButton type="outlined" onClick={handleCancel}>
                    { translator(messages.cancel) }
                </WMICButton>
                <WMICButton type="primary" onClick={() => { handleSave(); }} disabled={!(isValid && hasChanges)}>
                    { translator(messages.continue) }
                </WMICButton>
            </Fragment>
        );
    };

    const getAddressToggleContent = () => {
        return (
            <ToggleField
                label={translator(secondaryAddressOption)}
                availableValues={[
                    {
                        displayName: translator(messages.sameAddressToggleYes),
                        id: 'true'
                    },
                    {
                        displayName: translator(messages.sameAddressToggleNo),
                        id: 'false'
                    }
                ]}
                className={`${styles.sameAddressToggle}`}
                id="sameAddress"
                onValueChange={handleToggle}
                value={sameAddress}
            />
        );
    };

    const editButtonAction = () => {
        setEditMode(true);
    };

    const overrideProps = {

        editButton: {
            content: messages.editButton,
            onClick: editButtonAction
        },

        editAddressForm: {
            address: data,
            setAddress: setData,
            setValidity: setValidity
        },

        sameAddressError: {
            visible: questionRequired,
        },

        sameAddress: {
            content: getAddressToggleContent()
        },

        secondaryAddressTitle: {
            content: secondaryAddressFormTitle
        },

        secondaryAddressContent: {
            visible: !sameAddress && !_.isUndefined(sameAddress)
        },

        editSecondaryAddressForm: {
            address: secondaryAddress,
            setAddress: setSecondaryAddress,
            setValidity: setValidity
        },

        editAddressButtonSection: {
            content: getEditAddressButtonSection()
        }
    };

    const resolvers = {
        resolveComponentMap: {
            wmicAddressForm: AddressForm
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={address}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICChangeAddressEditComponent.propTypes = {
    address: PropTypes.shape({
        addressLine1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        postalCode: PropTypes.string
    }),
    setEditMode: PropTypes.func.isRequired,
    match: PropTypes.shape({}).isRequired,
    handleChangeLoading: PropTypes.func.isRequired,
};

WMICChangeAddressEditComponent.defaultProps = {
    address: {}
};

export default WMICChangeAddressEditComponent;