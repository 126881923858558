import { setComponentMapOverrides } from '@jutro/uiconfig';
import 'gw-capability-policychange-common-react';
import PolicyChangesPage from './pages/PolicyChanges/PolicyChangesPage';
import ValuablesPage from './pages/Valuables/ValuablesPage';
import MortgageePage from './pages/Mortgagee/MortgageePage';
import CoveragesPage from './pages/Coverages/CoveragesPage';
import PCHOQuotePage from './pages/Quote/QuotePage';
import './HOPolicyChange.messages';

setComponentMapOverrides(
    {
        PolicyChangesPage: { component: 'PolicyChangesPage' },
        ValuablesPage: { component: 'ValuablesPage' },
        MortgageePage: { component: 'MortgageePage' },
        CoveragesPage: { component: 'CoveragesPage' },
        PCHOQuotePage: { component: 'PCHOQuotePage' }
    },
    {
        PolicyChangesPage,
        ValuablesPage,
        MortgageePage,
        CoveragesPage,
        PCHOQuotePage
    }
);
// eslint-disable-next-line import/prefer-default-export
export { default as HOPolicyChangeWizard } from './HOPolicyChangeWizard';
