import React, { useState, useCallback, useEffect } from 'react';
import { MetadataContent } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MapUtil, WMICRemoteLogger, WMICFeatureFlagUtil } from 'wmic-portals-utils-js';
import _ from 'lodash';

import metadata from './WMICEMQVehicleDistanceCalculationComponent.metadata.json5';
import styles from './WMICEMQVehicleDistanceCalculationComponent.module.scss';
import messages from './WMICEMQVehicleDistanceCalculationComponent.messages';

let googleMapsPromise;

const loadGoogleMapsAPI = () => {
    const protocol = document.location.protocol === 'https:' ? 'https' : 'http';
    const googleMapsApiSrc = `${protocol}://maps.googleapis.com/maps/api/js?key=${MapUtil.getApiKey()}&libraries=${MapUtil.getLibraries()}&version=${MapUtil.getMapVersion()}`;
    if (!googleMapsPromise) {
        googleMapsPromise = new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.async = true;
            document.head.appendChild(script);
            script.src = googleMapsApiSrc;
            script.onerror = (err) => {
                reject(err);
            };
            script.onload = () => {
                resolve(window.google.maps);
            };
        });
    }
    return googleMapsPromise;
};

const METERS_PER_MILE = 1609.344;
const metersToMiles = (meters) => {
    return meters / METERS_PER_MILE;
};

function WMICEMQVehicleDistanceCalcuationComponent(props) {
    const { onUpdateDistance } = props;
    const [originAddress, updateOriginAddress] = useState('');
    const [destinationAddress, updateDestinationAddress] = useState('');
    const [distance, updateDistance] = useState(undefined);
    const [distanceCannotBeCalculated, updateDistanceCannotBeCalculated] = useState(undefined);
    const [googleMapsApi, updateGoogleMapsApi] = useState(undefined);
    const [ldFlags, setLdFlags] = useState({});
    const [logger, setLogger] = useState({});
    const translator = useTranslator();
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const history = useHistory();

    const updateLogger = useCallback (() => {
        const updatedlogger = WMICRemoteLogger.getWMICRemoteLogger(
            'EMQVehicleDistanceCalculation',
            history.location.pathname,
            window.location,
            ldFlags,
            authHeader
        );
        setLogger(updatedlogger);
    },[ldFlags]);


    const initialGoogleMapApi = useCallback(async () => {
        if (_.isUndefined(googleMapsApi)) {
            const initApi = await loadGoogleMapsAPI();
            updateGoogleMapsApi(initApi);
        }
    }, [googleMapsApi]);
    
    const initFeatureFlags = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setLdFlags(rFlags);
    }

    useEffect(() => {
        initialGoogleMapApi();
        initFeatureFlags();
        updateLogger();
    // Only run once when page is init
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const makeGoogleMapDistanceApiCall = useCallback(async () => {
        const service = new googleMapsApi.DistanceMatrixService();
        let result = {
            response: '',
            status: ''
        };

        try {
            const response = await service.getDistanceMatrix({
                origins: [originAddress],
                destinations: [destinationAddress],
                travelMode: googleMapsApi.TravelMode.DRIVING,
                unitSystem: googleMapsApi.UnitSystem.IMPERIAL
            });

            result.response = response;
            result.status = 'OK';
        } catch (e) {
            result = null;
        }

        return result;
    }, [googleMapsApi, destinationAddress, originAddress]);

    const calculateDistance = useCallback(async () => {
        const calculatedDistanceResult = await makeGoogleMapDistanceApiCall();
        if (calculatedDistanceResult.status !== 'OK') {
            updateDistance(translator(messages.emqVehicleEstimateNotFound));
            updateDistanceCannotBeCalculated(true);
            logger.error(`emq.common.components.vehicle.distance calculation - did not receive ''OK'' status trying to calculate distance. Instead got ${calculatedDistanceResult.status}`);
        } else if (calculatedDistanceResult.response !== null) {
            switch (calculatedDistanceResult.response.rows[0].elements[0].status) {
                case googleMapsApi.DistanceMatrixElementStatus.ZERO_RESULTS:
                    updateDistance(translator(messages.emqVehicleEstimateNoResult));
                    updateDistanceCannotBeCalculated(true);
                    break;
                case googleMapsApi.DistanceMatrixElementStatus.NOT_FOUND:
                    updateDistance(translator(messages.emqVehicleEstimateNotFound));
                    updateDistanceCannotBeCalculated(true);
                    break;
                case googleMapsApi.DistanceMatrixElementStatus.OK:
                    {
                        const distanceValue = calculatedDistanceResult
                            .response.rows[0].elements[0].distance.value;
                        // Distance returns from the API is always in metric unit system
                        const distanceInMiles = Math.round(metersToMiles(distanceValue));
                        updateDistance(distanceInMiles);
                        updateDistanceCannotBeCalculated(false);
                        onUpdateDistance(distanceInMiles);
                    }
                    break;
                default:
                    updateDistance(translator(messages.emqVehicleEstimateNotFound));
                    updateDistanceCannotBeCalculated(true);
                    break;
            }
        } else {
            logger.info(`No results found for distance calculation using values: [${originAddress}, ${destinationAddress}]`);
            updateDistance(translator(messages.emqVehicleEstimateNotFound));
        }
    }, [translator, googleMapsApi, makeGoogleMapDistanceApiCall, logger]);

    const resetResult = useCallback(() => {
        updateOriginAddress('');
        updateDestinationAddress('');
        updateDistance(undefined);
    }, []);

    const getEstimatedMiles = useCallback(() => {
        return !distanceCannotBeCalculated ? (
            <p>{translator(messages.emqVehicleEstimateMiles, { distance: distance })}</p>
        ) : (<p>{distance}</p>);
    }, [distanceCannotBeCalculated, translator, distance]);

    const overrideProps = {
        emqVehicleEstimateMilesContainer: {
            visible: _.isUndefined(distance)
        },
        emqVehicleEstimateMilesOrigin: {
            onValueChange: (value) => updateOriginAddress(value),
            value: originAddress
        },
        emqVehicleEstimateMilesDestination: {
            onValueChange: (value) => updateDestinationAddress(value),
            value: destinationAddress
        },
        emqVehicleEstimateMilesButton: {
            disabled: _.isEmpty(originAddress) || _.isEmpty(destinationAddress)
        },
        emqVehicleEstimateResultContainer: {
            visible: !_.isUndefined(distance) && !_.isNaN(distance)
        },
        emqVehicleEstimateResult: {
            content: getEstimatedMiles()
        },
        emqVehicleEstimateResultTitle: {
            visible: !distanceCannotBeCalculated
        },
        emqVehicleEstimateResultNotFound: {
            visible: distanceCannotBeCalculated
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onCalculateDistance: calculateDistance,
            onReCalculate: resetResult,
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

WMICEMQVehicleDistanceCalcuationComponent.propTypes = {
    onUpdateDistance: PropTypes.func.isRequired
};

export default WMICEMQVehicleDistanceCalcuationComponent;
