import React, { useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { MetadataContent } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import CONSTANTS from "wmic-portals-utils-js/StringConstants";
import _ from 'lodash';

import { WMICFnolStateService, WMICFnolSharedDataService, WMICFnolUtilsService } from 'wmic-capability-fnol';
import { FNOLConstants } from 'wmic-capability-fnol-react';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { WMICButton } from 'wmic-components-platform-react';
import WMICFNOLPAReview from '../WMICFNOLPAReview/WMICFNOLPAReview'
import metadata from './WMICFNOLPASuccess.metadata.json5';
import messages from './WMICFNOLPASuccess.messages.js';
import styles from './WMICFNOLPASuccess.module.scss'


function WMICFNOLPASuccess(props) {
    const { match } = props;
    const DESIGNATED_TEMP_CLAIM_NUMBER = "999";
    const stateParam = _.get(match, 'params.state');
    const trackingNumberParam = _.get(match, 'params.trackingNumber');
    const isTempClaim = trackingNumberParam === DESIGNATED_TEMP_CLAIM_NUMBER;
    const translator = useTranslator();
    const claimType = FNOLConstants.CLAIM_TYPE.AUTO;
    const history = useHistory();

    const state = WMICFnolStateService.getState();

    useEffect(() => {
        if (history.location.state === undefined || !history.location.state.success) {
            history.push(`/${stateParam}/${claimType}-claim`);
            WMICFnolStateService.clearState();
            return;
        }
        const locationState = { ...history.location.state };
        delete locationState.success;
        history.replace(locationState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (state) {
            WMICFnolUtilsService.copyAttributes(state, WMICFnolSharedDataService);
        }
        
        switch (stateParam) {
            case CONSTANTS.STATE.CA_LONG:
                break;
            case CONSTANTS.STATE.OR_LONG:
                break;
            default:
                history.push('/home')
                break;
        }
        if (!WMICFnolSharedDataService.driverOfInsuredVehicle.address.stateProvince) {
            WMICFnolSharedDataService.driverOfInsuredVehicle.address.stateProvince = stateParam;
        }

        
    }, [stateParam, history, state])


    const onPrintClaim = () => window.print();
    
    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICButton,
            WMICFNOLPAReview
        },
        resolveCallbackMap: {
            onPrintClaim
        }
    };

    const overrideProps = {
        FNOLPASuccessTrackingNumberText: {
            content: isTempClaim ? '' : trackingNumberParam,
        },
        FNOLPASuccessRecordTrackingNumberText: {
            content: isTempClaim ? WMICRichTextUtil.translateRichText(translator(''))
                : WMICRichTextUtil.translateRichText(translator(messages.FNOLPASuccessRecordTrackingNumberText)),
        },
        FNOLPASuccessAvailablityText: {
            content: WMICRichTextUtil.translateRichText(translator(messages.FNOLPASuccessAvailablityText)),
        },
        FNOLPASuccessContactText: {
            content: WMICRichTextUtil.translateRichText(translator(messages.FNOLPASuccessContactText)),
        }
    }

    return (
        <div>
            <MetadataContent
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                {...resolvers} />
        </div>
    );
}

export default withRouter(WMICFNOLPASuccess)

