import React, { useContext } from 'react';
import { AuthContext, SecureRoute } from '@jutro/auth';
import { WMICLoader } from 'gw-components-platform-react';

/**
 * Wraps a component in a secure route with an authentication context
 *
 * @alias privateRouteWithAuthContext
 * @memberof module:gw-digital-auth-react
 *
 * @param {ReactElement} WrappedComponent a component to be wrapped
 *
 * @returns {ReactElement} the element wrapped in a secure route with an authentication context
 */
const privateRouteWithAuthContext = WrappedComponent => {
    return function ContextComponent(...otherProps) {
        const { isAuthenticated, isPending } = useContext(AuthContext);
        
        return (
            <SecureRoute
                {...otherProps}
                render={props =>
                    !isPending ? (
                        isAuthenticated ? (
                            <WrappedComponent {...props} />
                        ) : null
                    ) : (
                        <WMICLoader />
                    )
                }
            />
        );
    };
};

export default privateRouteWithAuthContext;
