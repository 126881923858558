import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICRemoteLogger } from 'wmic-portals-utils-js';
import { PhoneUtil } from '@xengage/gw-portals-viewmodel-js';
import { ServiceManager } from '@jutro/services';
import _ from 'lodash';

import metadata from './WMICAutoShopsListViewComponent.metadata.json5';
import styles from './WMICAutoShopsListViewComponent.module.scss';
import messages from './WMICAutoShopsListViewComponent.messages';
import WMICAutoShopModel from '../../models/WMICAutoShopModel';
import WMICPagination from './WMICPagination';

function WMICAutoShopsListViewComponent(props) {
    const { onShowMap, loadDataPromise } = props;

    const [locationInput, setlocationInput] = useState('');
    const [ldFlags, setLdFlags] = useState({});
    const [logger, setLogger] = useState({});
    const translator = useTranslator();
    const { authHeader, userInfo: authUserData } = useAuthentication();
    const history = useHistory();
    const [filteredSearchResults, setFilteredSearchResults] = useState([]);
    const [allShopsResults, setAllShopsResults] = useState([]);
    const shouldBeLink = false;
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const handleShowListMapView = () => {
        onShowMap();
    };

    const currentTableData = useMemo(() => {
        let firstResultIndex = 0;
        filteredSearchResults.length === allShopsResults.length ? firstResultIndex = (currentPage - 1) * pageSize : firstResultIndex = 0
        const lastResultIndex = firstResultIndex + pageSize;
        return filteredSearchResults.slice(firstResultIndex, Math.min(lastResultIndex, filteredSearchResults.length));
    }, [currentPage, filteredSearchResults]);

    const updateLogger = useCallback (() => {
        const updatedlogger = WMICRemoteLogger.getWMICRemoteLogger(
            'WMICAutoShopsListView',
            history.location.pathname,
            window.location,
            ldFlags,
            authHeader
        );
        setLogger(updatedlogger);
    },[ldFlags]);

    const handleLocationInputChange = (value) => {
        setlocationInput(value);
        getFilteredList(value);
    };

    const populateResultsListCallback = (shops) => {
        setFilteredSearchResults(shops);
    };

    const handleListView = () => {
        if (Array.isArray(loadDataPromise.auto_shops)) {
            const allShops = loadDataPromise.auto_shops;
            const localeService = ServiceManager.getService('locale-service');
            const phoneUtil = PhoneUtil();
            const autoshopModels = allShops.map((shop) => {
                shop.Phone = phoneUtil.prettyPrint(shop.Phone, localeService.getDefaultCountryCode());

                return new WMICAutoShopModel(shop);
            })
            setAllShopsResults(autoshopModels);
            populateResultsListCallback(autoshopModels);
        }
    };

    const getHeader = () => {
        return (
            <div className={styles['header']}>
                <h1>{translator(messages.autoShopsTitle)}</h1>
                <p className="is-size-3">{translator(messages.autoShopsListViewSubTitle)}</p>
            </div>
        );
    };

    const getResultsList = () => {
        let resultList;
        if (filteredSearchResults.length === 0){
            resultList = 
                <div className={`${styles['far-result-error']} p-4`}>
                    <h2 className={`${styles['mb-2']}`}>{translator(messages.autoShopsNoRepairShopsFound)}</h2>
                    <p>{translator(messages.autoShopsNoRepairShopsFoundInstructions)}</p>
                </div>
        } else {
            resultList = <>
                <ul className={`pl-0`} id="repair-list">
                    {
                        getAutoShopsListAsHtml2(currentTableData)
                    }
                </ul>
                <WMICPagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={filteredSearchResults.length}
                    pageSize={pageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
            </>
        }

        return resultList;
    };

    const getDirectionsLatLng = (lat, lng) => {
        const directionsLatLng = {
            lat: parseFloat(lat),
            lng: parseFloat(lng)
        };
        return directionsLatLng;
    };

    const getAutoShopsListAsHtml2 = (autoShopsDataToDisplay) => {
        return autoShopsDataToDisplay.map((preferredAutoShopModel) => {
            return preferredAutoShopModel.toListViewHtml({translator, getDirectionsLatLng});
        });
    };

    const getFilteredList = (searchTerm) => {
        setCurrentPage(1);
        searchTerm = searchTerm.toLowerCase();
        const matchingResults = _.filter(allShopsResults, (item) => {
            return matchesSearchTerm(item.street1, searchTerm)
                || matchesSearchTerm(item.street2, searchTerm)
                || matchesSearchTerm(item.city, searchTerm)
                || matchesSearchTerm(item.stateProvince, searchTerm)
                || matchesSearchTerm(item.postalCode?.toString(), searchTerm)
                || matchesSearchTerm(item.resourceName, searchTerm);
        });

        setFilteredSearchResults(matchingResults);
    };

    const matchesSearchTerm = (field, searchTerm) => {
        return typeof field === 'string' && field.length > 0 && field.toLowerCase().includes(searchTerm);
    };

    useEffect(() => {
        updateLogger();
        handleListView();
    }, []);

    const overrideProps = {
        autoShopsListViewHeaderContainer: {
            content: getHeader()
        },
        autoShopsListViewBodySearchInput: {
            onValueChange: handleLocationInputChange,
            value: locationInput
        },
        autoShopsResultsList: {
            content: getResultsList()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleShowListMapView
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            overrideProps={overrideProps}
        />
    );
}

WMICAutoShopsListViewComponent.propTypes = {
    onShowMap: PropTypes.func.isRequired
};

export default WMICAutoShopsListViewComponent;
