import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Redirect, BrowserRouter as Router } from 'react-router-dom';
import _ from 'lodash';
import { routeConfirmationModal } from '@jutro/router';
// Imported First to allow Jutro styles to be overridden
import './App.scss';
import { setComponentMapOverrides } from '@jutro/uiconfig';
import { browserSupport } from 'wmic-portals-browserblocking-js';
import { AppFloorPlan } from '@jutro/floorplan';
import { ModalNextProvider, ModalNextEmitter, useModal } from '@jutro/components';
import { LocaleService, useTranslator } from '@jutro/locale';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { withAuthenticationProvider, privateRouteWithAuthContext, useAuthentication } from 'wmic-digital-auth-react';
import vmTranslator, { messages as commonMessages } from '@xengage/gw-platform-translations';
import { AccurateBreakpointPropagation } from '@xengage/gw-jutro-adapters-react';
import { EnrollmentService } from 'gw-capability-policyenrollment';
import { ViewModelServiceFactory } from '@xengage/gw-portals-viewmodel-js';
import { WMICPolicyDetails, WMICPolicyChange } from 'gw-capability-policy-react';
import { AvailabilityService } from 'gw-capability-policycommon';
import { EndorsementService } from 'gw-capability-policychange';
import { PAPolicyChangeWizard } from 'gw-capability-policychange-pa-react';
import { HOPolicyChangeWizard } from 'gw-capability-policychange-ho-react';
import { personalizedOfferComponentMap, personalizedOfferComponents } from 'gw-capability-personalizedoffers-react';
import { VehicleInfoLookupComponentMap, VehicleInfoLookupComponents } from 'gw-capability-vehicleinfo-react';
import { policyJobComponentMap, policyJobComponents } from 'gw-capability-policyjob-react';
import {
    BillingSummaryPage,
    MakePaymentPage,
    billingComponentMap,
    billingAdditionalComponents
} from 'gw-capability-billing-react';
import { AdditionalEnrollment } from 'gw-capability-enrollment-react';
import { WizardPageTemplateWithTitle } from 'gw-portals-wizard-components-ui';
import { ProducerInfoService } from 'gw-capability-profileinfo';

import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { AccountBillingDetailsService, BillingService } from 'gw-capability-billing';
import { PolicyService } from 'gw-capability-policy';
import { ClaimDocumentService } from 'gw-capability-claimdocument';

import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import {
    platformComponentMap,
    platformComponents,
    ImageComponent
} from 'gw-components-platform-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import BaseFNOLWizard, { ClaimsConfirmationPage } from 'gw-capability-fnol-common-react';
import { ContactAgentPage } from 'gw-pages-platform-react';
import FNOLPAWizard from 'gw-capability-fnol-pa-react';
import FNOLWCWizard from 'gw-capability-fnol-wc-react';
import FNOLCAWizard from 'gw-capability-fnol-ca-react';
import FNOLHOWizard from 'gw-capability-fnol-ho-react';
import FNOLCPWizard from 'gw-capability-fnol-cp-react';
import FNOLBOPWizard from 'gw-capability-fnol-bop-react';
import FNOLGeneralWizard from 'gw-capability-fnol-gl-react';
import { WMICApplicationUtil, APP_NAMES, WMICRemoteLogger, WMICFeatureFlagUtil } from 'wmic-portals-utils-js';

import {
    ClaimList, ClaimDetails, PolicyLevelCoverages
} from 'gw-capability-claim-react';
import { ClaimService } from 'gw-capability-claim';
import { FNOLService } from 'gw-capability-fnol';
import FaqPage from 'gw-capability-faq-react';
import { PolicyDocumentError } from 'gw-capability-document-react';
import { filterCapabilityRoutes } from '@xengage/gw-portals-config-js';
import { WMICEMQPage } from 'wmic-capability-emq-react';
import { WMICAutoShopsPage } from 'wmic-capability-autoshops-react';
import { WMICLogoutPage } from 'wmic-capability-logout-react';
import { WMICFNOLPAPage, WMICFNOLPAReview, WMICFNOLPASuccess, WMICFNOLHOPage, WMICFNOLHOReview, WMICFNOLHOSuccess } from 'wmic-capability-fnol-react';
import { NavigationService } from 'wmic-capability-navigation-react';
import {
    WMICPageHeader,
    WMICPageFooter,
    WMICPaymentModal,
    WMICContactUsAMP,
    WMICEmailConsentPage,
    WMICUserProfileAMP,
    WMICTechnicalErrorPage,
    WMICLogoutConfirmationPage,
} from 'wmic-components-amp-common-react';
import { useFaIcons } from 'wmic-components-platform-react';
import {
    WMICCheckEmailRegistrationDialog,
    WMICPasswordResetSuccessDialog,
    WMICExpiredRegistrationTokenDialog,
    WMICResetPasswordPage,
    WMICRegisterPage,
    WMICCreatePasswordPage,
    WMICForgotEmailPage,
    WMICMigrationPage
} from '../pages/wmic-enrollment';

import appRoutes from './App.routes.metadata.json5';
import messages from './App.messages';
import LandingPage from '../pages/LandingPage/LandingPage';
import MaintenancePage from '../pages/MaintenancePage/MaintenancePage';

const modalEmitter = new ModalNextEmitter();

const { capabilitiesConfig } = appConfig;

const componentMap = {
    landingpage: privateRouteWithAuthContext(LandingPage),
    policydocumenterror: privateRouteWithAuthContext(PolicyDocumentError),
    fnolwcwizard: privateRouteWithAuthContext(FNOLWCWizard),
    basefnolwizard: privateRouteWithAuthContext(BaseFNOLWizard),
    claimsconfirmationpage: privateRouteWithAuthContext(ClaimsConfirmationPage),
    contactagent: privateRouteWithAuthContext(ContactAgentPage),
    fnolpawizard: privateRouteWithAuthContext(FNOLPAWizard),
    fnolhowizard: privateRouteWithAuthContext(FNOLHOWizard),
    additionalenrollmentpage: privateRouteWithAuthContext(AdditionalEnrollment),
    claimlist: privateRouteWithAuthContext(ClaimList),
    claimdetails: privateRouteWithAuthContext(ClaimDetails),
    policydetailspage: privateRouteWithAuthContext(WMICPolicyDetails),
    policychangepage: privateRouteWithAuthContext(WMICPolicyChange),
    papolicychangewizard: privateRouteWithAuthContext(PAPolicyChangeWizard),
    hopolicychangewizard: privateRouteWithAuthContext(HOPolicyChangeWizard),
    billingsummarypage: privateRouteWithAuthContext(BillingSummaryPage),
    makepaymentpage: privateRouteWithAuthContext(MakePaymentPage),
    usersprofile: privateRouteWithAuthContext(WMICUserProfileAMP),
    policylevelcoverages: privateRouteWithAuthContext(PolicyLevelCoverages),
    faqpage: privateRouteWithAuthContext(FaqPage),
    fnolcawizard: privateRouteWithAuthContext(FNOLCAWizard),
    fnolcpwizard: privateRouteWithAuthContext(FNOLCPWizard),
    fnolbopwizard: privateRouteWithAuthContext(FNOLBOPWizard),
    fnolgeneralwizard: privateRouteWithAuthContext(FNOLGeneralWizard),
    contactus: privateRouteWithAuthContext(WMICContactUsAMP),
    emailconsentpage: privateRouteWithAuthContext(WMICEmailConsentPage),
    technicalerrorpage: WMICTechnicalErrorPage,
    emqpage: privateRouteWithAuthContext(WMICEMQPage),
    preferredautoshops: WMICAutoShopsPage,
    fnolpapage: WMICFNOLPAPage,
    fnolpareview: WMICFNOLPAReview,
    fnolpasuccess: WMICFNOLPASuccess,
    fnolhopage: WMICFNOLHOPage,
    fnolhoreview: WMICFNOLHOReview,
    fnolhosuccess: WMICFNOLHOSuccess,
    logoutpage: WMICLogoutPage,
    resetpasswordpage: WMICResetPasswordPage,
    registerpage: WMICRegisterPage,
    createpasswordpage: WMICCreatePasswordPage,
    forgotemailpage: WMICForgotEmailPage,
    migrationPage: WMICMigrationPage,
    logoutconfirmationpage: WMICLogoutConfirmationPage
};

setComponentMapOverrides(
    {
        ...platformComponentMap,
        ...personalizedOfferComponentMap,
        ...VehicleInfoLookupComponentMap,
        ...policyJobComponentMap,
        ...billingComponentMap,
        WizardPageTemplateWithTitle: { component: 'WizardPageTemplateWithTitle' },
        // replace the native IMG component with a proxied version
        img: { component: 'img' },
    },
    {
        ...platformComponents,
        ...personalizedOfferComponents,
        ...VehicleInfoLookupComponents,
        ...policyJobComponents,
        ...billingAdditionalComponents,
        WizardPageTemplateWithTitle,
        img: ImageComponent,
    }
);

// const shouldRouteToSignUp = (authorities) => {
//     const accountAuthorities = authorities.filter((authority) => authority.authorityType === AUTHORITY_TYPE.ACCOUNT
//         || authority.authorityType === AUTHORITY_TYPE.POLICY);
//     // if user doesn't have ACCOUNT authorities it is supposed to be enrolled
//     if (accountAuthorities.length === 0) {
//         return <Redirect to="/signup-enrollment" />;
//     }
//     return null;
// };

const routesWithTitle = filterCapabilityRoutes(capabilitiesConfig, appRoutes.routes);

function App() {
    // console.log('check import wmic-portals-utils-js', WMICApplicationUtil);
    const { isAuthenticated, authHeader, userInfo: authUserData } = useAuthentication();
    const translator = useTranslator();
    const [viewModelService, setViewModelService] = useState(undefined);
    const [logger, setLogger] = useState(undefined);
    const [featureFlags, setFeatureFlags] = useState(undefined);
    const [isAMPAvailable, setIsAMPAvailable] = useState(undefined);

    const modalApi = useModal();

    WMICApplicationUtil.setAppName(APP_NAMES.AMP);
    LocaleService.setCurrentLanguage('en-US');
    LocaleService.setCurrentLocale('en-US');

    const floorPlansConfig = useMemo(() => {
        return [{
            scrollContent: false,
            routes: routesWithTitle,
            showFooter: false,
            showHeader: false,
            showSubHeader: false
        }];
    }, []);

    const getAppHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    };

    useEffect(() => {
        initFeatureFlags();
        browserSupport();
        getAppHeight()
        const translatorFn = vmTranslator(translator);
        !isAuthenticated && clearLocalStorage();
        import(
            /* webpackChunkName: "product-metadata" */
            // eslint-disable-next-line import/no-unresolved
            'product-metadata'
        ).then((productMetadata) => {
            const { default: result } = productMetadata;
            const newViewmodeService = ViewModelServiceFactory.getViewModelService(result, translatorFn);
            setViewModelService(newViewmodeService);
            _.set(window, 'wmic.viewmodel.metadata', newViewmodeService.productMetadata);
        });
        window.addEventListener('resize', getAppHeight);
    }, [translator]);

    const clearLocalStorage = useCallback(() => {
        localStorage.removeItem('overduePoliciesNotShown');
    }, []);

    const initFeatureFlags = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const flags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setFeatureFlags(flags);
    }

    useEffect(() => {
        if (window.self === window.top) {
            const theBody = document.getElementsByTagName('body')[0];
            theBody.className += ' wmicRoot';
        }
    }, [])



    useEffect(() => {
        if (featureFlags) {
            if (!logger) {
                const updatedLogger = WMICRemoteLogger.getWMICRemoteLogger(
                    'App',
                    '',
                    window.location,
                    featureFlags,
                    authHeader
                );
                setLogger(updatedLogger);
            } else {
                logger.setLocation({ location: window.location });
            }
        }

    }, [logger, featureFlags]);

    const handleError = useCallback((error) => {
        if (logger) {
            logger.error(error);
        }
        const state = {
            pathname: '/technicalErrorPage/error',
        };
        return <Redirect to={state} />;
    }, [logger]);

    const wrapperRef = useRef(null);
    useFaIcons(wrapperRef);

    const checkAMPAvailability = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const flags = await WMICFeatureFlagUtil.useFlags(undefined);
        const isAMPAvailable = flags['operational-amp'].isAvailable;
        return isAMPAvailable;
    }

    useEffect(() => {
        checkAMPAvailability()
            .then(isAMPAvailable => {
                if (!isAMPAvailable) {
                    componentMap.landingpage = MaintenancePage;
                }
                setIsAMPAvailable(isAMPAvailable);
            })
            .catch(handleError);
    }, []);

    useEffect(() => {
        _.set(window, '__giwan.modalApi', { modalApi});
    }, [modalApi]);

    if (isAMPAvailable === undefined) return false;

    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider
                value={{
                    AccountBillingDetailsService: AccountBillingDetailsService,
                    PolicyService: PolicyService,
                    EndorsementService: EndorsementService,
                    AvailabilityService: AvailabilityService,
                    FNOLService: FNOLService,
                    ClaimService: ClaimService,
                    ClaimDownloadService: ClaimDocumentService,
                    BillingService: BillingService,
                    EnrollmentService: EnrollmentService,
                    ContactService: ProducerInfoService,
                    NavigationService: NavigationService,
                    WMICPaymentModal: WMICPaymentModal,
                }}
            >
                <Router
                    basename="/account-management"
                    getUserConfirmation={(message, callback) => routeConfirmationModal(message, callback, modalEmitter)}>
                    <div ref={wrapperRef}>
                        <ViewModelServiceContext.Provider
                            value={viewModelService}
                        >
                            <WMICPageHeader />
                            <div className="ampContent">
                                <ErrorBoundary onError={handleError}>
                                    <ModalNextProvider >
                                    <AppFloorPlan
                                        componentMap={componentMap}
                                        floorPlans={floorPlansConfig}
                                    />
                                    </ModalNextProvider>
                                </ErrorBoundary>
                            </div>
                            <WMICPageFooter />
                        </ViewModelServiceContext.Provider>
                    </div>
                </Router>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
}

export default withAuthenticationProvider()(App);
