import React, { useEffect, useCallback, useState, useRef } from 'react';
import {
    ModalNext,
    ModalFooter,
} from '@jutro/components';
import { WMICButton } from 'wmic-components-platform-react';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { useTranslator } from '@jutro/locale';
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import { useAuthentication } from 'wmic-digital-auth-react';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import styles from './WMICOneIncPaymentModal.module.scss';
import messages from './WMICOneIncPaymentModal.messages';

const { oneIncConfig } = appConfig;

const WMICOneIncPaymentModal = (props) => {
    const {
        oneIncPaymentDetails,
        paymentModalClosed,
        saveComplete,
        paymentModalLoaded,
        payNowEnabled,
        savePaymentEnabled,
        managePaymentMethodsEnabled,
        updateModalLoaded
    } = props;
    
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const [modalLoadErrorHandled, updateModalLoadErrorHandled] = useState(false);
    const [maskBackgroundBeforeLoading, setMaskBackgroundBeforeLoading] = useState(false);
    const portalOne = useRef(null);

    let oneInc = null;
    let methodSaved = false;
    const modalLoaded = useRef(false);

    const handleError = () => {
        if (!modalLoadErrorHandled) {
            updateModalLoadErrorHandled(true);
        }
    };

    const handleClose = () => {
        paymentModalClosed();
    };

    const setBodyScrolling = (value) => {
        if (!value) {
            document.body.classList.add("noscroll");
        } else {
            document.body.classList.remove("noscroll");
        }
    }

    const onLoad = () => {
        setMaskBackgroundBeforeLoading(false);
    }

    const onUnload = () => {
        updateModalLoaded(false);
        paymentModalClosed(methodSaved);
        setBodyScrolling(true);
    }

    const onError = (e) => {
        console.error(`policy.common.components.oneIncPayment.OneIncPaymentCtrl - received an error within OneInc Payment modal. Details - ${e?.detail?.Description}`);
    }

    const onSaveComplete = (e) => {
        methodSaved = true;
        saveComplete(e.detail);
    }

    const onPaymentCanceled = (e) => {
        if (e.srcElement) {
            e.srcElement.innerHTML = '';
        }
        paymentModalClosed({eventType: 'canceled'});
    }

    const onModalDialogOpened =() => {
        updateModalLoaded(true);
        modalLoaded.current = true;
        paymentModalLoaded();
    }

    const initEventHandlers = useCallback((container) => {
        if (!container) {
            return;
        }
        
        container.addEventListener('portalOne.load', onLoad);
        container.addEventListener('portalOne.unload', onUnload);
        container.addEventListener('portalOne.error', onError);
        container.addEventListener('portalOne.saveComplete', onSaveComplete);
        container.addEventListener('portalOne.paymentCanceled', onPaymentCanceled);
        container.addEventListener('portalOne.modalDialogOpened', onModalDialogOpened);
    }, []);

    const removeHttp = (url) => {
        return url.replace(/^https?:\/\//, '');
    };

    const modalLoadTimeout = () => {
        setTimeout(() => {
            if (!modalLoaded.current) {
                handleError();
            }
        }, oneIncConfig.timeout);
    };

    useEffect(() => {     
        // eslint-disable-next-line react-hooks/exhaustive-deps
        oneInc = window.OneInc;
        const container = document.getElementById('portalOneContainer');
        portalOne.current = new oneInc.PortalOne(container, initEventHandlers);
        
        return () => {
            container.removeEventListener('portalOne.load', onLoad);
            container.removeEventListener('portalOne.unload', onUnload);
            container.removeEventListener('portalOne.error', onError);
            container.removeEventListener('portalOne.saveComplete', onSaveComplete);
            container.removeEventListener('portalOne.paymentCanceled', onPaymentCanceled);
            container.removeEventListener('portalOne.modalDialogOpened', onModalDialogOpened);
            setBodyScrolling(true);
        };
    }, []);

    useEffect(() => {
        if (payNowEnabled) {
            setMaskBackgroundBeforeLoading(true);
            setBodyScrolling(false);
            modalLoadTimeout();
            const todaysDate = new Date();
            const maxBankPaymentDaysInFuture = 60;
            const finalPayDate = new Date(Date.UTC(
                todaysDate.getFullYear(),
                todaysDate.getMonth(),
                todaysDate.getDate() + maxBankPaymentDaysInFuture,
                todaysDate.getHours(),
                todaysDate.getMinutes(),
                todaysDate.getSeconds())
            );
            
            const getOneIncSessionDetails = AccountBillingDetailsService.getOneIncSessionDetails(oneIncPaymentDetails.accountNumber, oneIncPaymentDetails.contactPublicId, authHeader);
            getOneIncSessionDetails.then((response) => {
                if (!response.oneIncCustomerID || !response.sessionID) {
                    handleError();
                    return;
                }
                const billingAddressStreet = `${oneIncPaymentDetails.billingAddress.addressLine1}, ${oneIncPaymentDetails.billingAddress.city}, ${oneIncPaymentDetails.billingAddress.state}`;
                const paymentObject = {
                    customerId: response.oneIncCustomerID,
                    sessionId: response.sessionID,
                    paymentCategory: oneIncPaymentDetails.creditCardAllowed ? 'UserSelect' : 'ECheck',
                    acceptCreditCards: oneIncPaymentDetails.creditCardAllowed ? 'true' : 'false',
                    feeContext: 'PaymentWithFee',
                    convenienceFeeType: 'Renewal',
                    amountContext: 'SelectOrEnterAmount',
                    minAmountDue: oneIncPaymentDetails.minAmountDue,
                    accountBalance: oneIncPaymentDetails.accountBalance,
                    billingZip: oneIncPaymentDetails.billingAddress.postalCode,
                    billingAddressStreet: billingAddressStreet,
                    policyHolderName: oneIncPaymentDetails.userDisplayName,
                    isScheduledPayEnabled: 'true',
                    finalPayDate: finalPayDate,
                    clientReferenceData1: oneIncPaymentDetails.clientReferenceData1,
                    clientReferenceData2: oneIncPaymentDetails.policyId,
                    email: oneIncPaymentDetails.email,
                    clientReferenceData4: 'AMP',
                    clientReferenceData5: removeHttp(response.bcUrl),
                    confirmationDisplay: 'true',
                    saveOption: 'UserSelect',
                    displayMode: 'Modal',
                    allowClosing: true
                };
                portalOne.current.makePayment(paymentObject);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payNowEnabled]);

    useEffect(() => {
        if (savePaymentEnabled) {
            modalLoadTimeout();
            const getOneIncSessionDetails = AccountBillingDetailsService.getOneIncSessionDetails(oneIncPaymentDetails.accountNumber, oneIncPaymentDetails.contactPublicId, authHeader);
            getOneIncSessionDetails.then((response) => {
                if (!response.sessionID) {
                    handleError();
                    return;
                }
                portalOne.current.managePaymentMethods({
                    customerId: response.oneIncCustomerID,
                    sessionId: response.sessionID,
                    acceptCreditCards: 'false',
                    acceptPrepaidCards: 'false',
                    paymentCategory: 'ECheck',
                    clientReferenceData1: oneIncPaymentDetails.invoiceStreamId,
                    clientReferenceData2: oneIncPaymentDetails.policyId,
                    clientReferenceData4: 'AMP',
                    clientReferenceData5: removeHttp(response.bcUrl),
                    policyHolderName: oneIncPaymentDetails.userDisplayName,
                    allowClosing: true,
                    displayMode: 'Modal',
                    saveOption: 'UserSelect'
                });
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savePaymentEnabled]);

    useEffect(() => {
        if (managePaymentMethodsEnabled) {
            setMaskBackgroundBeforeLoading(true);
            setBodyScrolling(false);
            modalLoadTimeout();
            const getOneIncSessionDetails = AccountBillingDetailsService.getOneIncSessionDetails(oneIncPaymentDetails.accountNumber, oneIncPaymentDetails.contactPublicId, authHeader);
            getOneIncSessionDetails.then((response) => {
                if (!response.sessionID) {
                    handleError();
                    return;
                }
                portalOne.current.managePaymentMethods({
                    customerId: response.oneIncCustomerID,
                    sessionId: response.sessionID,
                    acceptCreditCards: true,
                    acceptPrepaidCards: false,
                    clientReferenceData1: oneIncPaymentDetails.accountNumber,
                    clientReferenceData2: oneIncPaymentDetails.contactPublicId,
                    clientReferenceData4: 'AMP',
                    clientReferenceData5: removeHttp(response.bcUrl),
                    allowClosing: true,
                    displayMode: 'Modal',
                    saveOption: 'UserSelect'
                });
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managePaymentMethodsEnabled]);

    if (modalLoadErrorHandled) {
        return (
            <ModalNext isOpen={modalLoadErrorHandled}
                       shouldCloseOnOverlayClick={false}
                       shouldCloseOnEsc
                       onRequestClose={handleClose}>
                <div className={styles.modalHeaderBodyContainer}>
                    <h1 className="wmic-modal-header-title">{translator(messages.paymentSystemUnavailable)}</h1>
                </div>
                <div className={styles.modalBodyContainer}>{WMICRichTextUtil.translateRichText(translator(messages.paymentSystemCurrentlyUnavailable))}</div>
                <ModalFooter>
                    <WMICButton
                        onClick={() => {
                            updateModalLoadErrorHandled(false);
                            paymentModalClosed();
                        }}
                    >
                        {translator(messages.close)}
                    </WMICButton>
                </ModalFooter>
            </ModalNext>
        );
    }

    return (
        !modalLoadErrorHandled && (
            <>
                { maskBackgroundBeforeLoading && (
                    <div id="maskBackground" className={styles.maskBackground}/>
                )}
                <div id="portalOneContainer"/>
            </>
        )
    );
};

WMICOneIncPaymentModal.propTypes = {
    oneIncPaymentDetails: PropTypes.shape({}).isRequired,
    paymentModalClosed: PropTypes.func.isRequired,
    saveComplete: PropTypes.func.isRequired,
    paymentModalLoaded: PropTypes.func,
    payNowEnabled: PropTypes.bool,
    savePaymentEnabled: PropTypes.bool,
    managePaymentMethodsEnabled: PropTypes.bool,
    updateModalLoaded: PropTypes.func.isRequired
};

WMICOneIncPaymentModal.defaultProps = {
    payNowEnabled: false,
    savePaymentEnabled: false,
    managePaymentMethodsEnabled: false,
    saveComplete: () => {},
    paymentModalLoaded: () => {}
};

export default WMICOneIncPaymentModal;