import React, { useCallback, useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { MetadataContent } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';
import appConfig from 'app-config';

import { WMICFnolStateService, BaseYourInformationModel, WMICFnolUtilsService, WMICFnolService } from 'wmic-capability-fnol';
import { FNOLConstants, FNOLDisclaimer } from 'wmic-capability-fnol-react';
import { WMICReCaptchaService } from 'wmic-portals-recaptcha-js';
import { WMICButton } from 'wmic-components-platform-react';
import { WMICLoader } from 'gw-components-platform-react';
import CONSTANTS from "wmic-portals-utils-js/StringConstants";

import metadata from './WMICFNOLHOReview.metadata.json5';
import messages from './WMICFNOLHOReview.messages.js';
import styles from './WMICFNOLHOReview.module.scss';

function WMICFNOLHOReview({ match, variant }) {
    const { serviceUrls } = appConfig;
    const [trySubmit, setTrySubmit] = useState(false)
    const claimType = FNOLConstants.CLAIM_TYPE.RESIDENTIAL;
    const stateParam = _.get(match, 'params.state');
    const translator = useTranslator();
    const history = useHistory();

    const state = WMICFnolStateService.getState();

    const countryOptions = WMICFnolUtilsService.getSelectCanadaUSA()
    const stateOptions = WMICFnolUtilsService.getSelectState()

    const insuranceOptionMap = {
        INSURED: 'FNOLHOReviewInsuredOptionInsured',
        MORTGAGEE: 'FNOLHOReviewInsuredOptionMortgagee',
        CLAIMANT_CARRIER: 'FNOLHOReviewInsuredOptionAnotherInsuranceCompany',
        ATTORNEY: 'FNOLHOReviewInsuredOptionAttorney',
    };

    useEffect(() => {
        if (variant === 'page' && history.location?.state?.previousStep !== FNOLConstants.FLOW_STEPS.START) {
            WMICFnolStateService.clearState();
            history.push(`/${stateParam}/${claimType}-claim`);
            return;
        }

        if (!state) {
            handleEdit();
        }
    }, [handleEdit, state]);
    

    const getGenericInformationSection = (item, sectionTitle = false, num = 0) => ({
        sectionTitle: sectionTitle && translator(sectionTitle, {num}),
        list: [
            { label: translator(messages.FNOLReviewName), value: item.name },
            { label: translator(messages.FNOLReviewAddress), value: item.address.street },
            { label: translator(messages.FNOLReviewCity), value: item.address.city },
            { label: translator(messages.FNOLReviewCountry), value: getCountry(item.address.country) },
            { label: translator(messages.FNOLReviewState), value: getState(item.address.stateProvince) },
            { label: translator(messages.FNOLReviewZipcode), value: item.address.postalCode },
            { label: translator(messages.FNOLReviewPhoneNumber), value: item.phoneNumber },
            { label: translator(messages.FNOLReviewBusinessPhoneNumber), value: `${item.businessPhoneNumberExt ?
                `${item.businessPhoneNumber} ext. ${item.businessPhoneNumberExt}`
                : item.businessPhoneNumber}`},
            { label: translator(messages.FNOLReviewCellPhoneNumber), value: item.cellPhoneNumber }
        ]
    })

    const getYourInfo = () => {
        const yourInfo = {
            sectionTitle: false,
            list: [
                { label: '', value: translator(messages[insuranceOptionMap[state.reportingParty.insuredOption]]) },
                { label: translator(messages.FNOLReviewFirstName), value: state.reportingParty.firstName },
                { label: translator(messages.FNOLReviewLastName), value: state.reportingParty.lastName },
                { label: translator(messages.FNOLReviewPhoneNumber), value: state.reportingParty.phoneNumber },
                { label: translator(messages.FNOLReviewBusinessPhoneNumber), value: `${state.reportingParty.businessPhoneNumberExt ? 
                    `${state.reportingParty.businessPhoneNumber} ext. ${state.reportingParty.businessPhoneNumberExt}`
                    : state.reportingParty.businessPhoneNumber}`},
                { label: translator(messages.FNOLReviewCellPhoneNumber), value: state.reportingParty.cellPhoneNumber },
                { label: translator(messages.FNOLReviewEmailAddress), value: state.reportingParty.emailAddress },
            ]
        };

        if (BaseYourInformationModel.isCompany(state.reportingParty.insuredOption))
            yourInfo.list.push(...[
                { label: translator(messages.FNOLReviewCompanyName), value: state.reportingParty.companyInfo.companyName },
                { label: translator(messages.FNOLReviewFileNumber), value: state.reportingParty.companyInfo.fileNumber },
            ])

        return [yourInfo];
    }

    const getLossInformation = () => [{
        sectionTitle: false,
        list: [
            { label: translator(messages.FNOLReviewWawanesaPolicyNumber), value: <><span className={styles["text-blue"]}>HP </span>{state.lossInformation.policyNumber}</> },
            { label: translator(messages.FNOLReviewDateOfLoss), value: state.lossInformation.dateOfLoss },
            { label: translator(messages.FNOLReviewTimeOfLoss), value: state.lossInformation.timeOfLoss },
            { label: translator(messages.FNOLReviewTellUsWhatHappened), value: state.lossInformation.lossDescription },
        ]
    }]

    const getPoliceReportInformation = () => [{
        sectionTitle: false,
        list: [
            { label: translator(messages.FNOLReviewPoliceReportDeptNameLabel), value: state.policeInformation.policeDeptName },
            { label: translator(messages.FNOLReviewPoliceReportReportNumberLabel), value: state.policeInformation.reportNumber },
            { label: translator(messages.FNOLReviewPoliceReportContactLabel), value: state.policeInformation.policeDeptContactInfo },
        ]
    }]

    const getWawanesaPolicyholderInformation = () => [{
        sectionTitle: false,
        list: [
            { label: translator(messages.FNOLReviewFirstName), value: state.insured.firstName },
            { label: translator(messages.FNOLReviewLastName), value: state.insured.lastName },
            { label: translator(messages.FNOLReviewAddress), value: state.insured.address.street },
            { label: translator(messages.FNOLReviewCity), value: state.insured.address.city },
            { label: translator(messages.FNOLReviewCountry), value: getCountry(state.insured.address.country) },
            { label: translator(messages.FNOLReviewState), value: getState(state.insured.address.stateProvince) },
            { label: translator(messages.FNOLReviewZipcode), value: state.insured.address.postalCode },
            { label: translator(messages.FNOLReviewPhoneNumber), value: state.insured.phoneNumber },
            { label: translator(messages.FNOLReviewBusinessPhoneNumber), value: `${state.insured.businessPhoneNumberExt ? 
                `${state.insured.businessPhoneNumber} ext. ${state.insured.businessPhoneNumberExt}`
                : state.insured.businessPhoneNumber}`},
            { label: translator(messages.FNOLReviewCellPhoneNumber), value: state.insured.cellPhoneNumber }
        ]
    }]

    const getMortgageesInformation = () => {
        return state.mortgageesSelected.value
            ? state.mortgagees.list.map((mortgagees, index) => getGenericInformationSection(mortgagees, messages.FNOLReviewMortgageesTitle, index + 1))
            : [];
    }

    const getCountry = (country) => {
        const value = countryOptions.find((v) => v.code === country)
        return value.displayName.defaultMessage
    }

    const getState = (stateProvince) => {
        const value = stateOptions.find((v) => v.code === stateProvince)
        return !value ? '' : value.displayName.defaultMessage
    }

    const handleEdit = useCallback(() => {
        history.push({pathname: `/${stateParam}/residential-claim`});
    }, [history, stateParam]);

    const handleSave = async () => {
        setTrySubmit(true);
        try {
            const token = await WMICReCaptchaService.fetchReCaptchaToken('submitClaim')
            const fnolHeaders = {
                'x-forwarded-host': serviceUrls.xForwardedHost,
                'rcToken': token
            };

            const trackingNumber = await WMICFnolService.submitClaim(state, fnolHeaders, claimType);
            const locationState = { ...history.location.state };
            delete locationState.previousStep;
            history.replace(locationState);
            history.push({
                pathname: `/${stateParam}/${claimType}/${trackingNumber}/success`,
                state: {
                    success: true
                }
            });

        } catch (err) {
            history.push(FNOLConstants.FNOL_TECHNICAL_ERORR_PATH);
        } finally {
            setTrySubmit(false)
        }
    };

    const overrideProps = {
        FNOLHOReviewContainer: {
            visible: !trySubmit
        },
        FNOLHOLoader: {
            visible: trySubmit
        },
        FNOLHOReviewContentContainer: {
            className: cx(variant !== 'component' ? 'ww-main-content':  '', 'ww-content-limit ww-main-content-responsive fnol-margin-bottom')
        },
        FNOLHOTitleComponentCardTitle: {
            visible: variant === 'component'
        },
        FNOLHOTitlePageCardTitle: {
            visible: variant !== 'component'
        },
        FNOLHOContinueContainer: {
            visible: variant !== 'component'
        },
        FNOLHOReviewContainerYourInfo: {
            title: translator(messages.FNOLHOReviewYourInformationTitle),
            data: getYourInfo()
        },
        FNOLHOReviewContainerLossInfo: {
            title: translator(messages.FNOLHOReviewLossInformationTitle),
            data: getLossInformation()
        },
        FNOLHOReviewWawanesaPolicyholderInfo: {
            title: translator(messages.FNOLHOReviewWawanesaPolicyholderInformationTitle),
            data: getWawanesaPolicyholderInformation()
        },
        FNOLHOReviewPoliceReportInfo: {
            title: translator(messages.FNOLHOReviewPoliceReportTitle),
            data: getPoliceReportInformation(),
            visible: state.policeReportSelected.value
        },
        FNOLHOReviewMortgageeInfo: {
            title: translator(messages.FNOLReviewMortgageeTitle),
            data: getMortgageesInformation(),
            noContentWarningMessage: translator(messages.FNOLReviewNoMortgagesWarning)
        },
        FNOLHOEditButton: {
            trackButtonIdentifier: translator(messages.FNOLHOEditButtonTrackButtonIdentifier, {claimType: claimType, state: stateParam})
        },
        FNOLHOSubmitButton: {
            trackButtonIdentifier: translator(messages.FNOLHOSubmitButtonTrackButtonIdentifier, {claimType: claimType, state: stateParam})
        },
        FNOLHODisclaimer: {
            showCaliforniaLawDisclaimer: stateParam === CONSTANTS.STATE.CA_LONG
        }
    }
    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICButton,
            WMICLoader,
            FNOLDisclaimer
        },
        resolveCallbackMap: {
            handleSave,
            handleEdit
        }
    }

    return (
        <div>
            <MetadataContent
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                {...resolvers} />
        </div>
    );
}

WMICFNOLHOReview.propTypes = {
    variant: PropTypes.oneOf(['component', 'page'])
};

WMICFNOLHOReview.defaultProps = {
    variant: 'page'
};

export default withRouter(WMICFNOLHOReview)