import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentPlan: {
        id: 'payment.views.payment-plan-modal.Payment Plan',
        defaultMessage: 'Payment Plan'
    },
    dueDate: {
        id: 'payment.views.payment-plan-modal.Due Date',
        defaultMessage: 'Due Date'
    },
    amount: {
        id: 'payment.views.payment-plan-modal.Amount',
        defaultMessage: 'Amount'
    },
    overdueAmount: {
        id: 'payment.views.payment-plan-modal.Overdue amount',
        defaultMessage: 'Overdue amount'
    },
    contactUs: {
        id: 'payment.views.payment-plan-modal.contact us',
        defaultMessage: 'contact us'
    },
    close: {
        id: 'payment.views.payment-plan-modal.Close',
        defaultMessage: 'Close'
    },
    edit: {
        id: 'policy.common.views.account-policy-details.billing-details.Edit',
        defaultMessage: 'Edit Plan'
    },
    bankDetails: {
        id: 'policy.common.views.account-policy-details.Bank Details',
        defaultMessage: 'Bank Details: '
    },
    financialInstitutionName: {
        id: 'policy.common.views.billing-details.tooltips.Financial Institution Name',
        defaultMessage: 'Financial Institution Name: '
    },
    bankAccountNumberDisplay: {
        id: 'policy.common.views.billing-details.tooltips.Bank Account Number Display',
        defaultMessage: '{bankAccountType}&nbsp;&nbsp;****{lastFour}'
    },
    routingTransitNumber: {
        id: 'policy.common.views.billing-details.tooltips.Routing Transit Number',
        defaultMessage: 'Routing Transit Number: '
    },
    bankAccountNumber: {
        id: 'policy.common.views.billing-details.tooltips.Bank Account Number',
        defaultMessage: 'Bank Account Number: '
    },
    doNotSeeYourBankAccDetails: {
        id: 'policy.common.views.billing-details.tooltips.Do not see your bank account details',
        defaultMessage: 'Don\'t see your bank account details? If you have any questions please'
    },
    managePaymentPlan: {
        id: 'payment.views.payment-plan-modal.Manage Payment Plan',
        defaultMessage: 'Manage Payment Plan'
    },
    viewPaymentPlan: {
        id: 'payment.views.payment-plan-modal.View Payment Plan',
        defaultMessage: 'View Payment Plan'
    },
    changePaymentPlan: {
        id: 'payment.views.edit-payment-plan-modal.Change Payment Plan',
        defaultMessage: 'Change Payment Plan'
    },
    newPlan: {
        id: 'payment.views.edit-payment-plan-modal.Select a New Plan',
        defaultMessage: 'Select a New Plan'
    },
    currentPaymentPlan: {
        id: 'payment.views.edit-payment-plan-modal.Current Payment Plan',
        defaultMessage: 'Current Payment Plan'
    },
    switchTo: {
        id: 'payment.views.edit-payment-plan-modal.Switch to the following plan',
        defaultMessage: 'Switch to the following plan'
    },
    current: {
        id: 'payment.views.edit-payment-plan-modal.Current Schedule',
        defaultMessage: 'Current Schedule'
    },
    currentScheduleAria: {
        id: 'payment.views.edit-payment-plan-modal.Current Schedule Aria',
        defaultMessage: 'Payment of {amount} currently scheduled for {eventDate}'
    },
    proposed: {
        id: 'payment.views.edit-payment-plan-modal.Proposed Schedule',
        defaultMessage: 'Proposed Schedule'
    },
    proposedScheduleAria: {
        id: 'payment.views.edit-payment-plan-modal.Proposed Schedule Aria',
        defaultMessage: 'Proposed payment of {amount} scheduled for {eventDate}'
    },
    select: {
        id: 'payment.views.edit-payment-plan-modal.Select One',
        defaultMessage: 'Select One'
    },
    back: {
        id: 'payment.views.edit-payment-plan-modal.Back',
        defaultMessage: 'Back'
    },
    next: {
        id: 'payment.views.edit-payment-plan-modal.Next',
        defaultMessage: 'Next'
    },
    savePlanChanges: {
        id: 'payment.views.edit-payment-plan-modal.Save Plan Changes',
        defaultMessage: 'Save Plan Changes'
    },
    paymentPlanUpdated: {
        id: 'payment.views.edit-payment-plan-modal.Payment Plan Updated',
        defaultMessage: 'Payment Plan Updated'
    },
    paymentPlanChangeUnsuccessful: {
        id: 'payment.views.edit-payment-plan-modal.Payment plan change unsuccessful',
        defaultMessage: 'Payment plan change unsuccessful'
    },
    successfullyChanged: {
        id: 'payment.views.edit-payment-plan-modal.You have successfully changed your payment plan to',
        defaultMessage: 'You have successfully changed your payment plan to'
    },
    failedChangeYourPlan: {
        id: 'payment.views.edit-payment-plan-modal.Failed changed your payment plan',
        defaultMessage: 'There was an error changing your payment plan. Contact us for assistance at 1-800-640-2920 Monday – Friday 7:30 AM – 7:30 PM and Saturday 8:00 AM – 4:30 PM.'
    },
    paymentOptions: {
        id: 'payment.views.edit-payment-plan-modal.Payment Options',
        defaultMessage: 'Payment Options'
    },
    fullPay: {
        id: 'payment.views.edit-payment-plan-modal.Full Pay: 100% of total premium down; No installment fee',
        defaultMessage: 'Full Pay: 100% of total premium down; No installment fee'
    },
    twoPay: {
        id: 'payment.views.edit-payment-plan-modal.Two Pay: 50% of total premium down; 1 installments of 50% plus installment fee of $5.00 per installment',
        defaultMessage: 'Two Pay: 50% of total premium down; 1 installments of 50% plus installment fee of $5.00 per installment'
    },
    threePay: {
        id: 'payment.views.edit-payment-plan-modal.Three Pay: 40% of total premium down; 2 installments of 30% plus installment fee of $5.00 per installment',
        defaultMessage: 'Three Pay: 40% of total premium down; 2 installments of 30% plus installment fee of $5.00 per installment'
    },
    monthlyAutomatedRecurring: {
        id: 'payment.views.edit-payment-plan-modal.Monthly Automated Recurring: Equal installments plus installment fee of $1.00 withdrawn monthly from a checking or saving account',
        defaultMessage: 'Monthly Automated Recurring: Equal installments plus installment fee of $1.00 withdrawn monthly from a checking or saving account'
    },
    noteChanging: {
        id: 'payment.views.edit-payment-plan-modal.Note: Changing your current payment plan away from Monthly Automated Recurring (MAR) will automatically...',
        defaultMessage: 'Note: Changing your current payment plan away from Monthly Automated Recurring (MAR) will automatically set your newly chosen payment plan as the payment plan on your renewal term.'
    },
    noteReturning: {
        id: 'payment.views.edit-payment-plan-modal.Note: Returning to Monthly Automated Recurring (MAR) after changing your payment plan...',
        defaultMessage: 'Note: Returning to Monthly Automated Recurring (MAR) after changing your payment plan can only be done by calling Customer Service. MAR plans are not currently available as a selection online.'
    },
    pleaseCallUs1: {
        id: 'payment.views.edit-payment-plan-modal.pleaseCallUs1',
        defaultMessage: 'If you have a question, please call us at'
    },
    pleaseCallUs1Aria: {
        id: 'payment.views.edit-payment-plan-modal.pleaseCallUs1Aria',
        defaultMessage: 'If you have a question, please call us by phone at'
    },
    pleaseCallUs2Phone: {
        id: 'payment.views.edit-payment-plan-modal.pleaseCallUs2Phone',
        defaultMessage: ' &lt;a href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; '
    },
    pleaseCallUs3: {
        id: 'payment.views.edit-payment-plan-modal.pleaseCallUs3',
        defaultMessage: 'Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM, or email us at'
    },
    pleaseCallUs3Aria: {
        id: 'payment.views.edit-payment-plan-modal.pleaseCallUs3Aria',
        defaultMessage: 'Monday to Friday 7:30 AM to 7:30 PM and Saturday 8:00 AM to 4:30 PM, or email us at'
    },
    pleaseCallUs4Email: {
        id: 'payment.views.edit-payment-plan-modal.pleaseCallUs4Email',
        defaultMessage: ' &lt;a href="mailto:service.us@wawanesa.com"&gt;service.us@wawanesa.com&lt;/a&gt;.'
    },
    noteCurrentPlantoProposed: {
        id: 'payment.views.edit-payment-plan-modal.Note: If you change your current plan to the proposed schedule you will owe money today',
        defaultMessage: 'Note: If you change your current plan to the proposed schedule you will owe money today.'
    },
    marPlanAuthorization: {
        id: 'payment.views.select-bank-account-modal.Monthly Automated Recurring Payment Plan Authorization',
        defaultMessage: 'Monthly Automated Recurring Payment Plan Authorization'
    },
    marTextPart1: {
        id: 'payment.views.select-bank-account-modal.Monthly Automated Recurring Payment Plan Text Part1',
        defaultMessage: 'You have elected to participate in Wawanesa Insurance’s Monthly Automated Recurring (MAR) Payment Plan &lt;strong&gt;for Policy(s) {policyId}&lt;/strong&gt; and hereby authorize Wawanesa Insurance to electronically debit your specified banking account. All payments will be deducted from your account on the dates and in amounts indicated on the Automated Recurring Payment Notice (ARPN). You certify that you are an authorized user of this bank account and will not dispute these scheduled transactions with the bank; so long as the transactions correspond to the terms indicated in this authorization form.'
    },
    marTextPart2: {
        id: 'payment.views.select-bank-account-modal.Monthly Automated Recurring Payment Plan Text Part2',
        defaultMessage: 'You agree to contact us at least 7 days prior to a scheduled withdrawal for any changes to bank account information or withdrawal dates. Requests to change your policy may result in increased payment amounts.  Depending on the timing of these requested changes, Wawanesa Insurance may initiate a withdrawal according to the original ARPN prior to you receiving an updated ARPN. You will no longer be eligible for MAR and be required to make payments under an alternate payment plan, if:'
    },
    marTextPart3: {
        id: 'payment.views.select-bank-account-modal.Monthly Automated Recurring Payment Plan Text Part3',
        defaultMessage: '&lt;ul class=\"ww-full-list\"&gt;&lt;li&gt;Any payment is returned as unauthorized; or&lt;/li&gt;&lt;li&gt;Two payments are returned for any reason in the same policy period for a 6-month policy; or&lt;/li&gt;&lt;li&gt;Three payments are returned for any reason in the same policy period for a 12-month policy&lt;/li&gt;&lt;/ul&gt;'
    },
    marTextPart4: {
        id: 'payment.views.select-bank-account-modal.Monthly Automated Recurring Payment Plan Text Part4',
        defaultMessage: 'You acknowledge and agree that a non-sufficient funds fee will be assessed each time a payment is returned for insufficient funds.'
    },
    marTextPart5: {
        id: 'payment.views.select-bank-account-modal.Monthly Automated Recurring Payment Plan Text Part5',
        defaultMessage: 'This authorization will remain in effect for all payments on this policy term and any renewal terms offered. You may choose to change your payment plan at any time through your online account, by notifying Wawanesa Insurance in writing or by calling &lt;a href=\"tel:1-800-640-2920\"&gt;1-800-640-2920&lt;/a&gt;.'
    },
    acknowledgementText: {
        id: 'payment.views.select-bank-account-modal.I acknowledge and agree that I have read and accept the terms set forth above.',
        defaultMessage: '&lt;strong&gt;I {fullName} acknowledge and agree that I have read and accept the terms set forth above.&lt;/strong&gt;'
    },
    selectABankAccount: {
        id: 'payment.views.select-bank-account-modal.Select One',
        defaultMessage: 'Select a Bank Account'
    },
    payWithAnotherBankAccount: {
        id: 'payment.views.select-bank-account-modal.Pay with another bank account',
        defaultMessage: 'Pay with another bank account'
    },
    bankAccountType: {
        id: 'payment.views.select-bank-account-modal.Bank Account Type',
        defaultMessage: 'Bank Account Type'
    },
    checking: {
        id: 'payment.views.select-bank-account-modal.Checking',
        defaultMessage: 'Checking'
    },
    savings: {
        id: 'payment.views.select-bank-account-modal.Savings',
        defaultMessage: 'Savings'
    },
    pleaseChooseAnAccountTypeBeforeProceeding: {
        id: 'payment.views.select-bank-account-modal.Please choose an account type before proceeding.',
        defaultMessage: 'Please choose an account type before proceeding.'
    },
    nonSufficientFundsFeeWillBeAssessed: {
        id: 'payment.views.select-bank-account-modal. A $25.00 Non-Sufficient Funds (NSF) fee will be assessed for payments returned due to insufficient funds.',
        defaultMessage: ' A $25.00 Non-Sufficient Funds (NSF) fee will be assessed for payments returned due to insufficient funds.'
    },
    chequeSample: {
        id: 'payment.views.select-bank-account-modal.Cheque sample',
        defaultMessage: 'Cheque sample'
    },
    checkNumberIsNotARequiredField: {
        id: 'payment.views.select-bank-account-modal.* Check number is not a required field.',
        defaultMessage: '* Check number is not a required field.'
    },
    enterBankAccountInformation: {
        id: 'payment.views.select-bank-account-modal.Enter Bank Account Information',
        defaultMessage: 'Enter Bank Account Information'
    },
    bankOptionName: {
        id: 'payment.views.select-bank-account-modal.BankOptionName',
        defaultMessage: '{accountType} Account {accountNumber}'
    },
    routingTransitNumberMustBe9DigitsLong: {
        id: 'payment.views.select-bank-account-modal.Routing transit number must be 9 digits long.',
        defaultMessage: 'Routing transit number must be 9 digits long.'
    },
    bankAccountNumberMustBe1To17Digits: {
        id: 'payment.views.select-bank-account-modal.Bank account number must be 1 to 17 digits.',
        defaultMessage: 'Bank account number must be 1 to 17 digits.'
    },
    manageBankAccounts: {
        id: 'payment.views.select-bank-account-modal.Manage bank accounts',
        defaultMessage: 'Manage bank accounts'
    },
    managePaymentMethods: {
        id: 'payment.views.select-bank-account-modal.Manage Payment Methods',
        defaultMessage: 'Manage Payment Methods'
    },
    planChangeSuccess: {
        id: 'payment.views.select-bank-account-modal.You have successfully changed your payment plan to',
        defaultMessage: 'You have successfully changed your payment plan to {newPaymentPlan}.'
    },
    useAnotherBankAccount: {
        id: 'payment.views.select-bank-account-modal.Use another bank account',
        defaultMessage: 'Use another bank account'
    },
    paymentPageDescriptionAria: {
        id: 'payment.views.edit-payment-plan-modal.paymentPageDescriptionAria',
        defaultMessage: 'Your current payment plan is described below'
    },
    selectPaymentPageDescriptionAria: {
        id: 'payment.views.edit-payment-plan-modal.selectPaymentPageDescriptionAria',
        defaultMessage: 'Select a different payment plan below'
    },
    comparePaymentPageDescriptionAria: {
        id: 'payment.views.edit-payment-plan-modal.comparePaymentPageDescriptionAria',
        defaultMessage: 'Compare your current payment plan to the newly selected payment plan'
    },
    selectBankAccountPageDescriptionAria: {
        id: 'payment.views.edit-payment-plan-modal.selectBankAccountPageDescriptionAria',
        defaultMessage: 'Select a bank account below'
    },
    manageBankingInfoPageDescriptionAria: {
        id: 'payment.views.edit-payment-plan-modal.manageBankingInfoPageDescriptionAria',
        defaultMessage: ''
    },
    changeResultPageDescriptionAria: {
        id: 'payment.views.edit-payment-plan-modal.changeResultPageDescriptionAria',
        defaultMessage: ''
    },
    paymentInvalidRoutingTransitNumberPageDescriptionAria: {
        id: 'payment.views.edit-payment-plan-modal.paymentInvalidRoutingTransitNumberPageDescriptionAria',
        defaultMessage: ''
    },
    changeFailurePageDescriptionAria: {
        id: 'payment.views.edit-payment-plan-modal.changeFailurePageDescriptionAria',
        defaultMessage: ''
    },
    paymentDueAria: {
        id: 'payment.views.edit-payment-plan-modal.Payment Due Aria',
        defaultMessage: 'Payment of {amount} due on {eventDate}'
    },
    acknowledgementTextAria: {
        id: 'payment.views.select-bank-account-modal.I acknowledge and agree that I have read and accept the terms set forth above. Aria',
        defaultMessage: 'I {fullName} acknowledge and agree that I have read and accept the terms set forth above.'
    },
});
