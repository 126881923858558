import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { MetadataContent } from '@jutro/uiconfig';
import { useTranslator } from '@jutro/locale';
import { WMICRichTextUtil, WMICFeatureFlagUtil } from 'wmic-portals-utils-js';
import { useAuthentication } from 'wmic-digital-auth-react';
import CONSTANTS from "wmic-portals-utils-js/StringConstants";
import { WMICButton } from 'wmic-components-platform-react';
import { get } from 'lodash';
import moment from 'moment';

import { FNOLPAAutoGlassComponent,
    FNOLDisclaimer,
    FNOLYourInformationComponent,
    FNOLLossInformationComponent,
    FNOLInsuredInformationComponent ,
    FNOLVehicleInformationComponent,
    FNOLPoliceReportComponent,
    FNOLOtherPartiesComponent, 
    FNOLPassengersComponent ,
    FNOLWitnessesComponent,
    FNOLConstants,
    broadcastEvent
} from 'wmic-capability-fnol-react';
import { WMICFnolStateService, WMICFnolSharedDataService, WMICFnolUtilsService, WMICFnolSharedDataServiceObject } from 'wmic-capability-fnol';

import metadata from './WMICFNOLPAPage.metadata.json5';
import messages from '../WMICFNOLPage.messages.js';
import styles from '../WMICFNOLPage.module.scss'


function WMICFNOLPAPage(props) {
    const { match } = props;
    const translator = useTranslator();
    const [toggleValue, setToggleValue] = useState(undefined);
    const [hasError, setHasError] = useState(false);
    const [showOtherPartySection, setShowOtherPartySection] = useState(false);
    const [trySubmit, setTrySubmit] = useState(false);
    const [fFlags, setfFlags] = useState({});
    const { userInfo: authUserData } = useAuthentication();
    const featureFlags = WMICFeatureFlagUtil.getFeatureFlags();
    const stateParam = get(match, 'params.state');
    const claimType = FNOLConstants.CLAIM_TYPE.AUTO;
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    const state = WMICFnolStateService.getState();

    const initFeatureFlags = async () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rFlags = await WMICFeatureFlagUtil.useFlags(authUserData);
        setfFlags(rFlags);
    }

    useEffect (() => {
        initFeatureFlags();
    }, []);

    const fnolFFAvailable = useCallback(() => {
        return WMICFeatureFlagUtil.queryAvailabilityAMP(fFlags, featureFlags.FNOL)?.isAvailable;
    },[fFlags, featureFlags]);

    useEffect(() => {
        if (Object.entries(fFlags).length > 1) {
            if (state) {
                setToggleValue(state.isAutoglassOnly.value)
                WMICFnolUtilsService.copyAttributes(state, WMICFnolSharedDataService);
            } else {
                WMICFnolUtilsService.copyAttributes(WMICFnolSharedDataServiceObject, WMICFnolSharedDataService);
            }

            if (!fnolFFAvailable() || (stateParam !== CONSTANTS.STATE.CA_LONG && stateParam !== CONSTANTS.STATE.OR_LONG)) {
                history.push('/home');
            } else {
                setLoading(false);
            }

            if (!WMICFnolSharedDataService.driverOfInsuredVehicle.address.stateProvince) {
                WMICFnolSharedDataService.driverOfInsuredVehicle.address.stateProvince = stateParam;
            }
        }
        
    }, [stateParam, fFlags]);

    const handleToggleChange = (value) => {
        setToggleValue(value);
        WMICFnolSharedDataService.isAutoglassOnly.value = value;
    }

    const handleYourInfoDispatcher = (data) => {
        if(data.showReportingParty !== undefined && data.showOtherPartySection !== undefined) {
            setShowOtherPartySection(data.showOtherPartySection)
            broadcastEvent.dispatch("reportingPartyChanged", data);
        } else if (data.reportingParty) {
            broadcastEvent.dispatch("yourInfoUpdated", {data, modifyOtherParty: showOtherPartySection});            
        }
    }

    const handleSave = async () => {
        setTrySubmit(true);
        const result = await WMICFnolUtilsService.scrollToError()
        if(!result){
            WMICFnolStateService.addState(WMICFnolSharedDataService);
            history.push({
                pathname: `/${stateParam}/auto/review`,
                state: {
                    previousStep: FNOLConstants.FLOW_STEPS.START
                }
            });
            window.scrollTo(0,0);
        }
    };

    const validateFields = (path, type, optional = false, attr = '') => {
        const value = get(WMICFnolSharedDataService, path);
        let res = false;
        switch(type) {
            case 'field':
                res = !(value || !trySubmit);
                break;
            case 'policy':
                res = !((value && value.length > 6)|| !trySubmit);
                break;
            case 'date': 
                res = (trySubmit && validateDate(value, optional))
                break;
            case 'time':
                res = (trySubmit && value.length > 1 && !FNOLConstants.REGEX_VALIDATIONS.TIME.test(value))
                break;
            case 'toggle':
                res = !(value !== null || !trySubmit)
                break;
            case 'tabset':
                res = !(value.every((obj) => obj[attr]) || !trySubmit)
                break;
            default:
                break
        }
        if (res && !hasError) setHasError(true)
        if (trySubmit && WMICFnolUtilsService.getFNOLPAHasError(WMICFnolSharedDataService)) setHasError(false);
        return res
    }

    
    const validateDate = (value, optional) => {
        const date = moment(value, 'MM/DD/YYYY', true);
        if(optional)
            return !((date.isValid() || FNOLConstants.REGEX_VALIDATIONS.DATE.test(value) || value.length < 1) && (date <= new Date()))
        return !((date.isValid() || FNOLConstants.REGEX_VALIDATIONS.DATE.test(value)) && (date <= new Date()))
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            FNOLPAAutoGlassComponent,
            FNOLDisclaimer,
            FNOLYourInformationComponent,
            FNOLLossInformationComponent,
            FNOLInsuredInformationComponent,
            FNOLVehicleInformationComponent,
            FNOLPoliceReportComponent,
            FNOLOtherPartiesComponent,
            FNOLPassengersComponent,
            FNOLWitnessesComponent,
            WMICButton
        },
        resolveCallbackMap: {
            handleToggleChange,
            handleSave
        }
    };

    const overrideProps = {
        FNOLPAContainer75PolicyNumberRequired: {
            content: WMICRichTextUtil.translateRichText(translator(messages.FNOLPagePolicyNumberRequired))
        },
        FNOLPAContainer75AutoGlassOnlyToggle: {
            value: toggleValue
        },
        FNOLPAContainer75AutoGlassWarning: {
            content: WMICRichTextUtil.translateRichText(translator(messages.FNOLPAPageAutoGlassWarning)),
            visible: toggleValue === true
        },
        FNOLPAFormContainer: {
            visible: toggleValue === false
        },
        FNOLPADisclaimer: {
            showCaliforniaLawDisclaimer: toggleValue === false && stateParam === CONSTANTS.STATE.CA_LONG
        },
        FNOLPAFormYourInformation: {
            claimType: claimType,
            WMICFnolSharedDataService,
            handleYourInfoDispatcher,
            validateFields
        },
        FNOLPAFormLossInformation: {
            state: FNOLConstants.formatState(stateParam),
            WMICFnolSharedDataService,
            claimType,
            validateFields
        },
        FNOLPAOtherPartyInsuredInformationComponent: {
            WMICFnolSharedDataService,
            variant: CONSTANTS.FNOL.VARIANTS.OTHER_PARTY,
            claimType,
            state: FNOLConstants.formatState(stateParam),
            showOtherPartySection,
            validateFields
        },
        FNOLPAOtherPartyVehicleInformationComponent: {
            WMICFnolSharedDataService,
            variant: CONSTANTS.FNOL.VARIANTS.OTHER_PARTY,
            visible: showOtherPartySection
        },
        FNOLPAInsuredInformationComponent: {
            WMICFnolSharedDataService,
            claimType,
            state: FNOLConstants.formatState(stateParam),
            validateFields
        },
        FNOLPAVehicleInformationComponent: {
            WMICFnolSharedDataService,
        },
        FNOLPAPoliceReportComponent: {
            WMICFnolSharedDataService,
            validateFields
        },
        FNOLPAOtherPartiesComponent: {
            state: FNOLConstants.formatState(stateParam),
            WMICFnolSharedDataService,
            WMICFnolUtilsService,
            validateFields
        },
        FNOLPAPassengersComponent: {
            state: FNOLConstants.formatState(stateParam),
            WMICFnolSharedDataService,
            WMICFnolUtilsService,
            validateFields
        },
        FNOLPAWitnessesComponent: {
            state: FNOLConstants.formatState(stateParam),
            WMICFnolSharedDataService,
            WMICFnolUtilsService,
            validateFields
        },
        FNOLPAErrorMessage: {
            visible: hasError
        },
        FNOLPAContainer: {
            visible: !loading
        },
        FNOLPAPageAutoGlass: {
            state: FNOLConstants.formatState(stateParam)
        },
        FNOLPAContinueButton: {
            trackButtonIdentifier: translator(messages.FNOLContinueTrackButtonIdentifier, {claimType: claimType, state: stateParam})
        }
    }

    return (
        <div>
            <MetadataContent
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                {...resolvers} />
        </div>
    );
}

export default withRouter(WMICFNOLPAPage)
