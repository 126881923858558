import { setComponentMapOverrides } from '@jutro/uiconfig';
import WMICLogoutPage from './pages/WMICLogoutPage/WMICLogoutPage';


setComponentMapOverrides(
    {
        WMICLogoutPage: { component: 'WMICLogoutPage' }
    },
    {
        WMICLogoutPage
    }
);

export { default as WMICLogoutPage } from './pages/WMICLogoutPage/WMICLogoutPage';
